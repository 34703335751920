import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import {
  TAddUserManagementBody,
  TUserManagementData,
  TAdminMetaInfo,
  TGetUserManagementParams,
  TGetClientsAreaPermissions,
} from '~/api/userManagement/userManagementApi.types';
import { TDefaultPaginationResponse } from '~/types/api';
import { baseQueryHandler } from '~/utils/apiRequest';

export const userManagementApi = createApi({
  reducerPath: 'userManagementApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['UserManagement', 'AdminMetaInfo', 'Permissions'],
  endpoints: (builder) => ({
    getUserManagement: builder.query<
      TDefaultPaginationResponse<TUserManagementData>,
      TGetUserManagementParams
    >({
      query: ({ params, role }) => ({
        url: `/rest/admins/list/${role}/`,
        params,
      }),
      providesTags: ['UserManagement'],
    }),

    getAdminMetaInfo: builder.query<TAdminMetaInfo, void>({
      query: () => ({
        url: '/rest/admins/admin-meta-info/',
      }),
      providesTags: ['AdminMetaInfo'],
    }),

    addUserManagement: builder.mutation<void, TAddUserManagementBody>({
      query: (formData) => {
        return {
          url: '/rest/admins/create/',
          method: 'POST',
          data: {
            ...formData,
          },
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled;
        Notification.success({
          title: 'Successfully',
        });
      },
    }),

    getClientsAreaPermissions: builder.query<TGetClientsAreaPermissions, void>({
      query: () => ({
        url: '/rest/admins/permissions/',
      }),
      providesTags: ['Permissions'],
    }),
  }),
});
export const {
  useLazyGetUserManagementQuery,
  useAddUserManagementMutation,
  useGetAdminMetaInfoQuery,
  useGetClientsAreaPermissionsQuery,
} = userManagementApi;
