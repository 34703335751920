import { FC } from 'react';

import { TabStructure } from '../../../components/EnrtyScreenStructure/TabStructure/TabStructure';

export const RiskScoringTab: FC = () => {
  return (
    <TabStructure
      withoutText
      withMainSave
      columnsWidth="0.4fr 1fr"
      entityType="risk-scoring-tab"
    />
  );
};
