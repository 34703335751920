import { styled } from 'react-ui-kit-exante';

export const StyledAddTagRow = styled('div')`
  display: flex;
  gap: 24px;

  .AddNewTagButton {
    white-space: nowrap;
  }
`;
