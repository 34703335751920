import { useContext } from 'react';
import { IconButton, Radio } from 'react-ui-kit-exante';

import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';

import { TokenTypes } from '../../../../PasswordManagement.constants';
import { PasswordManagementContext } from '../../../../context';
import { Forms } from '../../../Forms';

import { StyledList, StyledItem, StyledName } from './TokenListSection.styled';
import { TTokenListSection } from './TokenListSection.types';

export const MAIN_USER_TOKEN_NAME = 'Main password';

export const TokenListSection = ({
  tokens,
  messageStub,
  authFlowTokens,
  isPasswordTokens,
}: TTokenListSection) => {
  const { setEditData, onEdit, onDelete, isDisabledBtn, onEditAuthFlow } =
    useContext(PasswordManagementContext);

  return (
    <StyledList className="List">
      {tokens?.length > 0 ? (
        tokens?.map((token) => {
          const { name, type, id } = token;

          const isChecked = authFlowTokens?.includes(Number(id));

          return (
            <StyledItem className="Item" key={id}>
              <>
                <ActionWithConfirmation
                  onConfirm={() => onEditAuthFlow(id, isPasswordTokens)}
                  placement="bottom"
                  confirmButtonNameKey="Confirm"
                  cancelButtonNameKey="Cancel"
                  title={`Do you want to ${
                    isChecked ? 'deactivate' : 'activate'
                  } this token?`}
                  withCloseAfterConfirmation
                  disabled={isChecked && isPasswordTokens}
                >
                  <Radio checked={isChecked} />
                </ActionWithConfirmation>
                <StyledName className="Name">{name}</StyledName>
                {TokenTypes.Password === type && (
                  <ActionWithConfirmation
                    onConfirm={onEdit}
                    content={<Forms />}
                    placement="bottom"
                    confirmButtonNameKey="Save"
                    cancelButtonNameKey="Cancel"
                    disabled={isDisabledBtn}
                    withCloseAfterConfirmation={isDisabledBtn}
                    closeHandler={() => setEditData(null)}
                  >
                    <IconButton
                      title="Edit"
                      iconName="EditIcon"
                      iconColor="secondary"
                      iconSize={18}
                      disabled={isDisabledBtn}
                      onClick={() => setEditData(token)}
                    />
                  </ActionWithConfirmation>
                )}
                <ActionWithConfirmation
                  onConfirm={() => onDelete(id)}
                  title="Do you want to delete this token?"
                  placement="bottom"
                  confirmButtonNameKey="Confirm"
                  cancelButtonNameKey="Cancel"
                  disabled={isDisabledBtn}
                  closeHandler={() => setEditData(null)}
                >
                  <IconButton
                    iconSize={18}
                    iconColor="secondary"
                    iconName="DeleteIcon"
                    title="Delete"
                    disabled={name === MAIN_USER_TOKEN_NAME || isDisabledBtn}
                  />
                </ActionWithConfirmation>
              </>
            </StyledItem>
          );
        })
      ) : (
        <div>{messageStub}</div>
      )}
    </StyledList>
  );
};
