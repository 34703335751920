import { skipToken } from '@reduxjs/toolkit/query';
import { memo, useContext, useMemo } from 'react';
import { Loader } from 'react-ui-kit-exante';

import {
  useGetApplicationTransactionsTotalsQuery,
  useGetGlobalContextQuery,
} from '~/api';
import { ValueRow } from '~/components/ValueRow';
import { DEFAULT_CURRENCY } from '~/constants/common';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { formatNumber } from '~/utils/formatters/formatNumber';

import {
  StyledLoaderContainer,
  StyledTransactionTotalList,
} from './TransactionTotal.styled';

const TransactionTotal = () => {
  const { application, activeLegalEntity } = useContext(ApplicationContext);

  const applicationId = application?.application?.id;
  const params = applicationId
    ? { applicationId, legalEntity: activeLegalEntity }
    : skipToken;

  const { data: totals, isFetching } =
    useGetApplicationTransactionsTotalsQuery(params);
  const { data: globalContext } = useGetGlobalContextQuery();

  const whiteLabel = globalContext?.white_label;
  const wlTitle = whiteLabel?.wl_title ?? '';
  const oppositeWlTitle = whiteLabel?.opposite_wl ?? '';

  const {
    funds_opposite: fundsOpposite,
    funds_other: fundsOther,
    sum,
    total_funds: totalFunds,
    total_nav: totalNav,
    total_wrs: totalWrs,
    wrs_opposite: wrsOpposite,
    wrs_other: wrsOther,
  } = totals ?? {};

  const showOppositeAndOther = !!fundsOpposite || !!wrsOpposite;
  const isDisabledTotals =
    Number(fundsOpposite) === 0 && Number(wrsOpposite) === 0;

  const totalsView = useMemo(() => {
    const result = [];

    if (showOppositeAndOther) {
      result.push(
        ...[
          {
            label: `Fundings ${wlTitle}:`,
            value: formatNumber(fundsOther ?? ''),
          },
          {
            label: `Withdrawals ${wlTitle}:`,
            value: formatNumber(wrsOther ?? ''),
          },
        ],
      );
    }

    if (!isDisabledTotals) {
      result.push(
        ...[
          {
            label: `Fundings ${oppositeWlTitle}:`,
            value: formatNumber(fundsOpposite ?? ''),
          },
          {
            label: `Withdrawals ${oppositeWlTitle}:`,
            value: formatNumber(wrsOpposite ?? ''),
          },
          {
            label: `Total Fundings:`,
            value: formatNumber(totalFunds ?? ''),
          },
          {
            label: `Total Withdrawals:`,
            value: formatNumber(totalWrs ?? ''),
          },
        ],
      );
    }

    if (showOppositeAndOther) {
      result.push(
        ...[
          {
            label: 'Sum:',
            value: formatNumber(sum ?? ''),
          },
          {
            label: 'NAV Total:',
            value: formatNumber(totalNav ?? ''),
          },
        ],
      );
    }

    return result;
  }, [
    fundsOpposite,
    fundsOther,
    isDisabledTotals,
    oppositeWlTitle,
    showOppositeAndOther,
    sum,
    totalFunds,
    totalNav,
    totalWrs,
    wlTitle,
    wrsOpposite,
    wrsOther,
  ]);

  if (isFetching) {
    return (
      <StyledLoaderContainer className="LoaderContainer">
        <Loader size="l" isCentered isInner />
      </StyledLoaderContainer>
    );
  }

  return totalsView.length > 0 ? (
    <StyledTransactionTotalList className="TransactionTotalList">
      {totalsView.map(({ label, value }) => (
        <ValueRow
          key={`${label}_${value}`}
          label={label}
          value={`${value} ${DEFAULT_CURRENCY}`}
        />
      ))}
    </StyledTransactionTotalList>
  ) : null;
};

export const TransactionTotalMemoized = memo(TransactionTotal);
