import { APP_INSTANCE } from '../getCrmInstanceName';

export const getEnv = () => {
  const isStage = window.location.host.includes('stage');
  const isTest = window.location.host.includes('test');
  const isCstage = window.location.host.includes('stage.gozo');

  if (isTest) {
    return 'test';
  }

  if (isCstage) {
    return 'cstage';
  }

  if (isStage) {
    return 'stage';
  }

  return 'stage';
};

export const ENVIRONMENT = getEnv();

const DEFAULT_API_CRM_HOST = {
  stage: 'crm-backend-stage.exante.eu',
  test: 'crm-backend-test.exante.eu',
  cstage: 'crm-stage.gozo.pro',
};

const DEFAULT_API_CPRM_HOST = {
  stage: 'cprm-backend-stage.exante.eu',
  test: 'cprm-backend-test.exante.eu',
  cstage: '',
};

const DEFAULT_API_BO_HOST = {
  stage: 'node-back-stage.exante.eu',
  test: 'node-back-test.exante.eu',
  cstage: '',
};

const DEFAULT_API_AUDIT_LOG_HOST = {
  stage: 'auditlog-stage.exante.eu',
  test: 'auditlog-test.exante.eu',
  cstage: '',
};

const NON_HTTPS_HOSTS = [
  /crm.exante$/,
  /^localhost(:\d{2,6})?/,
  /^crm-\d{1,5}\.k8s\.stage\.zorg\.sh/, // branches
];

export const getAPIProtocol = (APIhost: string) => {
  return NON_HTTPS_HOSTS.find((regExp) => APIhost.match(regExp))
    ? 'http'
    : 'https';
};

const DEFAULT_API_HOST =
  APP_INSTANCE === 'cprm'
    ? DEFAULT_API_CPRM_HOST[ENVIRONMENT]
    : DEFAULT_API_CRM_HOST[ENVIRONMENT];

const isCrmInsideRun = () =>
  window.runUIhistoryContainer && APP_INSTANCE === 'crm';

const isCprmInsideRun = () =>
  window.runUIhistoryContainer && APP_INSTANCE === 'cprm';

const getWarnMsg = (
  title: string,
  variableName: string,
  envVariableName: string,
) => {
  return `[${title} Warning] CRM-UI application was run inside the RUN-UI, but ${variableName} is empty, so the ${envVariableName} from the environment variables is used as a fallback`;
};

export const getBaseCrmUrl = () => {
  if (isCrmInsideRun()) {
    const url = window.CRM_UI_REACT?.api_urls?.CRM;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseCrmUrl',
          'window.CRM_UI_REACT.api_urls.CRM',
          'DEFAULT_API_CRM_HOST',
        ),
      );
      return DEFAULT_API_CRM_HOST[ENVIRONMENT];
    }

    return url;
  }

  if (isCprmInsideRun()) {
    const url = window.CPRM_UI_REACT?.api_urls?.CPRM;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseCrmUrl',
          'window.CPRM_UI_REACT.api_urls.CPRM',
          'DEFAULT_API_CPRM_HOST',
        ),
      );
      return DEFAULT_API_CPRM_HOST[ENVIRONMENT];
    }

    return url;
  }

  return DEFAULT_API_HOST;
};

export const getBaseBOUrl = () => {
  if (isCrmInsideRun()) {
    const url = window.CRM_UI_REACT?.api_urls?.BO;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseBackOfficeUrl',
          'window.CRM_UI_REACT.api_urls.BO',
          'DEFAULT_API_BO_HOST',
        ),
      );
      return DEFAULT_API_BO_HOST[ENVIRONMENT];
    }

    return url;
  }

  if (isCprmInsideRun()) {
    const url = window.CPRM_UI_REACT?.api_urls?.BO;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseBackOfficeUrl',
          'window.CPRM_UI_REACT.api_urls.BO',
          'DEFAULT_API_BO_HOST',
        ),
      );
      return DEFAULT_API_BO_HOST[ENVIRONMENT];
    }

    return url;
  }

  return DEFAULT_API_BO_HOST[ENVIRONMENT];
};

export const getBaseAuditLogUrl = () => {
  if (isCrmInsideRun()) {
    const url = window.CRM_UI_REACT?.api_urls?.AUDIT_LOG;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseAuditLogUrl',
          'window.CRM_UI_REACT.api_urls.AUDIT_LOG',
          'DEFAULT_API_AUDIT_LOG_HOST',
        ),
      );
      return DEFAULT_API_AUDIT_LOG_HOST[ENVIRONMENT];
    }

    return url;
  }

  if (isCprmInsideRun()) {
    const url = window.CPRM_UI_REACT?.api_urls?.AUDIT_LOG;

    if (!url) {
      console.warn(
        getWarnMsg(
          'getBaseAuditLogUrl',
          'window.CPRM_UI_REACT.api_urls.AUDIT_LOG',
          'DEFAULT_API_AUDIT_LOG_HOST',
        ),
      );
      return DEFAULT_API_AUDIT_LOG_HOST[ENVIRONMENT];
    }

    return url;
  }

  return DEFAULT_API_AUDIT_LOG_HOST[ENVIRONMENT];
};
