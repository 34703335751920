import { ComponentProps, JSXElementConstructor } from 'react';

import { ReactComponent as GlobeIcon } from '../../../../../assets/globe.svg';
import { styled } from '../../../../../theme';

export const getPhoneCountryIcon = <
  C extends JSXElementConstructor<ComponentProps<C>>,
>(
  component: C,
) =>
  styled(component)(() => ({
    height: '16px',
    width: '16px',
    transform: 'scale(1.2)',
  }));

export const StyledInternationalIcon = styled(GlobeIcon)(({ theme }) => ({
  color: theme.color.icon.secondary,
}));
