import { ICellValue } from 'react-ui-kit-exante';

import { TDocsResult, TDocTypes } from '~/api/docs/docs.types';
import { APP_INSTANCE } from '~/utils/getCrmInstanceName';

import {
  StyledUploadedTableLink,
  StyledUploadedTableRow,
} from './UploadedDocumentsTable.styled';
import { DeleteActionCell } from './compnents/DeleteActionCell';
import { EditActionCell } from './compnents/EditActionCell/EditActionCell';

export const getColumns = (dataDocTypes: TDocTypes[], type: string) => {
  return [
    {
      Header: 'Date',
      accessor: 'uploaded_date',
      disableSortBy: true,
      disableFilters: true,
      formatting: 'date',
      width: '110',
    },
    {
      Header: 'Name',
      accessor: 'base_filename',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row: { original } }: ICellValue<TDocsResult>) => (
        <StyledUploadedTableLink
          href={`/${APP_INSTANCE}/document-${original?.id}`}
          target="_blank"
          rel="noreferrer"
          className="UploadedTableLink"
        >
          {original?.base_filename}
        </StyledUploadedTableLink>
      ),
    },
    {
      Header: 'Comment',
      accessor: 'history',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ row: { values } }: ICellValue<TDocsResult>) => {
        const history = values?.history;

        return (
          <StyledUploadedTableRow className="UploadedTableRow">{`${
            history?.moderator_comment || ''
          }  ${history?.user_comment || ''}`}</StyledUploadedTableRow>
        );
      },
    },
    {
      id: 'action',
      Header: 'Actions',
      disableSortBy: true,
      width: 120,
      align: 'right',
      Cell: ({ row: { original } }: ICellValue<TDocsResult>) => {
        const documentOptions = dataDocTypes?.map(
          ({ key, title }: TDocTypes) => ({
            value: key,
            label: title,
          }),
        );

        return (
          <StyledUploadedTableRow className="UploadedTableRow">
            <EditActionCell
              type={type}
              fileId={original?.id}
              history={original?.history}
              documentOptions={documentOptions}
              filename={original?.base_filename}
            />
            <DeleteActionCell fileId={original?.id} />
          </StyledUploadedTableRow>
        );
      },
    },
  ];
};
