import { useContext, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { TParams } from '~/router/router.types';
import { TLocationState } from '~/types/location';

import { TabsContext } from '../../contexts/TabsContext';

import { mapTabsComponents } from './TabEntry.constants';

export const TabEntry = () => {
  const { page } = useParams<TParams>();
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();

  const { requestParams, previousPath } =
    (location.state as TLocationState) || {};
  // fix for https://jira.exante.eu/browse/CRM-3792
  const locationState = {
    state: {
      previousPath,
      requestParams,
    },
  };

  const { currentTab, subTabs } = useContext(TabsContext);

  useEffect(() => {
    if (!page && currentTab) {
      navigate(`${currentTab?.tab?.url}`, locationState);
    }

    if (!hash && subTabs?.length) {
      navigate(`${pathname}#${subTabs[0]?.url}`, locationState);
    }
  }, [currentTab, pathname, subTabs]);

  const keyEntity = (hash.replace('#', '') ||
    page) as keyof typeof mapTabsComponents;

  const Component = mapTabsComponents?.[keyEntity] || <>Entity not found</>;

  return Component;
};
