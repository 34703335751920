import { styled } from '../../../../theme';
import { Select } from '../../Select';

export const StyledPhoneCountrySelect = styled(Select)(() => ({
  marginRight: '8px',

  '&.SelectSkeleton, &.SelectComponent': {
    width: '70px !important',
  },

  '.MuiFilledInput-input': {
    paddingTop: '11px',
    paddingBottom: '11px',
    paddingRight: '38px !important',
  },

  '.MuiFilledInput-input .PhoneCountryLabel': {
    visibility: 'hidden',
  },

  '.MuiInputBase-root': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
}));
