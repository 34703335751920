import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/utils/apiRequest';

import { TAdminChoicesApi } from './adminChoices.types';

export const adminChoicesApi = createApi({
  reducerPath: 'adminChoicesApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['AdminChoices'],
  endpoints: (builder) => ({
    getAdminChoices: builder.query<TAdminChoicesApi[], string>({
      query: (queryParams) => ({
        url: `/rest/admins/admin-choices/?${queryParams}`,
      }),
      providesTags: ['AdminChoices'],
    }),
  }),
});
export const { useGetAdminChoicesQuery } = adminChoicesApi;
