import { FC, useContext } from 'react';
import { IconButton, Tooltip } from 'react-ui-kit-exante';

import { ApplicationFormContext } from '../../../contexts/FormContext';

import { StyledLoader, StyledTabHeader } from './TabHeader.styled';

export const TabHeader: FC = () => {
  const { formNotEdited, isLoading, isErrors } = useContext(
    ApplicationFormContext,
  );

  return (
    <StyledTabHeader className="TabHeader">
      <Tooltip
        placement="left"
        title={isErrors ? 'Please fill the required fields' : ''}
      >
        <>
          {!isLoading && (
            <IconButton
              label="Save"
              type="submit"
              iconName="SaveIcon"
              iconColor="action"
              iconSize={24}
              disabled={formNotEdited}
              className="SaveApplicactionButton"
              data-test-id="save-applicaction--button"
            />
          )}
          {isLoading && <StyledLoader className="Loader" />}
        </>
      </Tooltip>
    </StyledTabHeader>
  );
};
