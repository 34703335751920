import type { AnyAction, Dispatch, Middleware } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';

import {
  depositsApi,
  permissionsApi,
  personalSettingsApi,
  bookmarksApi,
  requestsApi,
  dataChangeRequestsApi,
  withdrawalsApi,
  commentsApi,
  globalContextApi,
  contactsApi,
  tagsApi,
  userManagementApi,
  adminChoicesApi,
  applicationLegalEntitiesApi,
  reportsApi,
  notificationsApi,
  applicationsApi,
  accountsApi,
  assetsApi,
  notificationsNodeBackApi,
  symbolsApi,
  usersApi,
  tradesApi,
  typesApi,
  accountPermissionsApi,
  accessRightsApi,
  computedUsersApi,
  bookmarksBOApi,
  transactionsApi,
  generalUsersApi,
  managersApi,
  docsApi,
  personsApi,
  backOfficeProxyApi,
  applicationTransactionsApi,
  riskScoringApi,
} from '~/api';

import { rootReducer } from './rootReducer';

type MiddlewareFn = Middleware<
  Record<string, unknown>,
  any,
  Dispatch<AnyAction>
>;

const apis = [
  permissionsApi,
  depositsApi,
  personalSettingsApi,
  bookmarksApi,
  requestsApi,
  dataChangeRequestsApi,
  withdrawalsApi,
  contactsApi,
  commentsApi,
  globalContextApi,
  tagsApi,
  userManagementApi,
  adminChoicesApi,
  applicationLegalEntitiesApi,
  reportsApi,
  notificationsApi,
  applicationsApi,
  accountsApi,
  assetsApi,
  notificationsNodeBackApi,
  symbolsApi,
  usersApi,
  tradesApi,
  typesApi,
  accountPermissionsApi,
  accessRightsApi,
  computedUsersApi,
  transactionsApi,
  bookmarksBOApi,
  generalUsersApi,
  managersApi,
  docsApi,
  personsApi,
  backOfficeProxyApi,
  applicationTransactionsApi,
  riskScoringApi,
];

export const createStore = (middlewares: MiddlewareFn[] = []) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false,
      }).concat([...apis.map(({ middleware }) => middleware), ...middlewares]);
    },
    devTools: process.env.NODE_ENV !== 'production',
  });
};
