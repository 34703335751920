import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { TChildren } from 'types/TChildren';
import {
  useLazyGetGetHiddenFieldsQuery,
  useUpdateDepositInfoMutation,
} from '~/api';
import { TDepositInfo, TDepositType } from '~/api/deposits/deposits.types';
import { TDefaultFormValues } from '~/types/form';
import { checkKeyDown } from '~/utils/checkKeyDown';
import { getDirtyValues } from '~/utils/forms/getDirtyValues';

import { DEPOSIT_TYPES } from '../../DepositForm.constants';

import { getDefaultFormValues, getFormValidationScheme } from './helpers';

type TDepositFormContext = {
  onSave: VoidFunction;
  isEdit: boolean;
  isFormDisabled: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  setIsFormDisabled: Dispatch<SetStateAction<boolean>>;
  formNotEdited: boolean;
  hiddenFields: string[];
  isFormLoading: boolean;
};

type TDepositFormProps = {
  deposit: TDepositInfo;
};

const initialState: TDepositFormContext = {
  onSave: () => {},
  isEdit: false,
  isFormDisabled: false,
  setIsEdit: () => {},
  setIsFormDisabled: () => {},
  formNotEdited: true,
  hiddenFields: [],
  isFormLoading: false,
};

export const DepositFormContext =
  createContext<TDepositFormContext>(initialState);

export const DepositFormProvider: FC<TDepositFormProps & TChildren> = ({
  children,
  deposit,
}) => {
  const [onUpdateDepositInfo, updateDepositState] =
    useUpdateDepositInfoMutation();
  const [getGetHiddenFields, getHiddenFieldsState] =
    useLazyGetGetHiddenFieldsQuery();

  const [isEdit, setIsEdit] = useState(initialState.isEdit);
  const [isFormDisabled, setIsFormDisabled] = useState(
    initialState.isFormDisabled,
  );

  const defaultValues = getDefaultFormValues(deposit);
  const validationScheme = getFormValidationScheme(deposit);

  const methods = useForm<TDefaultFormValues>({
    defaultValues,
    resolver: yupResolver(validationScheme),
  });

  const { handleSubmit, getValues, formState, watch, reset } = methods;

  const formData = getDirtyValues(
    formState.dirtyFields,
    getValues(),
    getHiddenFieldsState.data,
  );

  const onSave = async () => {
    const res = await onUpdateDepositInfo({
      depositId: deposit.id,
      updateInfo: formData,
    });

    if (!('error' in res)) {
      setIsEdit(false);

      reset(getDefaultFormValues(res.data));
    }
  };

  const depositType = watch('deposit_type');

  useEffect(() => {
    if (depositType) {
      getGetHiddenFields({
        type: DEPOSIT_TYPES[depositType as TDepositType],
      });
    }
  }, [depositType]);

  useEffect(() => {
    reset(defaultValues);
  }, [isEdit]);

  const value = useMemo(
    (): TDepositFormContext => ({
      onSave,
      isEdit,
      isFormDisabled,
      setIsFormDisabled,
      setIsEdit,
      formNotEdited: isEmpty(formData),
      hiddenFields: getHiddenFieldsState.data || [],
      isFormLoading: updateDepositState.isLoading,
    }),
    [
      onSave,
      formData,
      isEdit,
      getHiddenFieldsState.data,
      isFormDisabled,
      updateDepositState.isLoading,
    ],
  );

  return (
    <DepositFormContext.Provider value={value}>
      <FormProvider {...methods}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <form
          onSubmit={handleSubmit(onSave)}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          {children}
        </form>
      </FormProvider>
    </DepositFormContext.Provider>
  );
};
