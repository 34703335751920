import { StyledTransactionContent } from './Transactions.styled';
import { TransactionsHeader } from './components/TransactionsHeader';
import { TransactionsTable } from './components/TransactionsTable';
import { TransactionTotal } from './components/TransactionsTotal';
import { useGetTransactionsData } from './hooks/useGetTransactionsData';

export const Transactions = () => {
  const {
    options,
    selectedAccountId,
    selectedAccount,
    onChangeHandler,
    canView,
  } = useGetTransactionsData();

  return (
    <>
      <TransactionsHeader
        options={options}
        value={selectedAccount}
        onChange={onChangeHandler}
      />
      <StyledTransactionContent className="TransactionContent">
        <TransactionsTable
          selectedAccountId={selectedAccountId}
          canView={canView}
        />
        {canView && <TransactionTotal />}
      </StyledTransactionContent>
    </>
  );
};
