import { Loader, styled } from 'react-ui-kit-exante';

export const StyledTabHeader = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
`;

export const StyledLoader = styled(Loader)`
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
