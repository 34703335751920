import { object } from 'yup';

import { TDepositInfo } from '~/api/deposits/deposits.types';
import { FieldTypes } from '~/constants/common';
import { TDefaultFormValues } from '~/types/form';
import { mapTypeToValidationFn } from '~/utils/forms/mapTypeToValidationFn';

import { replaceZeroAfterNumber } from '../../../../../../utils/replaceZeroAfterNumber';
import { getClientDetailsFields } from '../../DepositForm.constants';
import { getFields } from '../../DepositForm.helpers';

export const getDefaultFormValues = (deposit: TDepositInfo) => {
  return [
    ...getClientDetailsFields(deposit),
    ...getFields({ deposit }),
  ].reduce<TDefaultFormValues>((acc, curr) => {
    const key = curr.name as keyof TDepositInfo;
    if (curr.editable === false) {
      return { ...acc };
    }

    if (curr.type === FieldTypes.DateTimeField) {
      return {
        ...acc,
        [curr.name]: deposit[key] ? new Date(String(deposit[key])) : null,
      };
    }

    const value = curr?.subKey ? deposit[key]?.[curr.subKey] : deposit[key];

    if (curr.name === 'amount') {
      return {
        ...acc,
        [curr.name]: replaceZeroAfterNumber(value) || '',
      };
    }

    return {
      ...acc,
      [curr.name]: value,
    };
  }, {});
};

export const getFormValidationScheme = (deposit: TDepositInfo) => {
  const scheme = [
    ...getClientDetailsFields(deposit),
    ...getFields({ deposit }),
  ].reduce((acc, curr) => {
    if (curr.required) {
      const valueType = curr.valueType;
      return {
        ...acc,
        [curr.name]: mapTypeToValidationFn(valueType)
          .required(`${curr.title} is a required field`)
          .typeError(valueType ? `${curr.title} must be a '${valueType}'` : ''),
      };
    }

    return {
      ...acc,
    };
  }, {});
  return object(scheme);
};
