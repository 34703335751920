import { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IColumn,
  IconButton,
  IOnFetchArguments,
  Table,
  TAdditionalFilter,
  useTableData,
} from 'react-ui-kit-exante';

import {
  useGetPermissionsQuery,
  useGetRequestsTableFiltersQuery,
  useLazyGetRequestsQuery,
  useRequestsExportCsvMutation,
} from '~/api';
import { TRequest } from '~/api/requests/requests.types';
import { RefreshButton } from '~/components/RefreshButton';
import { defaultSortByGlobal } from '~/constants/table';
import {
  useCallbackTriggerHandle,
  useGetTableFilterOptions,
  useLogHandleTime,
} from '~/hooks';
import { PATHS } from '~/router';
import { prepareFilterParams } from '~/utils/prepareFilterParams';
import { prepareSelectedColumns } from '~/utils/prepareSelectedColumns';
import {
  calculateCountOfPages,
  getAdditionalFilters,
  getDefaultPagination,
  getFilterParams,
  getPaginationParams,
} from '~/utils/table';
import { getTableColumnsFromLs } from '~/utils/table/getTableColumnsFromLs';
import { getSortingParams } from '~/utils/table/sorting';

import { getColumns } from './Requests.columns';
import {
  DISPLAYED_COLUMNS_KEYS,
  REQUESTS_DATE_FIELDS,
  TABLE_ID,
} from './Requests.constants';
import { getRowProps } from './Requests.helpers';

export const Requests: FC = () => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `requests-page-${TABLE_ID}`,
  );
  const navigate = useNavigate();

  const { data: requestsTableFilters } = useGetRequestsTableFiltersQuery();
  const { data: permissions } = useGetPermissionsQuery();

  const [
    fetchRequests,
    { isLoading: isGetRequestsLoading, isSuccess: isGetRequestsSuccess },
  ] = useLazyGetRequestsQuery();

  useEffect(() => {
    if (isGetRequestsLoading) {
      setStartHandleTime();
    }
  }, [isGetRequestsLoading, setStartHandleTime]);

  const additionalOptions = useGetTableFilterOptions(requestsTableFilters);

  const [triggerExportCsv] = useRequestsExportCsvMutation();

  const getRequests = useCallback(
    async (params: IOnFetchArguments) => {
      const paginationParams = getPaginationParams(params);
      const filterParams = prepareFilterParams(
        getFilterParams(params, REQUESTS_DATE_FIELDS),
      );
      const sortingParams = getSortingParams(params);

      const response = await fetchRequests({
        ...paginationParams,
        ...filterParams,
        ...sortingParams,
      });
      return response.data;
    },
    [fetchRequests],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId: TABLE_ID,
      data: { onFetch: getRequests },
      pagination: {
        getDefaultPagination,
      },
      saveViewParamsAfterLeave: true,
      hasNegativeFilters: true,
    }),
    [getRequests],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    skip,
    isLoading,
    setSorting,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    fetchData,
    params,
  } = useTableData(tableDataArgs);

  const additionalFilters = useMemo<
    TAdditionalFilter<Record<string, unknown>>[]
  >(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        filters: requestsTableFilters,
        additionalOptions,
      }),
    [removeFilter, setFilter, requestsTableFilters, additionalOptions],
  );

  const filterProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
      manualFilters: true,
    }),
    [additionalFilters, filters, resetFilters],
  );

  const total = data?.iTotalDisplayRecords || 0;

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount: calculateCountOfPages(total, limit),
    }),
    [skip, limit, page, setLimit, setPage, total],
  );

  const columns = useMemo(() => {
    return getColumns({
      recordPermissions: permissions?.record,
      fetchData,
    });
  }, [permissions?.record, fetchData]);

  const displayedColumnKeys = useMemo(() => DISPLAYED_COLUMNS_KEYS, []);

  const handleExportCsv = async () => {
    const selectedColumns = prepareSelectedColumns(
      getTableColumnsFromLs(TABLE_ID),
    );
    const preparedFilters = prepareFilterParams(filters);

    await triggerExportCsv({
      preparedFilters,
      selectedColumns,
    });
  };

  const additionalActions = [
    {
      component: (
        <>
          <RefreshButton
            onRefresh={fetchData}
            disabled={isLoading}
            iconColor="secondary"
            title="Refresh table data"
          />
          <IconButton
            onClick={handleExportCsv}
            iconSize={24}
            iconName="ExportIcon"
            iconColor="secondary"
            title="Export records to csv"
            data-test-id={`${TABLE_ID}__button--export-csv`}
            className="RequestsExportCsv"
          />
        </>
      ),
    },
  ];

  const handleRowClick = useCallback(
    (tableData: IColumn<TRequest>) => {
      const { column, row } = tableData;

      if (column.id !== 'action' && permissions?.record?.change) {
        navigate(`${PATHS.MONITORING}/${row.original.id}`, {
          state: {
            previousPath: window.location.href,
            requestParams: params,
          },
        });
      }
    },
    [navigate, params, permissions?.record?.change],
  );

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: data?.data,
    processTrigger: !isGetRequestsLoading && isGetRequestsSuccess,
  });

  return (
    <Table<TRequest>
      title="Monitoring Requests"
      className="RequestsTable"
      columns={columns}
      isLoading={isLoading}
      displayedColumnKeys={displayedColumnKeys}
      filtersExpanded
      isFlexLayout
      manualSortBy
      defaultSortBy={defaultSortByGlobal}
      onSort={setSorting}
      hasFilters
      filteringProps={filterProps}
      data={data?.data || []}
      tableId={TABLE_ID}
      hasPagination
      hasNegativeFilters
      showTableInfo
      serverPaginationProps={serverPaginationProps}
      handleCellClick={handleRowClick}
      saveColumnOrder
      saveViewParamsAfterLeave
      additionalActions={additionalActions}
      getRowProps={getRowProps}
    />
  );
};
