import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import { ALL_LEGAL_ENTITY } from '~/constants/common';
import { baseQueryHandler } from '~/utils/apiRequest';

import { NODE_BACK_URL } from '../nodeBackApi/endpoints';

import {
  TActionsInfo,
  TApplicationAllData,
  TApplicationLegalDocs,
  TApplicationSaveParams,
  TApplicationStructure,
  TGetApplicationParams,
  TGetTagsParams,
  TLinkedApplications,
  TLinkedApplicationsChoices,
  TTableSection,
  TTagsResponse,
} from './applications.types';

export const applicationsApi = createApi({
  reducerPath: 'applicationsApi',
  baseQuery: baseQueryHandler,
  tagTypes: [
    'Application',
    'ApplicationStructure',
    'ApplicationTags',
    'ActionsInfo',
    'LinkedApplicationChoices',
    'LinkedApplications',
    'TableSection',
  ],
  endpoints: (builder) => ({
    getApplication: builder.query<TApplicationAllData, TGetApplicationParams>({
      query: ({ id, crmId, legalEntity = ALL_LEGAL_ENTITY }) => ({
        url: `${NODE_BACK_URL}/api/users/computed/entry/`,
        params: {
          id,
          crmId,
          le: legalEntity,
        },
      }),
      providesTags: ['Application'],
    }),
    getApplicationStructure: builder.query<
      TApplicationStructure,
      TGetApplicationParams
    >({
      query: ({ id, legalEntity = ALL_LEGAL_ENTITY }) => ({
        url: `/rest/sections/`,
        params: {
          application_id: id,
          legalEntity,
        },
      }),
      providesTags: ['ApplicationStructure'],
    }),

    getApplicationLogs: builder.query<(string | number)[][], { id?: number }>({
      query: ({ id }) => ({
        url: `/rest/applications/modal/${id}/logs/`,
      }),
    }),

    getApplicationLegalDocs: builder.query<
      TApplicationLegalDocs[],
      { id?: number }
    >({
      query: ({ id }) => ({
        url: `/rest/applications/modal/${id}/legaldocs/?le=All`,
      }),
    }),

    getApplicationOtherLegalDocs: builder.query<
      TApplicationLegalDocs[],
      { id?: number }
    >({
      query: ({ id }) => ({
        url: `/rest/applications/modal/${id}/other-legaldocs/?le=All`,
      }),
    }),

    getApplicationLegalDocUrl: builder.query<
      { url: string },
      { docId: number; legalEntity: string }
    >({
      query: ({ docId, legalEntity }) => ({
        url: `/rest/legaldocs/signed/${docId}/download/?le=${legalEntity}`,
      }),
    }),

    getApplicationLegalDocPDFUrl: builder.query<
      { url: string },
      { urlWithId: string }
    >({
      query: ({ urlWithId }) => ({
        url: `${urlWithId}json/?download=true`,
      }),
    }),

    getApplicationLegalDocPDF: builder.query<Blob, { url: string }>({
      query: ({ url }) => ({
        url: `${url}`,
        requestOptions: { responseType: 'blob' },
      }),
    }),

    applicationActions: builder.mutation<
      void,
      { applicationId: number; action: string; successMessage: string }
    >({
      query: ({ applicationId, action }) => ({
        url: `/rest/applications/application/${applicationId}/${action}/`,
        method: 'POST',
      }),
      async onQueryStarted({ successMessage }, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: successMessage || 'User successfully transitioned',
          });
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['ActionsInfo'],
    }),

    loginAction: builder.query<{ url: string }, { applicationId: number }>({
      query: ({ applicationId }) => ({
        url: `/rest/admins/sudo/${applicationId}/?noredirect`,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Application redirected to login',
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),

    convertToApplication: builder.query<void, { applicationId: number }>({
      query: ({ applicationId }) => ({
        url: `/rest/applications/${applicationId}/convert/`,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Converted to application',
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),

    sendMessageToClient: builder.mutation<
      void,
      {
        applicationId: number;
        params: { locale: string; message_text: string };
      }
    >({
      query: ({ applicationId, params }) => ({
        url: `/rest/applications/${applicationId}/send-message/`,
        method: 'POST',
        data: params,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Message has been sent',
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),

    getApplicationTags: builder.query<TTagsResponse[], TGetTagsParams>({
      query: ({ objectId, sectionName, legalEntity }) => ({
        url: `/rest/tags/tag-group/app_related_tagvalues/`,
        params: {
          object_id: objectId,
          name__in: sectionName,
          le: legalEntity || ALL_LEGAL_ENTITY,
        },
      }),
      providesTags: ['ApplicationTags'],
    }),

    getApplicationActionsInfo: builder.query<
      TActionsInfo,
      { applicationId: number }
    >({
      query: ({ applicationId }) => ({
        url: `/rest/applications/modal/${applicationId}/actions/`,
      }),
      providesTags: ['ActionsInfo'],
    }),
    saveApplication: builder.mutation<
      void,
      { applicationId: number; params: TApplicationSaveParams }
    >({
      query: ({ applicationId, params }) => ({
        url: `/rest/application-save/${applicationId}/`,
        method: 'PATCH',
        data: params,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Application successfully saved',
          });
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['Application', 'ApplicationTags'],
    }),
    rejectApplication: builder.mutation<
      void,
      { applicationId: number; review_text: string }
    >({
      query: ({ applicationId, review_text }) => ({
        url: `/rest/applications/reject/${applicationId}/`,
        method: 'PATCH',
        data: { review_text },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Application successfully rejected',
          });
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['ActionsInfo'],
    }),
    clientActions: builder.mutation<
      void,
      { data: Record<string, boolean | number> }
    >({
      query: ({ data }) => ({
        url: `/rest/clients/${data.id}/`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Client successfully transitioned',
          });
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['Application'],
    }),
    getLinkedApplicationChoices: builder.query<
      TLinkedApplicationsChoices,
      { applicationId: number; query: string }
    >({
      query: ({ applicationId, query }) => ({
        url: `/rest/applications/application/linked-applications-choices/`,
        params: {
          query,
          limit: 20,
          exclude_id: applicationId,
        },
      }),
      providesTags: ['LinkedApplicationChoices'],
    }),
    getLinkedApplications: builder.query<
      TLinkedApplications,
      { applicationId: number }
    >({
      query: ({ applicationId }) => ({
        url: `/rest/applications/linked-applications/${applicationId}/`,
      }),
      providesTags: ['LinkedApplications'],
    }),
    getTableSection: builder.query<
      TTableSection,
      { applicationId: number; tableKey: string }
    >({
      query: ({ applicationId, tableKey }) => ({
        url: `/rest/tables/table-data/${applicationId}/${tableKey}/`,
      }),
      providesTags: ['TableSection'],
    }),
    deleteTableRow: builder.mutation<void, { rowId: number }>({
      query: ({ rowId }) => ({
        url: `/rest/tables/table-row/${rowId}/`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Row successfully deleted',
          });
        } catch (e) {
          Notification.error({
            title: 'Row deleting error ',
          });
        }
      },
      invalidatesTags: ['TableSection'],
    }),

    requestStatusAction: builder.mutation<
      void,
      {
        applicationId: number;
        type: string;
      }
    >({
      query: ({ applicationId, type }) => ({
        url: `/rest/applications/modal-data/${applicationId}/${type}/`,
        method: 'POST',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Request successfully sent',
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),
    addTableRow: builder.mutation<
      void,
      { numberOfRows: number; tableId: number }
    >({
      query: ({ numberOfRows, tableId }) => ({
        url: `/rest/tables/table-row/`,
        method: 'POST',
        data: {
          n_row: numberOfRows,
          table: tableId,
        },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Row successfully added',
          });
        } catch (e) {
          Notification.error({
            title: 'Row adding error ',
          });
        }
      },
      invalidatesTags: ['TableSection'],
    }),
    addTableValue: builder.mutation<
      void,
      { applicationId: number; columnId: number; rowId: number; value: string }
    >({
      query: ({ applicationId, columnId, rowId, value }) => ({
        url: `/rest/tables/table-value/`,
        method: 'POST',
        data: {
          application: applicationId,
          column: columnId,
          row: rowId,
          value,
        },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Row successfully added',
          });
        } catch (e) {
          Notification.error({
            title: 'Row adding error ',
          });
        }
      },
      invalidatesTags: ['TableSection'],
    }),
  }),
});

export const {
  useLazyGetApplicationQuery,
  useLazyGetApplicationStructureQuery,
  useGetApplicationLogsQuery,
  useGetApplicationLegalDocsQuery,
  useGetApplicationOtherLegalDocsQuery,
  useLazyGetApplicationLegalDocUrlQuery,
  useLazyGetApplicationLegalDocPDFUrlQuery,
  useLazyGetApplicationLegalDocPDFQuery,
  useApplicationActionsMutation,
  useLazyLoginActionQuery,
  useLazyConvertToApplicationQuery,
  useSendMessageToClientMutation,
  useLazyGetApplicationTagsQuery,
  useLazyGetApplicationActionsInfoQuery,
  useSaveApplicationMutation,
  useRejectApplicationMutation,
  useClientActionsMutation,
  useLazyGetLinkedApplicationChoicesQuery,
  useGetLinkedApplicationsQuery,
  useLazyGetLinkedApplicationsQuery,
  useGetTableSectionQuery,
  useLazyGetTableSectionQuery,
  useDeleteTableRowMutation,
  useRequestStatusActionMutation,
  useAddTableRowMutation,
} = applicationsApi;
