import { useEffect, useState } from 'react';
import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { useGetGlobalContextQuery } from '~/api';
import { TColumn, TContacts } from '~/api/globalContext/globalContext.types';
import { ContactInfoCell } from '~/components/ContactInfoCell';

import { COLUMNS_DATE_FIELDS } from './Contacts.constants';
import { TNormalizedColumn } from './Contacts.types';

export function useDynamicData() {
  const [columns, setColumns] = useState<TNormalizedColumn[]>([]);
  const [defaultVisibleColumns, setDefaultVisibleColumns] = useState<
    string[] | null
  >(null);

  const {
    data: globalContext,
    isLoading,
    isFetching,
  } = useGetGlobalContextQuery();

  const isLoadingGlobalContext = isLoading || isFetching;

  const contactsColumns = globalContext?.table_columns.contact || [];

  const getNormalizedColumns = (responseColumns: IColumn<TColumn>[]) => {
    return responseColumns?.reduce((acc, item): IColumn<TContacts>[] => {
      const columnId: string = item.id;
      const columnType: string = item?.tag_type;

      if (columnId === 'name') {
        const customColumn = {
          accessor: 'name',
          Header: 'Info',
          Cell: ({ row: { values } }: ICellValue<Record<string, unknown>>) => {
            const { name, email, phone } = values;
            return <ContactInfoCell name={name} email={email} phone={phone} />;
          },
        };
        return [customColumn, ...acc];
      }

      const isDateFieldFormat =
        columnType === 'date' || COLUMNS_DATE_FIELDS.includes(columnId);

      return [
        ...acc,
        {
          accessor: item.id,
          Header: item.title,
          disableFilters: true, // we are getting filters from backend
          ...(isDateFieldFormat
            ? {
                formatting: 'dateUTC',
              }
            : {}),
        },
      ];
    }, [] as IColumn<TColumn>[]);
  };

  useEffect(() => {
    if (!isLoadingGlobalContext && contactsColumns) {
      setColumns(getNormalizedColumns(contactsColumns));
      setDefaultVisibleColumns(
        contactsColumns?.filter((col) => col.checked).map((col) => col.id),
      );
    }
  }, [contactsColumns, isLoadingGlobalContext]);

  return {
    columns,
    defaultVisibleColumns,
    isLoadingGlobalContext,
  };
}
