import { styled, IconButton, Panel } from 'react-ui-kit-exante';

export const StyledNotificationsEditorWrapper = styled(Panel)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 0;

  .TabActionsContainer {
    padding: 0 24px;
  }
`;

export const StyledNotificationsEditorSaveBtn = styled(IconButton)`
  margin-left: auto;
`;
