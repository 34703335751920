import { PasswordManagement } from './components';
import { PasswordManagementProvider } from './context';

export const PasswordManagementContainer = () => {
  return (
    <PasswordManagementProvider>
      <PasswordManagement />
    </PasswordManagementProvider>
  );
};
