import { useState } from 'react';

import { TAccountPermissionByUsername, TPrevPermissionsData } from '~/api';

export function usePreviousPermissions(
  defaultPermissions: TAccountPermissionByUsername[],
): TPrevPermissionsData {
  // We are using useState here because we need to re-render the form after changing the state
  const [previousPermissions, setPreviousPermissions] =
    useState<TAccountPermissionByUsername[]>(defaultPermissions);

  return { previousPermissions, setPreviousPermissions };
}
