import { useState } from 'react';

type TCopiedValue = string | null;
type TCopyFn = (text: string) => Promise<boolean>;

export function useCopyToClipboard(
  initialValue?: string,
): [TCopiedValue, TCopyFn] {
  const [copiedText, setCopiedText] = useState<TCopiedValue>(
    initialValue ?? null,
  );

  const copy: TCopyFn = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
}
