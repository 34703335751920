import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/utils/apiRequest';

import {
  TApplicationAccountSegregations,
  TApplicationAccountSegregationParams,
} from './backOfficeProxy.types';

export const backOfficeProxyApi = createApi({
  reducerPath: 'backOfficeProxyApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['ApplicationTransactions'],
  endpoints: (builder) => ({
    getApplicationAccountSegregations: builder.query<
      TApplicationAccountSegregations,
      TApplicationAccountSegregationParams
    >({
      query: ({ applicationId, page, pageSize }) => ({
        url: `/rest/backoffice_proxy/${applicationId}/account_segregations/`,
        params: {
          page,
          page_size: pageSize,
        },
      }),
    }),
  }),
});

export const { useLazyGetApplicationAccountSegregationsQuery } =
  backOfficeProxyApi;
