import cn from 'classnames';
import { FC, useMemo } from 'react';
import { getCountries, isPossiblePhoneNumber } from 'react-phone-number-input';

import { StyledPhoneInput } from './Phone.styled';
import { PhoneContext } from './PhoneContext';
import { PhoneCountrySelect } from './PhoneCountrySelect';
import { PhoneInput } from './PhoneInput';
import { IPhoneInputProps } from './types';

export const Phone: FC<IPhoneInputProps> = ({
  translateFunction,
  className,
  countriesExcluded,
  countries,
  ...rest
}) => {
  const contextValue = useMemo(
    () => ({
      translate:
        translateFunction ||
        function translate(val: string): string {
          return val;
        },
    }),
    [translateFunction],
  );

  const countriesWithoutExcluded = useMemo(() => {
    const countriesIncluded = countries || getCountries();

    return !countriesExcluded?.length
      ? countriesIncluded
      : countriesIncluded.filter(
          (country) => !countriesExcluded?.includes(country),
        );
  }, [countries, countriesExcluded]);

  return (
    <PhoneContext.Provider value={contextValue}>
      <StyledPhoneInput
        className={cn('PhoneWrapper', className)}
        international
        inputComponent={PhoneInput}
        countrySelectComponent={PhoneCountrySelect}
        countries={countriesWithoutExcluded}
        rules={{ validate: isPossiblePhoneNumber }}
        {...rest}
      />
    </PhoneContext.Provider>
  );
};
