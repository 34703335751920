export type TContactsProps = {
  tableId: string;
  pageName: string;
};

export enum EReportTypes {
  commentsReport = 'commentsreport',
  clientsCompliance = 'clientscompliance',
  applications = 'applications',
}

export type TNormalizedColumn = {
  accessor: string;
  Header: string;
};
