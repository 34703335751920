import { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  calculateCountOfPages,
  IconButton,
  IOnFetchArguments,
  Table,
  TAdditionalFilter,
  useTableData,
} from 'react-ui-kit-exante';

import {
  useLazyExportCsvQuery,
  useLazyGetDataChangeRequestsQuery,
} from '~/api/dataChangeRequests';
import { TDataChangeRequests } from '~/api/dataChangeRequests/dataChangeRequests.types';
import { RefreshButton } from '~/components/RefreshButton';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';
import { PATHS } from '~/router';
import {
  getDefaultPagination,
  getFilterParams,
  getPaginationParams,
} from '~/utils/table';

import {
  DISPLAYED_COLUMNS_KEYS,
  getColumns,
  TABLE_ID,
} from './DataChangeRequests.constants';

export const DataChangeRequests: FC = () => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `data-change-requests-page-${TABLE_ID}`,
  );
  const navigate = useNavigate();

  const [
    fetchDataChangeRequests,
    {
      isLoading: isGetDataChangeRequestsLoading,
      isSuccess: isGetDataChangeRequestsSuccess,
    },
  ] = useLazyGetDataChangeRequestsQuery();
  const [triggerExportCsv] = useLazyExportCsvQuery();

  useEffect(() => {
    if (isGetDataChangeRequestsLoading) {
      setStartHandleTime();
    }
  }, [isGetDataChangeRequestsLoading, setStartHandleTime]);

  const getDataChangeRequests = useCallback(
    async (params: IOnFetchArguments) => {
      const paginationParams = getPaginationParams(params);
      const filterParams = getFilterParams(params);

      const response = await fetchDataChangeRequests({
        ...paginationParams,
        ...filterParams,
      });

      return response.data;
    },
    [fetchDataChangeRequests],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId: TABLE_ID,
      data: {
        onFetch: getDataChangeRequests,
      },
      filters: {
        getDefaultFilters: () => {
          return { not_approved: true };
        },
      },
      pagination: {
        getDefaultPagination,
      },
      saveViewParamsAfterLeave: true,
    }),
    [getDataChangeRequests],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    skip,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    fetchData,
    params,
  } = useTableData(tableDataArgs);

  const total = data?.iTotalDisplayRecords ?? 0;

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount: calculateCountOfPages(total, limit),
    }),
    [skip, limit, page, setLimit, setPage, total],
  );

  const additionalFilters = useMemo<
    TAdditionalFilter<Record<string, unknown>>[]
  >(
    () => [
      {
        Header: 'New only',
        accessor: 'not_approved',
        type: 'checkbox',
        onFilter: setFilter,
        onRemove: removeFilter,
      },
    ],
    [removeFilter, setFilter],
  );

  const filterProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      additionalFilters,
    }),
    [filters, resetFilters, additionalFilters],
  );

  const columns = getColumns();

  const displayedColumnKeys = useMemo(() => DISPLAYED_COLUMNS_KEYS, []);

  const handleExportCsv = async () => {
    await triggerExportCsv({
      ...filters,
    });
  };

  const handleRowClick = useCallback(
    ({ id }: TDataChangeRequests) =>
      navigate(`${PATHS.DATA_CHANGE_REQUESTS}/${id}`, {
        state: {
          previousPath: window.location.href,
          requestParams: params,
        },
      }),
    [navigate, params],
  );

  const additionalActions = [
    {
      component: (
        <>
          <RefreshButton
            onRefresh={fetchData}
            disabled={isLoading}
            iconColor="secondary"
            title="Refresh table data"
          />
          <IconButton
            onClick={handleExportCsv}
            iconSize={24}
            iconName="ExportIcon"
            iconColor="secondary"
            title="Export requests to csv"
            data-test-id="data-change-requests__button--export-csv"
            className="DataChangeRequestsExportCsv"
          />
        </>
      ),
    },
  ];

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: data?.data,
    processTrigger:
      !isGetDataChangeRequestsLoading && isGetDataChangeRequestsSuccess,
  });

  return (
    <Table<TDataChangeRequests>
      title="Data Change Requests"
      className="DataChangeRequestsTable"
      titleSize={1}
      columns={columns}
      displayedColumnKeys={displayedColumnKeys}
      isLoading={isLoading}
      filtersExpanded
      isFlexLayout
      disableSortBy
      manualSortBy
      hasFilters
      filteringProps={filterProps}
      data={data?.data || []}
      tableId={TABLE_ID}
      handleRowClick={handleRowClick}
      hasPagination
      showTableInfo
      saveColumnOrder
      serverPaginationProps={serverPaginationProps}
      isHiddenColumnSelect
      saveViewParamsAfterLeave
      additionalActions={additionalActions}
    />
  );
};
