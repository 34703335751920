import { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IColumn, IconButton } from 'react-ui-kit-exante';

import {
  useGetPermissionsQuery,
  useLazyGetApplicationRequestsQuery,
} from 'api';
import { TRequest } from '~/api/requests/requests.types';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { PATHS, ROOT_PATH } from '~/router';

import { COLUMNS, REQUESTS_TABLE_ID } from './RequestTable.constants';
import {
  StyledRequestTableWrapper,
  StyledRequestTable,
} from './RequestTable.styled';

export const RequestTable = () => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `contracts-entry-${REQUESTS_TABLE_ID}`,
  );
  const { data: permissions } = useGetPermissionsQuery();

  const navigate = useNavigate();
  const { application, activeLegalEntity } = useContext(ApplicationContext);

  const applicationId = application?.application.id;

  const [
    getApplicationRequestsQuery,
    { data, isLoading, isFetching, isSuccess },
  ] = useLazyGetApplicationRequestsQuery();

  useEffect(() => {
    if (applicationId) {
      getApplicationRequestsQuery({
        applicationId,
        legalEntity: activeLegalEntity,
      });
    }
  }, [applicationId, activeLegalEntity]);

  useEffect(() => {
    if (isLoading) {
      setStartHandleTime();
    }
  }, [isLoading, setStartHandleTime]);

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: data,
    processTrigger: !isLoading && isSuccess,
  });

  const handleOnAddRequest = () =>
    navigate(`${ROOT_PATH}/monitoring/create/${applicationId}`);

  const additionalActions = [
    {
      component: (
        <IconButton
          title="Add request"
          iconColor="action"
          iconName="AddIcon"
          label="Add New Request"
          onClick={handleOnAddRequest}
        />
      ),
    },
  ];

  const handleRowClick = useCallback(
    (tableData: IColumn<TRequest>) => {
      const { column, row } = tableData;

      if (column.id !== 'action' && permissions?.record?.change) {
        navigate(`${PATHS.MONITORING}/${row.original.id}`);
      }
    },
    [navigate, permissions?.record?.change],
  );

  return (
    <StyledRequestTableWrapper className="RequestTableWrapper">
      <StyledRequestTable
        isFlexLayout
        columns={COLUMNS}
        data={data || []}
        className="RequestTable"
        tableId={REQUESTS_TABLE_ID}
        handleCellClick={handleRowClick}
        isLoading={isLoading || isFetching}
        additionalActions={additionalActions}
      />
    </StyledRequestTableWrapper>
  );
};
