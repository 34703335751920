import {
  TSection,
  TSectionChildren,
  TTag,
  TTagsResponse,
} from '~/api/applications/applications.types';

import {
  DynamicSectionKey,
  HideEmptyTagsSections,
  StaticSectionKey,
  TableSectionKey,
} from './ApplicationEntry.constants';

export const getSections = (children: TSection[] = []) => {
  return (
    children?.filter((i) =>
      [StaticSectionKey, DynamicSectionKey, TableSectionKey].includes(
        i.resourcetype,
      ),
    ) || []
  );
};

export const getTagIsShown = (tag: TTag, hideEmptyTags = false) => {
  const tagvalue = tag?.tagvalue_set?.[0];
  return (
    !hideEmptyTags ||
    Boolean(
      tagvalue &&
        (tagvalue.t_value ||
          tagvalue.bool_value ||
          tagvalue.date_value ||
          tagvalue.manager),
    )
  );
};

export const getAllDynamicSectionNames = (sections: TSection[]) => {
  return sections.reduce<string[]>((acc, curr) => {
    if (curr?.children) {
      curr?.children?.forEach((subSection) => {
        if (subSection?.resourcetype === DynamicSectionKey) {
          return [...acc, subSection?.name ?? ''];
        }
        return acc;
      });
    } else {
      if (curr?.resourcetype === DynamicSectionKey) {
        return [...acc, curr?.name ?? ''];
      }
      return acc;
    }
    return acc;
  }, []);
};

export const getTagsForSection = (
  data?: TTagsResponse[],
  section?: TSectionChildren,
) => {
  if (section?.name && data) {
    const tagsSection = data?.find((i) => {
      return i.name === section?.name;
    });

    const isHideEmptyTags = HideEmptyTagsSections.includes(section?.name);

    return tagsSection?.tags.filter((tag) => {
      return getTagIsShown(tag, isHideEmptyTags);
    });
  }
  return null;
};

export const getTagValue = (tag?: TTag) => {
  if (!tag) {
    return null;
  }

  const tValueSet = tag?.tagvalue_set?.[0];
  if (tValueSet) {
    if (tValueSet?.t_value) {
      if (typeof tValueSet?.t_value === 'string') {
        return tValueSet.t_value;
      }
      if (!Array.isArray(tValueSet.t_value)) {
        return tValueSet.t_value?.text;
      }
    }
    if (tValueSet?.date_value) {
      return tValueSet.date_value;
    }

    if (tValueSet?.bool_value !== null) {
      return tValueSet.bool_value;
    }
  }
  return null;
};

export const getTagIsEditable = (tag: TTag) => {
  if (tag.readonly) {
    return false;
  }
  if (tag.tagvalue_set[0]?.readonly) {
    return false;
  }
  return tag.tagvalue_set?.[0]?.is_can_edit !== false;
};
