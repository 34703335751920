import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tabs, Tab, TabPanel } from 'react-ui-kit-exante';

import { useGetClientsAreaPermissionsQuery } from '~/api';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';

import {
  PAGE_SIZE,
  PERMISSIONS_COLUMNS,
  TABLE_PERMISSIONS_ID,
  TABLE_TAGS_ID,
} from './PermissionsTables.constants';
import {
  StyledPermissionsTable,
  StyledPermissionsTablePanel,
} from './PermissionsTables.styled';

export const PermissionsTables = () => {
  const {
    setStartHandleTime: setStartHandleTimeForPerm,
    logHandleTime: logHandleTimeForPerm,
  } = useLogHandleTime(`permissions-page-${TABLE_PERMISSIONS_ID}`);
  const {
    setStartHandleTime: setStartHandleTimeForTags,
    logHandleTime: logHandleTimeForTags,
  } = useLogHandleTime(`permissions-page-${TABLE_TAGS_ID}`);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const { data, isLoading, isSuccess } = useGetClientsAreaPermissionsQuery();

  useEffect(() => {
    if (isLoading) {
      setStartHandleTimeForTags();
      setStartHandleTimeForPerm();
    }
  }, [isLoading, setStartHandleTimeForPerm, setStartHandleTimeForTags]);

  useCallbackTriggerHandle({
    cb: logHandleTimeForPerm,
    dataTrigger: data?.permissions,
    processTrigger: !isLoading && isSuccess,
  });

  useCallbackTriggerHandle({
    cb: logHandleTimeForTags,
    dataTrigger: data?.tags,
    processTrigger: !isLoading && isSuccess,
  });

  return (
    <StyledPermissionsTablePanel
      title="About Permissions"
      action={
        <IconButton
          title="Close"
          iconSize={24}
          iconColor="promo"
          iconName="CloseIcon"
          className="ClosePermissions"
          onClick={() => navigate(-1)}
          data-test-id="about-permissions__button--close"
        />
      }
    >
      <Tabs
        value={activeTab}
        onChange={(e, value) => {
          setActiveTab(value);
        }}
      >
        <Tab label="General permissions" />
        <Tab label="Special tags permissions" />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <StyledPermissionsTable
          isFlexLayout
          isPinnedHeader
          disableSortBy
          hasPagination
          saveColumnOrder
          defaultSortBy={[]}
          isHiddenColumnSelect
          pageSize={PAGE_SIZE}
          isLoading={isLoading}
          data={data?.permissions || []}
          columns={PERMISSIONS_COLUMNS}
          className="GeneralPermissionsTable"
          tableId={TABLE_PERMISSIONS_ID}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <StyledPermissionsTable
          isFlexLayout
          isPinnedHeader
          disableSortBy
          hasPagination
          saveColumnOrder
          defaultSortBy={[]}
          isHiddenColumnSelect
          pageSize={PAGE_SIZE}
          isLoading={isLoading}
          data={data?.tags || []}
          columns={PERMISSIONS_COLUMNS}
          className="SpecialTagsPermissionsTable"
          tableId={TABLE_TAGS_ID}
        />
      </TabPanel>
    </StyledPermissionsTablePanel>
  );
};
