import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import { baseQueryHandler } from '~/utils/apiRequest';

import {
  TComment,
  TCommentsReqParams,
  TCommentsResponse,
  TCreateCommentParams,
} from './comments.types';

export const commentsApi = createApi({
  reducerPath: 'commentsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['Comments'],
  endpoints: (builder) => ({
    getComments: builder.query<TCommentsResponse, TCommentsReqParams>({
      query: ({ entityId, contentType, isSystem = false, page, perPage }) => ({
        url: '/rest/comments/',
        params: {
          object_id: entityId,
          content_type: contentType,
          page,
          per_page: perPage,
          ...(isSystem ? {} : { is_system: 'False' }),
        },
      }),
      providesTags: ['Comments'],
    }),

    createComment: builder.mutation<TComment, TCreateCommentParams>({
      query: (params) => ({
        url: '/rest/comments/',
        method: 'POST',
        data: {
          ...params,
        },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Comment successfully created',
          });
        } catch (e) {
          Notification.error({
            title: 'Comment creation error ',
          });
        }
      },
    }),
  }),
});

export const { useLazyGetCommentsQuery, useCreateCommentMutation } =
  commentsApi;
