import { styled } from '../../../../theme';
import { Skeleton } from '../../../Skeleton';

export const UploadSkeletonContainerStyled = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',

  borderWidth: '1px',
  borderRadius: '4px',
  borderColor: theme?.color?.dropdown?.list?.bg?.focus,
  borderStyle: 'solid',
  padding: '8px',
}));

export const UploadSkeletonContainerLeftStyled = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '8px',
  width: '100%',
}));

export const UploadSkeletonContainerRightStyled = styled('div')(() => ({
  display: 'flex',
  gap: '8px',
}));

export const UploadSkeletonTitleStyled = styled(Skeleton)(({ theme }) => ({
  width: '32px',
  height: '32px',
  marginRight: '16px',

  [theme.breakpoints.up('sm')]: {
    width: '104px',
  },
}));

export const UploadSkeletonBulletStyled = styled(Skeleton)(({ theme }) => ({
  width: '16px',
  height: '16px',
  marginRight: '16px',

  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}));

export const UploadSkeletonDndIconStyled = styled(Skeleton)(({ theme }) => ({
  width: '16px',
  height: '16px',

  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}));

export const UploadSkeletonDndTextStyled = styled(Skeleton)(() => ({
  width: '72px',
  height: '16px',
}));

export const UploadSkeletonButtonStyled = styled(Skeleton)(() => ({
  width: '16px',
  height: '16px',
  marginLeft: 'auto',
}));
