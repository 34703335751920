import { omit } from 'lodash';
import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import {
  IOnFetchArguments,
  Table,
  calculateCountOfPages,
  useTableData,
} from 'react-ui-kit-exante';

import { useLazyGetNotificationsNodeBackQuery } from '~/api';
import { TNotification } from '~/api/nodeBackApi/notifications/notifications.types';
import { RefreshButton } from '~/components/RefreshButton';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';
import { getDefaultPagination } from '~/utils/table';

import { ApplicationContext } from '../../../../contexts/ApplicationContext';

import { prepareNotificationsParams } from './Notifications.helpers';
import {
  DISPLAYED_COLUMNS_KEYS,
  EMPTY_NOTIFICATIONS,
  TABLE_ID,
  UNNECESSARY_PARAMS_FOR_NOTIFICATION_TABLE,
  channelOptions,
  getAdditionalFilters,
  getColumns,
} from './NotificationsTable.constants';

export const NotificationsTable: FC = () => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `application-entry-${TABLE_ID}`,
  );
  const { application } = useContext(ApplicationContext);

  const [
    fetchNotifications,
    {
      isLoading: isGetNotificationsNodeBackLoading,
      isSuccess: isGetNotificationsNodeBackSuccess,
    },
  ] = useLazyGetNotificationsNodeBackQuery();

  useEffect(() => {
    if (isGetNotificationsNodeBackLoading) {
      setStartHandleTime();
    }
  }, [isGetNotificationsNodeBackLoading, setStartHandleTime]);

  const getNotifications = useCallback(
    async (params: IOnFetchArguments) => {
      if (!application) {
        return EMPTY_NOTIFICATIONS;
      }
      const userId: number = application.user.authdb_id;

      const preparedParams = prepareNotificationsParams(params);

      const response = await fetchNotifications({
        ...omit(preparedParams, UNNECESSARY_PARAMS_FOR_NOTIFICATION_TABLE),
        userId,
      });

      return response.data;
    },
    [application, fetchNotifications],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId: TABLE_ID,
      data: {
        onFetch: getNotifications,
      },
      pagination: {
        getDefaultPagination,
      },
    }),
    [getNotifications],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    skip,
    isLoading,
    resetFilters,
    filters: filtersTable,
    setFilter,
    removeFilter,
    fetchData: refetch,
  } = useTableData(tableDataArgs);

  const total = Number(data?.pagination?.total) || 0;

  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [removeFilter, setFilter],
  );

  const filteringProps = useMemo(
    () => ({
      additionalFilters,
      removeAllFilters: resetFilters,
      filters: filtersTable,
      manualFilters: true,
    }),
    [resetFilters, filtersTable],
  );

  const columns = getColumns({
    onFilter: setFilter,
    onRemove: removeFilter,
    channelOptions,
  });

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: data?.data,
    processTrigger:
      !isGetNotificationsNodeBackLoading && isGetNotificationsNodeBackSuccess,
  });

  const displayedColumnKeys = useMemo(() => DISPLAYED_COLUMNS_KEYS, []);

  const additionalActions = [
    {
      key: 'refresh',
      component: (
        <RefreshButton
          onRefresh={refetch}
          disabled={isLoading}
          iconColor="secondary"
          title="Refresh table data"
        />
      ),
    },
  ];

  return (
    <Table<TNotification>
      isPinnedHeader
      className="NotificationsTable"
      columns={columns}
      displayedColumnKeys={displayedColumnKeys}
      isLoading={isLoading}
      filtersExpanded
      isFlexLayout
      hasFilters
      filteringProps={filteringProps}
      data={data?.data || []}
      tableId={TABLE_ID}
      hasPagination
      showTableInfo
      pageSize={20}
      serverPaginationProps={serverPaginationProps}
      saveViewParamsAfterLeave
      defaultSortBy={[]}
      additionalActions={additionalActions}
    />
  );
};
