import { has, get } from 'lodash';
import {
  FieldNamesMarkedBoolean,
  FieldValues,
  UseFormGetValues,
  UseFormReset,
} from 'react-hook-form';
import { Notification } from 'react-ui-kit-exante';

import { TServiceResponse, TServiceResponseRTK } from '~/types/api';
import { TFormHandler } from '~/types/form/handler';

import { prepareServiceMessageToArray } from '../../services';

import { isAnyFieldDirty, TDirtyTree } from './helpers';

type TCreateFormSubmitHandlerArguments<T extends FieldValues> = {
  accountPermissionsQueryRefetch?: () => void;
  callback?: () => void;
  dirtyFields: FieldNamesMarkedBoolean<T>;
  getValues: UseFormGetValues<T>;
  handlersMap: TFormHandler<T> | null;
  reset: UseFormReset<T>;
};

type TInternalMessages = {
  success: string[];
  error: string[];
};

export function createFormSubmitHandler<T extends FieldValues>({
  callback,
  dirtyFields,
  getValues,
  handlersMap,
  reset,
}: TCreateFormSubmitHandlerArguments<T>) {
  return async (newData: T) => {
    const previousValues = getValues();
    const refetchList = new Set<string>();

    if (typeof dirtyFields === 'boolean') {
      return;
    }

    const dirtyKeys = Object.keys(dirtyFields);

    const promisesQueue: Promise<{
      response: TServiceResponse<T[keyof T]> | TServiceResponseRTK<T[keyof T]>;
      moduleName: keyof T;
    }>[] = [];

    const messages: TInternalMessages = {
      success: [],
      error: [],
    };

    dirtyKeys.forEach((key) => {
      const currentModuleName = newData[key];
      const currentModuleDirtyIndicator = dirtyFields[key] as TDirtyTree<T>;

      if (
        isAnyFieldDirty(currentModuleDirtyIndicator) &&
        typeof handlersMap?.[key] === 'function'
      ) {
        refetchList.add(String(key));
        promisesQueue.push(
          handlersMap[key](currentModuleName, newData).then((result) => ({
            response: result,
            moduleName: key,
          })),
        );
      }
    });

    const responses = await Promise.allSettled(promisesQueue);

    const updatedData = responses.reduce((acc, curr) => {
      if (curr.status === 'fulfilled') {
        const { response, moduleName } = curr.value;
        let data;
        let responseMessages;

        if (has(response, 'data.data')) {
          data = get(response, 'data.data', {});
          responseMessages = get(response, 'data.messages', {
            success: '',
            error: '',
          });
        } else if (has(response, 'error.data')) {
          data = get(response, 'error.data', {});
          responseMessages = get(response, 'error.messages', {
            success: '',
            error: '',
          });
        } else {
          data = response.data;
          responseMessages = get(response, 'messages', {
            success: '',
            error: '',
          });
        }
        if (responseMessages) {
          messages.success = messages.success.concat(
            prepareServiceMessageToArray(
              get(responseMessages, 'success', '') as
                | string
                | string[]
                | undefined,
            ),
          );
          messages.error = messages.error.concat(
            prepareServiceMessageToArray(
              get(responseMessages, 'error', '') as
                | string
                | string[]
                | undefined,
            ),
          );
        }
        if (data) {
          return { ...acc, [moduleName]: data };
        }
        return acc;
      }
      return acc;
    }, {} as T);

    if (messages.success.length > 0) {
      Notification.success({
        title: messages.success.join('\n'),
      });
    }
    if (messages.error.length > 0) {
      Notification.error({
        title: messages.error.join('\n'),
      });
    }

    const newState = { ...previousValues, ...updatedData };

    reset(newState);

    if (callback) {
      callback();
    }

    return {
      newState,
      refetchList,
    };
  };
}
