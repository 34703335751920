import { NODE_BACK_URL } from '~/api/nodeBackApi/endpoints';

import { apiRequest } from '../apiRequest';

import { FETCH_LOG_HANDLE_TIME } from './endpoints';
import { LogHandleTimeRequest } from './influxProxy.types';

export const fetchLogHandleTime = (data: LogHandleTimeRequest) => {
  return apiRequest({
    url: `${NODE_BACK_URL}${FETCH_LOG_HANDLE_TIME}`,
    method: 'POST',
    data,
  });
};
