import { useContext } from 'react';

import { useGetGlobalContextQuery, useGetPermissionsQuery } from '~/api';
import { ValueRow } from '~/components/ValueRow';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { ApplicationFormContext } from '~/pages/ApplicationEntry/contexts/FormContext';
import { ClientFormHeader } from '~/pages/ApplicationEntry/tabs/OnboardingTab/OnboardingSettingsTab/components/ClientForm/ClientFormHeader';
import { getFormField } from '~/utils/getFormField';

import {
  getClientFormFields,
  preparedClientsDirtyFields,
} from './ClientForm.helpers';
import {
  StyledClientFormField,
  StyledClientFormList,
  StyledClientFormWrapper,
} from './ClientForm.styled';

export const ClientForm = () => {
  const { application } = useContext(ApplicationContext);
  const { dirtyFields } = useContext(ApplicationFormContext);

  const { data: globalContext } = useGetGlobalContextQuery();
  const { data: permissions } = useGetPermissionsQuery();

  const applicationData = application?.application;
  const clients = applicationData?.clients;
  const clientPermissions = {
    ...permissions?.client,
    changeLE: !applicationData?.is_approved,
  };
  const allOptions = globalContext?.tags_choices;

  if (clients) {
    return (
      <div>
        {clients?.map((client) => {
          const {
            id,
            client_id: clientId,
            closed_only: closedOnly,
            closed_account: closedAccount,
            blocked_account: blockedAccount,
          } = client;

          return (
            <StyledClientFormWrapper key={id} className="ClientFormWrapper">
              <ClientFormHeader
                id={id}
                clientId={clientId}
                closedOnly={closedOnly}
                closedAccount={closedAccount}
                blockedAccount={blockedAccount}
              />
              <StyledClientFormList className="ClientFormList">
                {getClientFormFields(clientPermissions, allOptions).map(
                  ({ name, title, type, options, editable }) => {
                    const warning =
                      dirtyFields &&
                      preparedClientsDirtyFields(dirtyFields)[name];

                    return (
                      <ValueRow
                        key={name}
                        label={title}
                        valueNode={
                          <StyledClientFormField className="ClientFormField">
                            {getFormField({
                              type,
                              options,
                              warning,
                              disabled: !editable,
                              name: `clientId=${id}:${name}`,
                            })}
                          </StyledClientFormField>
                        }
                      />
                    );
                  },
                )}
              </StyledClientFormList>
            </StyledClientFormWrapper>
          );
        })}
      </div>
    );
  }

  return null;
};
