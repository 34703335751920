import { createContext, FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from 'react-ui-kit-exante';

import { TChildren } from 'types/TChildren';
import {
  useLazyGetApplicationQuery,
  useLazyGetApplicationStructureQuery,
} from '~/api';
import { EntryScreenWrapper } from '~/components/EntryScreen';
import { ALL_LEGAL_ENTITY } from '~/constants/common';
import { TParams } from '~/router/router.types';
import { getLE } from '~/utils/getLE';

import { TApplicationContext } from './ApplicationContext.types';

const initialState: TApplicationContext = {
  authdbId: 666,
  userName: '',
  userFullName: '',
  application: null,
  activeLegalEntity: ALL_LEGAL_ENTITY,
  structure: null,
  setActiveLegalEntity: () => {},
  isStructureLoading: false,
  isLoadingApplication: false,
};

export const ApplicationContext =
  createContext<TApplicationContext>(initialState);

export const ApplicationProvider: FC<TChildren> = ({ children }) => {
  const { ids } = useParams<TParams>();

  const [fetchApplication, applicationState] = useLazyGetApplicationQuery();
  const [fetchApplicationStructure, applicationStructureState] =
    useLazyGetApplicationStructureQuery();

  const [activeLegalEntity, setActiveLegalEntity] = useState<
    string | undefined
  >();

  const applicationData = applicationState.data;
  const isLoadingApplication =
    applicationState?.isLoading || applicationState.isFetching;

  const structure =
    applicationStructureState?.data?.sections?.find(
      (section) => section.name === 'applications',
    ) || null;

  const getApplicationData = async () => {
    const [id, crmId] = ids?.split('_') as string[];

    const { data } = await fetchApplication({
      id,
      crmId,
      legalEntity: activeLegalEntity || ALL_LEGAL_ENTITY,
    });

    const applicationId = data?.crm?.application.id;

    fetchApplicationStructure({
      id: applicationId,
      legalEntity: activeLegalEntity || getLE(data?.crm?.available_le),
    });
  };

  const authdbInfo = applicationData?.authdb.info;
  const authdbId =
    applicationData?.authdb?.id || applicationData?.crm?.user.authdb_id;
  const userName =
    applicationData?.crm?.application.username ||
    applicationData?.authdb.username ||
    '';

  const userFullName =
    useMemo(() => {
      if (applicationData?.crm?.full_name) {
        return applicationData?.crm?.full_name;
      }
      if (authdbInfo?.firstName && authdbInfo?.lastName) {
        return `${authdbInfo?.firstName} ${authdbInfo?.lastName}`;
      }

      return applicationData?.authdb?.username;
    }, [applicationData]) || '';

  useEffect(() => {
    getApplicationData();
  }, [activeLegalEntity]);

  const value = useMemo<TApplicationContext>(
    () => ({
      authdbId,
      userName,
      structure,
      userFullName,
      activeLegalEntity,
      isStructureLoading: applicationStructureState.isLoading,
      setActiveLegalEntity,
      application: applicationData?.crm || null,
      isLoadingApplication,
    }),
    [
      authdbId,
      userName,
      structure,
      userFullName,
      activeLegalEntity,
      applicationStructureState,
      applicationData,
      isLoadingApplication,
    ],
  );

  if (applicationState?.isLoading || !applicationData) {
    return (
      <EntryScreenWrapper>
        <Loader isCentered size={32} />
      </EntryScreenWrapper>
    );
  }

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
};
