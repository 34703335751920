import { TTranslator } from 'contexts/TranslationContext.types';

import { MEGABYTE, NO_VALID_ERROR_MESSAGE } from './constants';
import type { TFile } from './types';
import { TGetColorBg, UploadStatus } from './types';

export const getHasAcceptType = (files: FileList, accept?: string) => {
  if (!accept) {
    return true;
  }

  let hasAcceptType = true;

  Array.from(files).forEach((file) => {
    const fileTypes = file.type.split('/');
    let acceptType = false;

    fileTypes.forEach((fileType) => {
      if (accept?.includes(fileType)) {
        acceptType = true;
      }
      if (
        (fileType?.includes('msword') ||
          fileType?.includes(
            'vnd.openxmlformats-officedocument.wordprocessingml.document',
          )) &&
        accept.includes('doc')
      ) {
        acceptType = true;
      }
      if (fileType?.includes('tiff') && accept.includes('tif')) {
        acceptType = true;
      }
    });

    if (!acceptType && hasAcceptType) {
      hasAcceptType = false;
    }
  });

  return hasAcceptType;
};

export const getMaxFileSizeExceed = (maxFileSize: number, files: FileList) => {
  let maxFileSizeExceed = false;

  Array.from(files).forEach((file) => {
    if (!maxFileSizeExceed && file.size > maxFileSize) {
      maxFileSizeExceed = true;
    }
  });

  return maxFileSizeExceed;
};

export const getErrorMaxFileSizeExceedText = (
  maxFileSize: number,
  translator: TTranslator,
  errorMaxFileSizeExceedMessage?: string,
) => {
  if (!errorMaxFileSizeExceedMessage) {
    return translator('ui__fileupload__error_size_exceed', {
      MAX_FILE_SIZE_MB: maxFileSize / MEGABYTE,
    });
  }

  return errorMaxFileSizeExceedMessage;
};

export const getErrorValidFileTypeText = (
  translator: TTranslator,
  accept?: string,
  errorValidFileTypeText?: string,
) => {
  if (!errorValidFileTypeText && accept) {
    return translator('ui__fileupload__error_file_type', {
      VALID_FILE_TYPES: accept || '',
    });
  }

  if (errorValidFileTypeText) {
    return errorValidFileTypeText;
  }

  return NO_VALID_ERROR_MESSAGE;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) {
    return '0 Bytes';
  }

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const normalizeFiles = (
  files: File[],
  status: UploadStatus,
): TFile[] => {
  return files.map((item: File) => ({
    lastModified: item.lastModified,
    name: item.name,
    size: item.size,
    uploadStatus: status,
    type: item.type,
    removable: false,
    error: undefined,
    id: undefined,
  }));
};

export const getColorBg = ({
  viewMode,
  status,
  secondaryColor,
  fileStatusColor,
}: TGetColorBg) => {
  if (viewMode) {
    return secondaryColor;
  }
  return status ? fileStatusColor : '';
};
