import { fetchLogHandleTime } from './influxProxy.repository';
import type { LogHandleTimeRequest } from './influxProxy.types';

export const postLogHandleTime = async (data: LogHandleTimeRequest) => {
  try {
    if (!process.env.REACT_APP_IS_DEV) {
      await fetchLogHandleTime(data);
    }
  } catch (error) {
    console.error(`network error: ${error}`);
  }
};
