import ClickAwayListener from '@mui/material/ClickAwayListener';
import cn from 'classnames';
import { FC, Fragment, useEffect, useState } from 'react';

import { Button } from '../../../Button/Button';
import { IconButton } from '../../../Button/IconButton';

import { TEST_ID_ACTION_WITH_CONFIRMATION_CONFIRM_BUTTON } from './DeleteConfirmationTooltip.constants';
import {
  Buttons,
  Container,
  Title,
  StyledTooltip,
} from './DeleteConfirmationTooltip.styled';
import { TDeleteConfirmationTooltipProps } from './DeleteConfirmationTooltip.types';

export const DeleteConfirmationTooltip: FC<TDeleteConfirmationTooltipProps> = ({
  children,
  cancelButtonNameKey = 'Cancel',
  confirmButtonNameKey = 'OK',
  className,
  closeHandler,
  disabled,
  dismissHandler,
  externalOpened = false,
  hideDefaultButtons = false,
  noDismiss,
  onConfirm,
  openFromExternal = false,
  placement = 'top',
  size = 'default',
  title,
  withCloseAfterConfirmation,
  withTopCloseButton = false,
  TooltipContentWrapper,
}) => {
  const [open, setOpen] = useState(externalOpened);

  const handleTooltipClose = () => {
    setOpen(false);
    closeHandler?.();
  };

  const handleTooltipDismiss = () => {
    if (!open) {
      return;
    }

    if (!noDismiss) {
      if (dismissHandler) {
        dismissHandler();
      } else {
        handleTooltipClose();
      }
    }
  };

  const handleTooltipOpen = () => {
    if (!disabled) {
      setOpen(true);
    }
  };

  const handleConfirm = () => {
    onConfirm?.();

    if (withCloseAfterConfirmation) {
      handleTooltipClose();
    }
  };

  useEffect(() => {
    setOpen(externalOpened);
  }, [externalOpened]);

  const Wrapper = TooltipContentWrapper || Fragment;

  const tooltipContent = (
    <Wrapper>
      {title && (
        <Title className="ActionWithConfirmationTitle">
          {title}
          {withTopCloseButton && (
            <IconButton
              iconName="CloseIcon"
              onClick={handleTooltipClose}
              aria-label="close"
            />
          )}
        </Title>
      )}
      {!hideDefaultButtons && (
        <Buttons className="ActionWithConfirmationButtons">
          {onConfirm ? (
            <>
              <Button
                onClick={handleConfirm}
                textColor="action"
                disabled={disabled}
                color="transparent"
                aria-label="confirm"
                data-test-id={TEST_ID_ACTION_WITH_CONFIRMATION_CONFIRM_BUTTON}
              >
                {confirmButtonNameKey}
              </Button>
              <Button
                onClick={handleTooltipClose}
                textColor="secondary"
                color="transparent"
                aria-label="close"
              >
                {cancelButtonNameKey}
              </Button>
            </>
          ) : (
            <Button
              onClick={handleTooltipClose}
              textColor="action"
              color="transparent"
              aria-label="close"
            >
              OK
            </Button>
          )}
        </Buttons>
      )}
    </Wrapper>
  );

  return (
    <ClickAwayListener onClickAway={handleTooltipDismiss}>
      <Container className={cn('ActionWithConfirmationContainer', className)}>
        <StyledTooltip
          arrow
          placement={placement}
          title={tooltipContent}
          open={open}
          onClose={handleTooltipClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          className="Tooltip"
          size={size}
        >
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            onClick={
              !openFromExternal ? handleTooltipOpen : (e) => e.stopPropagation()
            }
          >
            {children}
          </div>
        </StyledTooltip>
      </Container>
    </ClickAwayListener>
  );
};
