import { useCallback, useRef } from 'react';

import { postLogHandleTime } from '~/utils/influxProxy';

export function useLogHandleTime(entity: string) {
  const start = useRef<string | null>(null);

  const setStartHandleTime = useCallback(() => {
    if (start.current === null) {
      start.current = new Date().toISOString();
    }
  }, []);

  const logHandleTime = useCallback(() => {
    if (start.current) {
      postLogHandleTime({
        entity,
        start: start.current,
        end: new Date().toISOString(),
      });

      start.current = null;
    }
  }, [entity]);

  return {
    setStartHandleTime,
    logHandleTime,
  };
}
