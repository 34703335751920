import { TAccountPermissionByUsername } from '~/api';

export function isDropDownDisabled(
  initialPermissions: string[],
  watchedPermissions: TAccountPermissionByUsername[],
  index: number,
) {
  const watchedElement = watchedPermissions[index];

  if (watchedElement?.accountId === '') {
    return false;
  }

  return initialPermissions.includes(watchedPermissions[index]?.accountId);
}
