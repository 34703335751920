export const getColumns = (isAll: boolean, canView?: boolean) =>
  [
    {
      Header: 'Date',
      accessor: 'datetime',
      disableSortBy: true,
    },
    isAll
      ? {
          Header: 'Account ID',
          accessor: 'account_id',
          disableSortBy: true,
        }
      : null,
    {
      Header: 'Type',
      accessor: 'type',
      disableSortBy: true,
    },
    canView
      ? {
          Header: 'Amount',
          accessor: 'sum',
          disableSortBy: true,
        }
      : null,
    canView
      ? {
          Header: 'Asset',
          accessor: 'asset',
          disableSortBy: true,
        }
      : null,
    canView
      ? {
          Header: 'Amount, EUR',
          accessor: 'converted_sum',
          disableSortBy: true,
        }
      : null,
    {
      Header: 'Manager',
      accessor: 'manager_username',
      disableSortBy: true,
    },
  ].filter(Boolean);
