import { Autocomplete } from 'react-ui-kit-exante';

import {
  StyledSectionTitle,
  StyledSelectBlock,
  StyledTransactionsHeader,
} from './TransactionsHeader.styled';
import { TransactionsHeaderProps } from './TransactionsHeader.types';

export const TransactionsHeader = ({
  options,
  value,
  onChange,
}: TransactionsHeaderProps) => {
  return (
    <StyledTransactionsHeader className="TransactionsHeader">
      <StyledSectionTitle className="SectionTitle">
        Transactions
      </StyledSectionTitle>
      <StyledSelectBlock className="SelectBlock">
        <Autocomplete
          sx={{ width: 240 }}
          size="small"
          options={options}
          onChange={onChange}
          value={value}
        />
      </StyledSelectBlock>
    </StyledTransactionsHeader>
  );
};
