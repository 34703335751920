import { styled } from 'react-ui-kit-exante';

export const StyledTabLoader = styled('div')`
  position: relative;

  min-height: 100px;

  .loader {
    & > div {
      position: relative;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;
