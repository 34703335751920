import { getTableId } from '~/utils/table';

export const COLUMNS = [
  {
    Header: 'Risk Type',
    accessor: 'title',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Value',
    accessor: 'clean_score',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Impact',
    accessor: 'impact_percent',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Risk',
    accessor: 'score',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Forced Level',
    accessor: 'level',
    disableSortBy: true,
    disableFilters: true,
  },
];

export const TABLE_ID = getTableId('risk-scoring');
