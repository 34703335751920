import { useContext, useState } from 'react';

import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';

import { RiskScoringTable } from '../RiskScoringTable';

import { StyledRiskScoringWrapperBtn } from './RiskScoringWrapper.styled';

export const RiskScoringWrapper = () => {
  const { application, activeLegalEntity } = useContext(ApplicationContext);
  const [refresh, setRefresh] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const legalEntities = activeLegalEntity
    ? [activeLegalEntity]
    : application?.application.legal_entities;

  const handleOnRefresh = () => setRefresh(!refresh);

  return (
    <>
      {legalEntities?.map((legalEntity) => {
        return (
          <RiskScoringTable
            key={legalEntity}
            refresh={refresh}
            legalEntity={legalEntity}
            setIsDisabled={setIsDisabled}
          />
        );
      })}
      <StyledRiskScoringWrapperBtn
        disabled={isDisabled}
        onClick={handleOnRefresh}
      >
        Update Risk Scoring
      </StyledRiskScoringWrapperBtn>
    </>
  );
};
