import { TClient } from '~/api/applications/applications.types';
import { TTagsChoices } from '~/api/globalContext/globalContext.types';
import { FieldTypes } from '~/constants/common';
import { transformVariantsToSelectOptions } from '~/utils/transformVariantsToSelectOptions';

export const getClientFormFields = (
  clientPermissions?: Record<string, boolean>,
  options?: TTagsChoices,
) => {
  return [
    {
      name: 'withdrawal_restriction',
      title: 'Withdrawal restriction',
      type: FieldTypes.Checkbox,
      editable: clientPermissions?.change_withdrawal_restriction,
    },
    {
      name: 'documents_pending',
      title: 'Documents pending',
      type: FieldTypes.Checkbox,
      editable: clientPermissions?.change_documents_pending,
    },
    {
      name: 'legal_entity',
      title: 'Legal entity',
      type: FieldTypes.Select,
      editable: clientPermissions?.changeLE,
      options: transformVariantsToSelectOptions(options?.legalEntities),
    },
    {
      name: 'client_type',
      title: 'Client type',
      editable: clientPermissions?.change_client_type,
      type: FieldTypes.Select,
      options: transformVariantsToSelectOptions(options?.clientTypes),
    },
    {
      name: 'approved_at',
      title: 'Approved date',
      editable: false,
      type: FieldTypes.DateField,
    },
    {
      name: 'last_client_review_date',
      title: 'Last client review date',
      editable: true,
      type: FieldTypes.DateField,
    },
    {
      name: 'closed_account',
      title: 'Closed',
      editable: false,
      type: FieldTypes.Checkbox,
    },
    {
      name: 'available_number_of_accounts',
      title: 'Available number of accounts',
      editable: clientPermissions?.change_available_number_of_accounts,
      type: FieldTypes.NumberField,
    },
    {
      name: 'funds_received',
      title: 'Funds received',
      editable: false,
      type: FieldTypes.Checkbox,
    },
    {
      name: 'funds_received_date',
      title: 'Funds received date',
      editable: false,
      type: FieldTypes.DateField,
    },
    {
      name: 'had_min_deposit',
      title: 'NAV > Minimum deposit',
      editable: false,
      type: FieldTypes.Checkbox,
    },
    {
      name: 'min_deposit_date',
      title: 'Minimum deposit date',
      editable: false,
      type: FieldTypes.DateField,
    },
    {
      name: 'total_nav',
      title: 'Total NAV',
      editable: false,
      type: FieldTypes.NumberField,
    },
    {
      name: 'liquid_nav',
      title: `Liquid NAV`,
      editable: false,
      type: FieldTypes.NumberField,
    },
    {
      name: 'non_liquid_nav',
      title: 'Non-liquid NAV',
      editable: false,
      type: FieldTypes.NumberField,
    },
    {
      name: 'non_compliance_fee',
      title: 'Non-compliance fee',
      editable: clientPermissions?.change_non_compliance_fee,
      type: FieldTypes.Checkbox,
    },
    {
      name: 'non_compliance_fee_date',
      title: 'Non-compliance fee date',
      editable: false,
      type: FieldTypes.DateField,
    },
    {
      name: 'non_compliance_fee_amount',
      title: 'Non-compliance fee amount',
      editable: clientPermissions?.change_non_compliance_fee_amount,
      type: FieldTypes.NumberField,
    },
  ];
};

export const getDefaultClientsValues = (clients: TClient[]) => {
  return clients
    ?.map((client, index) => {
      return Object.entries(client).map((item) => {
        const propName = `clientId=${clients[index].id}:${item[0]}`;

        if (item[0].includes('date')) {
          const preparedDate = item[1] ? new Date(String(item[1])) : null;

          return {
            [propName]: preparedDate,
          };
        }

        return {
          [propName]: item[1],
        };
      });
    })
    .flat()
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});
};

export const preparedClientsDirtyFields = (
  dirtyFields: Record<string, boolean | undefined>,
) =>
  Object.entries(dirtyFields)
    .map(([name, value]) => ({ [name.split(':')[1]]: value }))
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});
