import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IconButton,
  IOnFetchArguments,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { useLazyGetUserManagementQuery } from '~/api';
import { TUserManagementData } from '~/api/userManagement/userManagementApi.types';
import { RefreshButton } from '~/components/RefreshButton';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';
import { UserManagementRoles } from '~/pages/UserManagementPages/UserManagementPages.constants';
import { OLD_CRM_ROOT_PATH, PATHS } from '~/router';
import { linkAndClick } from '~/utils/linkAndClick';
import { getFilterParams, getTableId } from '~/utils/table';

import { AddUserManagementForm } from '../AddUserManagementForm';

import {
  getAdditionalFilters,
  getDefaultFilters,
} from './UserManagemen.helpers';
import { COLUMNS } from './UserManagement.constants';
import { TUserManagementTemplate } from './UserManagement.types';

export const UserManagement = ({
  title,
  typeOfRole,
}: TUserManagementTemplate) => {
  const tableId = getTableId(typeOfRole);
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `user-management-page-${tableId}`,
  );
  const navigate = useNavigate();

  const [
    fetchUserManagement,
    {
      isLoading: isGetUserManagementLoading,
      isFetching: isGetUserManagementFetching,
      isSuccess: isGetUserManagementSuccess,
    },
  ] = useLazyGetUserManagementQuery();

  useEffect(() => {
    if (isGetUserManagementLoading) {
      setStartHandleTime();
    }
  }, [isGetUserManagementLoading, setStartHandleTime]);

  const [isLoadingAdd, setIsLoadingAdd] = useState(false);

  const getRoleOfUserManagement = useCallback(
    async (params: IOnFetchArguments) => {
      const filterParams = getFilterParams(params);

      const response = await fetchUserManagement({
        role: typeOfRole,
        params: filterParams,
      });
      return response.data;
    },
    [fetchUserManagement, typeOfRole],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId,
      data: { onFetch: getRoleOfUserManagement },
      filters: {
        getDefaultFilters,
      },
      saveViewParamsAfterLeave: true,
    }),
    [getRoleOfUserManagement],
  );

  const {
    data,
    filters,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    fetchData: refetchTableData,
  } = useTableData(tableDataArgs);

  const isLoadingTable =
    isLoading || isGetUserManagementFetching || isLoadingAdd;

  const additionalFilters = getAdditionalFilters({
    onFilter: setFilter,
    onRemove: removeFilter,
  });

  const filterProps = useMemo(
    () => ({
      filters,
      additionalFilters,
      manualFilters: true,
      removeAllFilters: resetFilters,
    }),
    [filters, resetFilters],
  );

  const getColumns = () => {
    if (
      typeOfRole !== UserManagementRoles.Admin &&
      typeOfRole !== UserManagementRoles.Onboarding
    ) {
      return COLUMNS.filter(
        (column) => column.accessor !== 'le_approval_permissions',
      );
    }
    return COLUMNS;
  };

  const additionalActions = [
    {
      component: (
        <>
          <RefreshButton
            onRefresh={refetchTableData}
            disabled={isLoadingTable}
            iconColor="secondary"
            title="Refresh table data"
          />
          <AddUserManagementForm
            typeOfRole={typeOfRole}
            setIsLoadingAdd={setIsLoadingAdd}
            refetchTableData={refetchTableData}
          />
          <IconButton
            label="About permissions"
            iconSize={24}
            title="About permissions"
            iconName="KeyIcon"
            iconColor="secondary"
            className="AboutPermissions"
            onClick={() => navigate(PATHS.ABOUT_PERMISSIONS)}
            data-test-id="about-permissions__button--open"
          />
        </>
      ),
    },
  ];

  // todo add params as other tables (see Deposits) after change on new entry screen page
  const handleRowClick = ({ email }: { email: string }) => {
    const userManagementURL = `${OLD_CRM_ROOT_PATH}/${typeOfRole}-${email}`;

    linkAndClick(userManagementURL);
  };

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: data?.data,
    processTrigger: !isGetUserManagementLoading && isGetUserManagementSuccess,
  });

  return (
    <Table<TUserManagementData>
      hasFilters
      isFlexLayout
      manualSortBy
      title={title}
      hasPagination
      saveColumnOrder
      tableId={tableId}
      columns={getColumns()}
      data={data?.data || []}
      saveViewParamsAfterLeave
      className="UserManagement"
      isLoading={isLoadingTable}
      filteringProps={filterProps}
      additionalActions={additionalActions}
      handleRowClick={handleRowClick}
    />
  );
};
