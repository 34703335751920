import { Panel, styled } from 'react-ui-kit-exante';

export const StyledAccountPermissionsList = styled(Panel)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-top: 1px solid ${({ theme }) => theme.color.line.primary};
`;

export const StyledAccountPermissionsListRow = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: 100%;
  }

  .AccountPermissionsListDelete {
    margin-left: auto;
  }
`;

export const StyledAccountPermissionsAdd = styled('div')`
  display: flex;
  justify-content: flex-end;
`;
