import { DEPOSIT_STATUSES } from '~/api';
import { TDepositInfo } from '~/api/deposits/deposits.types';
import { FieldTypes } from '~/constants/common';
import { TGetFieldsParams } from '~/pages/DepositEntry/components/DepositForm/components/DepositDetails/DepositDetails.types';
import { formatDateTime } from '~/utils/dates/formatDate';
import { mapChoiceOptionToText } from '~/utils/formatters/mapChoiceOptionToValue';
import { linkAndClick } from '~/utils/linkAndClick';
import { replaceZeroAfterNumber } from '~/utils/replaceZeroAfterNumber';

import { DEPOSIT_STATUS_TITLES } from '../../DepositEntry.constants';

import { DEPOSIT_TYPES } from './DepositForm.constants';
import { StyledButtonClientCard } from './DepositForm.styled';
import { TFieldConfig } from './DepositForm.types';

export const availableStatusOptions = (deposit?: TDepositInfo) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { id, status, available_transitions } = deposit || {};
  if (!!id && !!available_transitions) {
    const available = available_transitions
      .filter(({ disabled }) => !disabled)
      .map(({ key, title: label }) => ({ value: key, label }));
    // current + available states
    return status
      ? [{ label: status.text, value: status.id }].concat(available)
      : available;
  }
  // all states
  return Object.entries(DEPOSIT_STATUS_TITLES).map(([value, label]) => ({
    value,
    label,
  }));
};

export const getFields = (params?: TGetFieldsParams): TFieldConfig[] => {
  const { countryA, ccy, deposit, hiddenFields } = params || {};

  const isBooked = deposit?.status.id === DEPOSIT_STATUSES.BOOKED;
  const isFieldEditable = !isBooked;
  const transactionUrl = deposit?.transaction_url;

  return [
    {
      name: 'deposit_type',
      title: 'Deposit type',
      type: FieldTypes.Select,
      formatter: mapChoiceOptionToText,
      subKey: 'id',
      optionsConfig: {
        options: Object.entries(DEPOSIT_TYPES).map(([value, label]) => ({
          value,
          label,
        })),
      },
      editable: Boolean(deposit?.deposit_type) && isFieldEditable,
    },
    {
      name: 'datetime',
      title: 'Date time',
      type: FieldTypes.DateTimeField,
      formatter: formatDateTime,
      editable: isFieldEditable,
    },

    {
      name: 'name',
      title: "Sender's name",
      editable: isFieldEditable,
    },
    {
      name: 'ac_no',
      title: 'Senders Account number',
      editable: isFieldEditable,
    },
    {
      name: 'iban',
      title: 'Senders IBAN',
      editable: isFieldEditable,
    },
    {
      name: 'swift',
      title: 'SWIFT',
    },

    {
      name: 'country_a',
      title: 'Country (A)',
      subKey: 'id',
      formatter: mapChoiceOptionToText,
      type: FieldTypes.Autocomplete,
      optionsConfig: {
        options: countryA?.options || [],
        isLoading: !!countryA?.isLoading,
      },
      editable: isFieldEditable,
    },
    {
      name: 'ccy',
      title: 'Currency',
      subKey: 'id',
      formatter: mapChoiceOptionToText,
      optionsConfig: {
        options: ccy?.options || [],
        isLoading: !!ccy?.isLoading,
      },
      type: FieldTypes.Autocomplete,
      editable: isFieldEditable,
    },
    {
      name: 'amount',
      title: 'Amount',
      editable: isFieldEditable,
      required: isFieldEditable,
      valueType: 'number',
      formatter: replaceZeroAfterNumber,
    },
    {
      name: 'detail',
      title: 'Details (client notes)',
      editable: isFieldEditable,
      type: FieldTypes.Textarea,
    },
    {
      name: 'is_draft',
      title: 'Draft',
      type: FieldTypes.Checkbox,
      editable: isFieldEditable,
    },
    {
      name: 'custody',
      title: 'Custody',
      editable: isFieldEditable,
    },
    {
      name: 'custody_account',
      title: 'Custody account',
      editable: isFieldEditable,
    },
    {
      name: 'bank_name',
      title: 'Client custody',
    },
    {
      name: 'client_custody_account',
      title: 'Client custody account',
      editable: isFieldEditable,
    },
    {
      name: 'bank_deposit_id',
      title: 'Bank Deposit ID',
      editable: deposit?.source !== 'bank-service',
    },
    {
      name: 'internal_id',
      title: 'Internal Id',
      editable: false,
    },
    {
      name: 'crypto_network',
      title: 'Crypto network',
      editable: false,
    },
    {
      name: 'transaction_hash',
      title: 'Transaction hash',
      withCopyButton: true,
      editable: false,
      node: (
        <StyledButtonClientCard
          className="ButtonClientCard"
          color="transparent"
          textColor="action"
          onClick={() => transactionUrl && linkAndClick(transactionUrl, true)}
        >
          {deposit?.transaction_hash}
        </StyledButtonClientCard>
      ),
    },
    {
      name: 'crypto_address_from',
      title: 'Crypto address from',
      withCopyButton: true,
      editable: false,
    },
    {
      name: 'crypto_address_to',
      title: 'Crypto address to',
      withCopyButton: true,
      editable: false,
    },
    // todo this fields will be used in application card
    // {
    //   name: 'confirms_required',
    //   title: 'Confirms required',
    // },
    // {
    //   name: 'status',
    //   title: 'Status',
    //   type: FieldTypes.Select,
    //   formatter: mapChoiceOptionToText,
    //   subKey: 'id',
    //   optionsConfig: {
    //     options: availableStatusOptions(deposit),
    //   },
    // },
  ].filter((i) => {
    return !(
      deposit?.hidden_edit_fields.includes(i.name) ||
      hiddenFields?.includes(i.name)
    );
  });
};
