import { useGetAccountPermissionDataQuery } from '~/api';
import { TUsePageDataReturn } from '~/utils/apiRequest';

import {
  TAccountPermissionsQueries,
  TDataAvailabilityState,
} from './AccountPermissionsContainer.types';

export const useAccountPermissionsData = (
  userName: string,
): TUsePageDataReturn<TAccountPermissionsQueries, TDataAvailabilityState> => {
  const accountPermissionsQuery = useGetAccountPermissionDataQuery(
    { userId: userName },
    { refetchOnMountOrArgChange: true },
  );

  const isAccountPermissionsLoading =
    accountPermissionsQuery.isLoading || accountPermissionsQuery.isFetching;
  const isDataLoading = isAccountPermissionsLoading;

  const hasAccountPermissionsData = Boolean(accountPermissionsQuery.data);

  return {
    queries: { accountPermissionsQuery },
    isDataLoading,
    dataAvailabilityState: {
      hasAccountPermissionsData,
    },
  };
};
