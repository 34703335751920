import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { CodeEditor, Notification } from 'react-ui-kit-exante';

import { useLazyGetUserQuery, useUpdateUserMutation } from '~/api';
import { toStringify } from '~/utils/formatters/toStringify';
import { validationJSON } from '~/utils/validation/validationJSON';

import { StyledTabActionsContainer } from '../../../../ApplicationEntry.styled';
import { ApplicationContext } from '../../../../contexts/ApplicationContext';
import { TabsContext } from '../../../../contexts/TabsContext';

import {
  StyledNotificationsEditorSaveBtn,
  StyledNotificationsEditorWrapper,
} from './NotificationsEditor.styled';

export const NotificationsEditor = () => {
  const { setCurrentTabIsDirty } = useContext(TabsContext);
  const { authdbId } = useContext(ApplicationContext);

  const [fetchUser, userState] = useLazyGetUserQuery();
  const [updateUser, updateState] = useUpdateUserMutation();

  const { data, isLoading: isUserLoading, isFetching } = userState;

  const [editorValue, setEditorValue] = useState('');
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (authdbId) {
      fetchUser({ userId: authdbId });
    }
  }, [authdbId]);

  useEffect(() => {
    if (data?.info?.notifications) {
      setEditorValue(toStringify(data.info.notifications));
    }
  }, [data]);

  useEffect(() => {
    setCurrentTabIsDirty(isDirty);
  }, [isDirty, setCurrentTabIsDirty]);

  const editNotifications = useCallback(async () => {
    if (validationJSON(editorValue)) {
      const response = await updateUser({
        userId: authdbId,
        data: { ...data?.info, notifications: JSON.parse(editorValue) },
      });

      if ('data' in response) {
        setIsDirty(false);
        setCurrentTabIsDirty(false);
        Notification.success({ title: 'Notifications successfully edited' });
      }
    }
  }, [editorValue, updateUser, authdbId, data?.info, setCurrentTabIsDirty]);

  const handleOnChange = useCallback((value: string) => {
    setEditorValue(value);
    setIsDirty(true);
  }, []);

  const getValue = useMemo(() => {
    if (isUserLoading || isFetching) {
      return 'Loading...';
    }
    return editorValue || 'Unfortunately, there is no user data available...';
  }, [isUserLoading, isFetching, editorValue]);

  return (
    <StyledNotificationsEditorWrapper>
      <StyledTabActionsContainer className="TabActionsContainer">
        <StyledNotificationsEditorSaveBtn
          label="Save"
          iconSize={24}
          iconColor="action"
          iconName="SaveIcon"
          title="Save notifications"
          onClick={editNotifications}
          className="NotificationsEditorSaveBtn"
          disabled={updateState.isLoading || !isDirty}
        />
      </StyledTabActionsContainer>

      <CodeEditor
        tabSize={2}
        mode="json"
        theme="github"
        value={getValue}
        disableContentPaddings
        onChange={handleOnChange}
        className="NotificationsEditor"
      />
    </StyledNotificationsEditorWrapper>
  );
};
