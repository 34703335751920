import { includes, uniq } from 'lodash';
import { Notification } from 'react-ui-kit-exante';
import { TestContext, ValidationError } from 'yup';

import {
  TAccountPermissionByUsername,
  TDefaultAccountAccess,
} from '~/api/nodeBackApi/accountPermissions/accountPermissions.types';
import { DEFAULT_ACCOUNT_ACCESS } from '~/api/nodeBackApi/accounts/accounts.constants';
import {
  accountAccessTypes,
  TAccountAccessType,
} from '~/api/nodeBackApi/accounts/accounts.types';
import { TFormHandler } from '~/types/form/handler';
import { IOption } from '~/types/form/options';
import { findAllIndexes } from '~/utils/findAllIndexes';
import { createServiceResponse } from '~/utils/services';

import {
  TCreateHandlersMapArguments,
  TFormData,
} from './PermissionsFormContainer.types';

export const getDefaultValues = (
  accountPermissions: TAccountPermissionByUsername[],
  defaultAccountAccess: TDefaultAccountAccess,
): TFormData => ({
  defaultAccountAccess: defaultAccountAccess || 'blocked',
  permissions: accountPermissions,
});

export const createHandlersMap = ({
  userName,
  previousPermissions,
  setPreviousPermissions,
  atpPermissions,
  updatePermissions,
  updateAtpPermissions,
}: TCreateHandlersMapArguments): TFormHandler<TFormData> | null => {
  return {
    permissions: async (permissions) => {
      const response = await updatePermissions({
        userId: userName,
        permissions,
        previousPermissions,
        setPreviousPermissions,
      });

      if (!(`error` in response)) {
        Notification.success({
          title: 'Account permissions are successfully updated',
        });
      }

      return response;
    },

    defaultAccountAccess: async (defaultAccountAccess) => {
      if (!atpPermissions) {
        return Promise.resolve(
          createServiceResponse({
            data: defaultAccountAccess,
            messages: { error: 'Atp permissions empty' },
          }),
        );
      }

      const response = await updateAtpPermissions({
        userId: userName,
        atpPermissions: {
          ...atpPermissions,
          superuser: isAccountAccessType(defaultAccountAccess)
            ? defaultAccountAccess
            : DEFAULT_ACCOUNT_ACCESS,
        },
      });

      if (!(`error` in response)) {
        Notification.success({
          title: 'Default account access is successfully updated.',
        });
      }

      return response;
    },
  };
};

function isAccountAccessType(
  value: string | null,
): value is TAccountAccessType {
  return includes(accountAccessTypes, value);
}

export function validatePermissions(
  initialValues: TAccountPermissionByUsername[],
  { path, createError }: TestContext,
) {
  const idKeys = initialValues.map((el) => el.accountId);
  const indexes = idKeys.reduce<number[][]>((acc, curr) => {
    const allKeyIndexes = findAllIndexes(idKeys, curr);

    return [...acc, allKeyIndexes];
  }, []);

  const duplicateIndexes = uniq(
    indexes.reduce((acc, curr) => {
      if (curr.length > 1) {
        return acc.concat(curr);
      }

      return acc;
    }, []),
  );

  if (duplicateIndexes.length > 0) {
    return new ValidationError(
      duplicateIndexes.map((item) =>
        createError({
          message: `Field should be unique`,
          path: `${path}.${item}.accountId`,
        }),
      ),
    );
  }

  return true;
}

export const createArraysValidation =
  <T extends Record<keyof T, IOption | string | null>>(uniqKey: keyof T) =>
  (initialValues: T[], { path, createError }: TestContext) => {
    const emptyIndexes: number[] = [];
    const idKeys: string[] = [];

    initialValues.forEach((item, index) => {
      const currentItem = item[uniqKey];

      if (currentItem === null) {
        emptyIndexes.push(index);
      } else {
        idKeys.push(
          typeof currentItem !== 'string' ? currentItem.value : currentItem,
        );
      }
    });

    const indexes = idKeys.reduce<number[][]>((acc, curr) => {
      const allKeyIndexes = findAllIndexes(idKeys, curr);

      return [...acc, allKeyIndexes];
    }, []);
    const duplicateIndexes = uniq(
      indexes.reduce((acc, curr) => {
        if (curr.length > 1) {
          return acc.concat(curr);
        }

        return acc;
      }, []),
    );

    if (duplicateIndexes.length > 0) {
      return new ValidationError(
        duplicateIndexes.map((item) =>
          createError({
            message: `Field should be unique`,
            path: `${path}.${item}.${uniqKey as string}`,
          }),
        ),
      );
    }

    if (emptyIndexes.length > 0) {
      return new ValidationError(
        emptyIndexes.map((item) =>
          createError({
            message: 'Field can not be empty',
            path: `${path}.${item}.${uniqKey as string}`,
          }),
        ),
      );
    }

    return true;
  };
