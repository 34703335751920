import { styled } from 'react-ui-kit-exante';

export const StyledDocumentsGeneralTitle = styled('div')`
  display: flex;
  padding-bottom: 24px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
`;

export const StyledDocumentsTitle = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledDocumentsTableTitle = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledDocumentsTableName = styled('h4')`
  font-size: 18px;
  font-weight: 400;
`;
