import cn from 'classnames';
import { FC } from 'react';
import { getCountryCallingCode } from 'react-phone-number-input';

import { PHONE_INTERNATIONAL_VALUE } from '../PhoneCountrySelect/constants';

import { IPhoneCountryLabelProps } from './types';

export const PhoneCountryLabel: FC<IPhoneCountryLabelProps> = ({
  country,
  label,
  className,
}) => {
  let labelStr: string = label;

  if (country !== PHONE_INTERNATIONAL_VALUE) {
    const countryCallingCode = getCountryCallingCode(country);

    if (countryCallingCode) {
      labelStr += ` +${countryCallingCode}`;
    }
  }

  return <span className={cn('PhoneCountryLabel', className)}>{labelStr}</span>;
};
