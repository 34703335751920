import cn from 'classnames';
import { FC } from 'react';

import { Tag } from '../Tag';

import { StyledSectionBody } from './SectionBody.styled';
import { TSectionBodyProps } from './SectionBody.types';

export const SectionBody: FC<TSectionBodyProps> = ({
  tags = [],
  withoutText,
  className,
  tagKey,
}) => {
  return (
    <StyledSectionBody className={cn('SectionBody', className)}>
      {tags?.length > 0 &&
        tags.map((tag) => {
          return (
            <Tag
              withoutText={withoutText}
              key={tag.id}
              tag={tag}
              tagKey={tagKey}
            />
          );
        })}
    </StyledSectionBody>
  );
};
