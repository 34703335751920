import { ChangeEvent, FC, useState } from 'react';
import { Input, RadioGroup } from 'react-ui-kit-exante';

import { useCreateCommentMutation, useGetGlobalContextQuery } from '~/api';

import { CALL_TYPE, mapCommentType } from '../Comments.constants';
import { StyledButton, StyledRowContainer } from '../Comments.styled';

import { TAddCommentFormProps } from './AddCommentForm.types';

export const AddCommentForm: FC<TAddCommentFormProps> = ({
  entityId,
  entityType,
  onRefetchTable,
  contentType,
}) => {
  const { data: globalContext } = useGetGlobalContextQuery();

  const [onCommentCreate, commentCreateState] = useCreateCommentMutation();

  const [comment, setComment] = useState('');
  const [commentType, setCommentType] = useState(CALL_TYPE);

  const isApplication = entityType === 'application';

  const commentTypes =
    Object.values(globalContext?.comment_types || {})?.filter(
      ({ is_selectable }) => is_selectable,
    ) || [];

  const commentTypesOptions = commentTypes.map(({ title, id }) => ({
    label: title,
    value: String(id),
  }));

  const handleChangeComment = (event: ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const onAddComment = async (answered = false) => {
    const response = await onCommentCreate({
      answered,
      content: comment,
      content_type: contentType,
      kind: isApplication ? Number(commentType) : mapCommentType[entityType],
      object_id: entityId,
      removed: false,
      hidden: false,
      is_system: false,
      dashboard: false,
    });

    if (!('error' in response)) {
      setComment('');
      onRefetchTable();
    }
  };

  const isAddCommentDisabled =
    !comment.trim().length || commentCreateState.isLoading;

  const onCommentTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCommentType(e.target.value);
  };

  const isCallButtons = isApplication && commentType === CALL_TYPE;
  return (
    <>
      {isApplication && (
        <StyledRowContainer className="SelectTypeContainer">
          <RadioGroup
            radioOptions={commentTypesOptions}
            handleChange={onCommentTypeChange}
            value={commentType}
          />
        </StyledRowContainer>
      )}

      <StyledRowContainer className="AddCommentContainer">
        <Input
          placeholder="Your comment"
          value={comment}
          onChange={handleChangeComment}
          data-test-id="clientcard__textarea--comment"
          fullWidth
        />

        {!isCallButtons && (
          <StyledButton
            onClick={() => onAddComment()}
            disabled={isAddCommentDisabled}
            color="secondary"
            fullWidth
            data-test-id="clientcard__button--add-comment"
            sx={{
              maxWidth: '156px',
            }}
          >
            Add comment
          </StyledButton>
        )}
      </StyledRowContainer>
      {isCallButtons && (
        <StyledRowContainer className="AddCommentContainerButtons">
          <StyledButton
            onClick={() => onAddComment(true)}
            disabled={isAddCommentDisabled}
            color="secondary"
            fullWidth
            data-test-id="clientcard__button--add-comment"
          >
            Answered, add comment
          </StyledButton>
          <StyledButton
            onClick={() => onAddComment()}
            disabled={isAddCommentDisabled}
            color="secondary"
            fullWidth
            data-test-id="clientcard__button--add-comment"
          >
            Was no answer
          </StyledButton>
        </StyledRowContainer>
      )}
    </>
  );
};
