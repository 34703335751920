export const COLUMNS = [
  {
    Header: 'Own',
    accessor: 'own',
    disableSortBy: true,
  },
  {
    Header: 'Omnibus',
    accessor: 'omnibus',
    disableSortBy: true,
  },
  {
    Header: 'Segregated',
    accessor: 'segregated',
    disableSortBy: true,
  },
];

export const TABLE_ID = 'financial-settings-tab-accounts-segregation-table';
