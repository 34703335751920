import { Button } from 'react-ui-kit-exante';

import { stopPropagationOnKeyDown } from '~/utils/stopPropagationOnKeyDown';

import {
  StyledConfirmationWithSelectActions,
  StyledConfirmationWithSelectDescription,
  StyledConfirmationWithSelectTitle,
  StyledConfirmationWithSelectWrapper,
} from './ActionWithConfirmationWithSelect.styled';
import { TActionWithConfirmationWithSelect } from './ActionWithConfirmationWithSelect.types';

// we can't use ActionWithConfirmation with some selects because mui tooltip has bug
export const ActionWithConfirmationWithSelect = ({
  children,
  open,
  anchorEl,
  onClose,
  title,
  content,
  confirmText,
  handleConfirm,
  handleClose,
  disabledConfirm,
  description,
  paperStyle,
}: TActionWithConfirmationWithSelect) => {
  return (
    <>
      {children}
      <StyledConfirmationWithSelectWrapper
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        className="ConfirmationWithSelectWrapper"
        slotProps={{
          paper: {
            style: paperStyle,
          },
        }}
      >
        {title && (
          <StyledConfirmationWithSelectTitle className="ConfirmationWithSelectTitle">
            {title}
          </StyledConfirmationWithSelectTitle>
        )}
        {description && (
          <StyledConfirmationWithSelectDescription className="ConfirmationWithSelectDescription">
            {description}
          </StyledConfirmationWithSelectDescription>
        )}
        {content}
        <StyledConfirmationWithSelectActions className="ConfirmationWithSelectActions">
          <Button
            color="transparent"
            textColor="action"
            disabled={disabledConfirm}
            onClick={handleConfirm}
            onKeyDown={stopPropagationOnKeyDown}
          >
            {confirmText ?? 'Confirm'}
          </Button>
          <Button
            color="transparent"
            textColor="secondary"
            onClick={handleClose}
            onKeyDown={stopPropagationOnKeyDown}
          >
            Cancel
          </Button>
        </StyledConfirmationWithSelectActions>
      </StyledConfirmationWithSelectWrapper>
    </>
  );
};
