import { styled } from 'react-ui-kit-exante';

export const StyledUploadedTableRow = styled('div')`
  display: flex;
`;

export const StyledUploadedTableLink = styled('a')`
  color: ${({ theme }) => theme?.color.typo.action};

  &:hover,
  &:visited {
    color: ${({ theme }) => theme?.color.typo.promo};
  }
`;
