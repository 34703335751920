import { styled, Table } from 'react-ui-kit-exante';

export const StyledRequestTableWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: end;

  > div > header {
    padding: 0 24px 16px;
  }
`;

export const StyledRequestTable = styled(Table)`
  width: 100%;
`;
