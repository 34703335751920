import { isEmpty } from 'lodash';
import { createContext, FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { TChildren } from 'types/TChildren';
import { useEditWithdrawalMutation, useGetPermissionsQuery } from '~/api';
import { TParams } from '~/router/router.types';
import { getDirtyValues } from '~/utils/forms/getDirtyValues';

import {
  TWithdrawalFormContext,
  TWithdrawalFormProps,
} from './WithdrawalFormContext.types';
import {
  getAccountToTransferValue,
  prepareWithdrawalFormData,
  getDefaultFormValues,
} from './helpers';

const initialState: TWithdrawalFormContext = {
  isEdit: false,
  resetForm: () => {},
  setIsEdit: () => {},
  formNotEdited: true,
  messageForBankToTransfer: '',
  isCanChangeAmount: false,
  isCanChange: false,
  isFormLoading: false,
};

export const WithdrawalFormContext =
  createContext<TWithdrawalFormContext>(initialState);

export const WithdrawalFormProvider: FC<TWithdrawalFormProps & TChildren> = ({
  children,
  withdrawal,
}) => {
  const { id: withdrawalId } = useParams<TParams>();
  const [onEditWithdrawal, state] = useEditWithdrawalMutation();
  const [isEdit, setIsEdit] = useState(initialState.isEdit);
  const { data: permissions } = useGetPermissionsQuery();

  const isCanChangeAmount = Boolean(
    permissions?.withdrawals.withdrawals_amount_change,
  );
  const isCanChange = Boolean(permissions?.withdrawals.withdrawals_edit);

  const defaultValues = getDefaultFormValues(
    withdrawal,
    isCanChangeAmount,
    isCanChange,
  );

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit, getValues, formState, watch, reset } = methods;

  watch(Object.entries(defaultValues).map(([name]) => name));

  const formData = getDirtyValues(formState.dirtyFields, getValues());
  const formValues = methods.getValues();
  const bankToTransfer = formValues.bank_to_transfer;
  const currency = formValues.currency;

  const messageForBankToTransfer = formValues.transfer_using_personal_iban
    ? "You can't change bank to transfer unless transfer using personal iban is true"
    : '';

  const setAccountToTransfer = () => {
    if (bankToTransfer) {
      const value = getAccountToTransferValue({
        bankToTransfer,
        currency: formValues.currency,
        banksToTransfer: withdrawal.banks_to_transfer,
      });

      methods.setValue('account_to_transfer', value, { shouldDirty: true });
    }
  };

  const resetBankToTransfer = () => {
    if (formState.dirtyFields.currency) {
      methods.setValue('bank_to_transfer', '', { shouldDirty: true });
      methods.setValue('account_to_transfer', '', { shouldDirty: true });
    }
  };

  useEffect(() => {
    setAccountToTransfer();
  }, [bankToTransfer]);

  useEffect(() => {
    resetBankToTransfer();
  }, [currency]);

  useEffect(() => {
    reset(defaultValues);
  }, [isEdit]);

  const onSave = () => {
    if (withdrawalId) {
      setIsEdit(!isEdit);

      onEditWithdrawal({
        withdrawalId,
        data: prepareWithdrawalFormData(formData),
      });

      methods.reset(defaultValues);
    }
  };

  const value = useMemo(
    (): TWithdrawalFormContext => ({
      isEdit,
      setIsEdit,
      isCanChange,
      isCanChangeAmount,
      messageForBankToTransfer,
      formNotEdited: isEmpty(formData),
      resetForm: () => methods.reset(defaultValues),
      isFormLoading: state.isLoading,
    }),
    [onSave, formData, state.isLoading],
  );

  return (
    <WithdrawalFormContext.Provider value={value}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSave)}>{children}</form>
      </FormProvider>
    </WithdrawalFormContext.Provider>
  );
};
