import cn from 'classnames';
import { FC, useContext, useMemo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Table } from 'react-ui-kit-exante';

import { useDeleteTableRowMutation, useLazyGetTableSectionQuery } from '~/api';
import { TSection } from '~/api/applications/applications.types';

import { ApplicationContext } from '../../../contexts/ApplicationContext';

import { StyledTableContainer } from './SectionTableBody.styled';
import { getColumns } from './columns';
import { AddRowForm } from './components/AddRowForm';

export type TSectionTableBody = {
  section: TSection;
  parent?: TSection;
};

export const SectionTableBody: FC<TSectionTableBody> = ({
  section,
  parent,
}) => {
  const { application } = useContext(ApplicationContext);
  const formContext = useFormContext();

  const applicationId = application?.application?.id;
  const sectionName = section.name;

  const inflowOutflowFlag =
    sectionName === 'inflow_outflow_table_section' &&
    formContext.watch('inflow_outflow_flag');

  const [getTableSection, { data: tableInfo, isLoading, isFetching }] =
    useLazyGetTableSectionQuery();
  const [onDeleteTableRow, state] = useDeleteTableRowMutation();

  useEffect(() => {
    if (applicationId) {
      getTableSection({
        applicationId,
        tableKey: sectionName || '',
      });
    }
  }, [applicationId, sectionName]);

  const onDeleteRow = (rowId: number) => {
    onDeleteTableRow({ rowId });
  };

  const actionProcessing = Boolean(state.isLoading);

  const columns = getColumns({
    columns: tableInfo?.columns,
    tableName: sectionName,
    rows: tableInfo?.rows,
    deleteAction: onDeleteRow,
    actionProcessing,
  });

  const addActionIsDisable = useMemo(() => {
    if (sectionName === 'inflow_outflow_table_section') {
      return !inflowOutflowFlag;
    }
    return false;
  }, [section, inflowOutflowFlag]);

  const handleAdd = () => {};

  const additionalActions = [
    {
      component: (
        <AddRowForm
          section={parent || section}
          onAdd={handleAdd}
          disabled={addActionIsDisable}
          fields={tableInfo?.columns}
        />
      ),
    },
  ];

  if (!sectionName) {
    return null;
  }

  return (
    <StyledTableContainer className="TableContainer">
      <Table
        className={cn('SectionTable', sectionName)}
        columns={columns}
        isLoading={isLoading || isFetching}
        data={tableInfo?.rows || []}
        tableId={sectionName}
        isFlexLayout
        disableSortBy
        hasFilters={false}
        additionalActions={additionalActions}
      />
    </StyledTableContainer>
  );
};
