import { FC, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from 'react-ui-kit-exante';

import { useLazyGetApplicationTagsQuery } from '~/api';
import { TSection } from '~/api/applications/applications.types';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';

import {
  getAllDynamicSectionNames,
  getSections,
  getTagIsEditable,
  getTagsForSection,
} from '../../../ApplicationEntry.helpers';
import { ApplicationContext } from '../../../contexts/ApplicationContext';
import { ApplicationFormProvider } from '../../../contexts/FormContext';
import { TabsContext } from '../../../contexts/TabsContext';
import { Section } from '../Section';
import { TabHeader } from '../TabHeader';

import { StyledColumn, StyledColumns } from './TabStructure.styled';
import { TTabStructureProps } from './TabStructure.types';

export const TabStructure: FC<TTabStructureProps> = ({
  withMainSave,
  entityType,
  additionalFormData,
  withoutText,
  columnsWidth,
}) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `application-entry-${entityType}`,
  );
  const { hash } = useLocation();
  const [getApplicationTagsQuery, state] = useLazyGetApplicationTagsQuery();

  const { currentTab } = useContext(TabsContext);
  const { application, activeLegalEntity } = useContext(ApplicationContext);

  const tab = useMemo(() => {
    const current = currentTab?.tab;
    if (current?.subTabs?.length) {
      return current?.subTabs?.find((s) => s.name === hash?.replace('#', ''));
    }
    return current;
  }, [currentTab?.tab]);

  useEffect(() => {
    if (state.isLoading) {
      setStartHandleTime();
    }
  }, [state.isLoading, setStartHandleTime]);

  const positionSections = useMemo(
    () => tab?.children?.filter((item) => item.position),
    [tab],
  );

  const columns = [positionSections?.[0], positionSections?.[1]].filter(
    Boolean,
  );

  const allSections = useMemo(
    () =>
      columns.length > 0
        ? columns.flatMap((i) =>
            getSections(i?.children).map((section) => {
              return section;
            }),
          )
        : getSections(tab?.children).map((section) => {
            return section;
          }),
    [columns, tab],
  );

  const getTags = (names?: string) => {
    const applicationId = application?.application?.id;
    if (applicationId) {
      getApplicationTagsQuery({
        objectId: applicationId,
        sectionName: names ?? '',
        legalEntity: activeLegalEntity,
      });
    }
  };

  useEffect(() => {
    if (allSections.length && tab && state.isUninitialized) {
      const fields = getAllDynamicSectionNames(allSections);
      getTags(fields.join(','));
    }
  }, [allSections, tab, state, activeLegalEntity, application]);

  const allEditableTagsForSection = allSections?.flatMap((section) => {
    const sectionChildren = section?.children;

    if (sectionChildren?.length && sectionChildren.length > 1) {
      return sectionChildren
        .map((innerSection) => {
          return (
            getTagsForSection(state?.data, innerSection)?.filter(
              getTagIsEditable,
            ) || []
          );
        })
        .flat();
    }

    return (
      getTagsForSection(state?.data, ...(sectionChildren || []))?.filter(
        getTagIsEditable,
      ) || []
    );
  });

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: state?.data,
    processTrigger: !state.isLoading && state.isSuccess,
  });

  const getSection = (section: TSection) => {
    return (
      <Section
        key={section.name}
        section={section}
        tagsData={state?.data}
        withoutText={withoutText}
      />
    );
  };

  if (!tab) {
    return null;
  }

  if (state.isLoading || state.isFetching) {
    return <Loader isCentered size="l" />;
  }

  return (
    <ApplicationFormProvider
      allTags={state?.data}
      additionalFormData={additionalFormData}
      editableTags={allEditableTagsForSection}
    >
      {withMainSave && <TabHeader />}

      {columns.length > 0 && (
        <StyledColumns className="Columns" columnsWidth={columnsWidth}>
          {columns.map((i) => (
            <StyledColumn
              className="Column"
              key={`${i?.resourcetype}_${i?.position}`}
            >
              {getSections(i?.children).map((section) => {
                return getSection(section);
              })}
            </StyledColumn>
          ))}
        </StyledColumns>
      )}

      {!columns.length &&
        getSections(tab?.children).map((section) => {
          return getSection(section);
        })}
    </ApplicationFormProvider>
  );
};
