import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

export const StyledSection = styled('div', {
  shouldForwardProp: blockNonNativeProps('isInnerSection'),
})<{ isInnerSection?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ isInnerSection }) => (isInnerSection ? '0 0 24px' : '24px')};

  border-top: ${({ theme, isInnerSection }) =>
    isInnerSection ? 'none' : `1px solid ${theme.color.line.primary}`};
`;

export const StyledTitle = styled('div', {
  shouldForwardProp: blockNonNativeProps(['isCollapsibleTitle']),
})<{ isCollapsibleTitle?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ isCollapsibleTitle }) =>
    isCollapsibleTitle ? '0' : '24px'};
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  font-family: ${({ theme }) => theme?.font?.main};
`;
