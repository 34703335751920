import { styled } from 'react-ui-kit-exante';

export const StyledTokensListWrapper = styled('div')`
  width: 100%;
  background: ${({ theme }) => theme?.color.bg.primary};
`;

export const StyledTokensListSection = styled('div')`
  border-top: ${({ theme }) => `1px solid ${theme?.color?.dropdown?.border}`};
  '&:not(:first-of-type)': {
    margin-top: 24px;
  }
`;

export const StyledTokensListTitle = styled('h2')`
  font-size: 24px;
  line-height: 48px;
  font-weight: 400;
  font-family: ${({ theme }) => theme?.font?.main};
`;
