import { useContext } from 'react';

import { Comments } from '~/modules/comments/components/Comments';

import { ApplicationContext } from '../../../../contexts/ApplicationContext';

export const CommentsSection = () => {
  const { application, isLoadingApplication } = useContext(ApplicationContext);

  if (!application?.application?.id) {
    return null;
  }

  return (
    <Comments
      entityId={application.application.id}
      entityType="application"
      needRefetch={isLoadingApplication}
    />
  );
};
