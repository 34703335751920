import { styled, IconButton } from 'react-ui-kit-exante';

export const StyledPersonsAddPersonBtn = styled(IconButton)`
  width: 100%;
  display: flex;
  justify-content: end;
  margin-bottom: 24px;
`;

export const StyledPersonsBlock = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 38px;
`;

export const StyledPersons = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledPersonsName = styled('h3')`
  margin: 24px 0;
  font-size: 20px;
  font-weight: 400;
  font-family: ${({ theme }) => theme?.font?.header};
`;

export const StyledPersonsCheckbox = styled('div')`
  display: flex;
  margin-bottom: 24px;
  align-items: center;
`;

export const StyledPersonsCheckboxTitle = styled('div')`
  font-size: 15px;
`;

export const StyledPersonsCheckboxInfo = styled('div')`
  display: grid;
  gap: 8px;
  justify-content: space-between;
  grid-template-columns: 30% 30% 30%;
`;

export const StyledPersonsNameWithActions = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledPersonsActions = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StyledPersonsTitleGroup = styled('h2')`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  padding: 16px 0;
  margin: 0;
  width: 100%;
  font-family: ${({ theme }) => theme?.font?.header};
  border-top: 1px solid ${({ theme }) => theme.color.line.primary};
  border-bottom: 1px solid ${({ theme }) => theme.color.line.primary};
`;
