import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import { TUpload } from '~/api/withdrawals/withdrawals.types';
import { baseQueryHandler } from '~/utils/apiRequest';
import { getFormData } from '~/utils/formData';

import { TDocs, TDocTypes, TEditDocBody, TUploadDocs } from './docs.types';

export const docsApi = createApi({
  reducerPath: 'docsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['DocTypes'],
  endpoints: (builder) => ({
    getDocTypes: builder.query<TDocTypes[], { applicationId?: number }>({
      query: ({ applicationId }) => {
        return {
          url: `/rest/docs/doc-types/${applicationId}/`,
        };
      },
      providesTags: ['DocTypes'],
    }),

    getDocs: builder.query<TDocs, { applicationId: number; type: string }>({
      query: ({ applicationId, type }) => {
        return {
          url: `/rest/docs/by-type/${applicationId}/${type}/`,
        };
      },
      providesTags: ['DocTypes'],
    }),

    deleteDocFile: builder.mutation<void, { fileId: number }>({
      query: ({ fileId }) => {
        return {
          url: `/rest/docs/document/${fileId}/delete/`,
          method: 'POST',
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled;
        Notification.success({
          title: 'Document was deleted',
        });
      },
      invalidatesTags: ['DocTypes'],
    }),

    editDocFile: builder.mutation<void, { fileId: number; data: TEditDocBody }>(
      {
        query: ({ fileId, data }) => {
          return {
            url: `/rest/docs/change_history/${fileId}/`,
            method: 'POST',
            data,
          };
        },
        async onQueryStarted(_, { queryFulfilled }) {
          await queryFulfilled;
          Notification.success({
            title: 'Comment was added',
          });
        },
      },
    ),

    editDocGroup: builder.mutation<void, { fileId: number; type: string }>({
      query: ({ fileId, type }) => {
        return {
          url: `/rest/docs/change_type/${type}/${fileId}/`,
          method: 'POST',
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled;
        Notification.success({
          title: 'Document group was changed',
        });
      },
    }),

    uploadDocs: builder.mutation<TUpload, TUploadDocs>({
      query: ({ applicationId, file, type }) => {
        const formData = getFormData([['doc_type', type]]);
        formData.data.append('file', file);

        return {
          url: `/rest/applications/uploads/doc/${applicationId}/`,
          method: 'POST',
          ...formData,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'File successfully uploaded',
          });
        } catch (e) {
          Notification.error({
            title: 'File uploading error ',
          });
        }
      },
      invalidatesTags: ['DocTypes'],
    }),

    renameFilename: builder.mutation<
      void,
      { fileId: number; filename: string }
    >({
      query: ({ fileId, filename }) => {
        const formData = getFormData([['filename', filename]]);

        return {
          url: `/rest/docs/document/${fileId}/rename/`,
          method: 'POST',
          ...formData,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled;
        Notification.success({
          title: 'Filename was changed',
        });
      },
    }),
  }),
});

export const {
  useLazyGetDocTypesQuery,
  useLazyGetDocsQuery,
  useDeleteDocFileMutation,
  useEditDocFileMutation,
  useEditDocGroupMutation,
  useUploadDocsMutation,
  useRenameFilenameMutation,
} = docsApi;
