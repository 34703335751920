import { styled } from 'react-ui-kit-exante';

export const StyledSectionTitle = styled('div')`
  display: flex;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
`;

export const StyledSelectBlock = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledTransactionsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`;
