import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IconButton, Notification } from 'react-ui-kit-exante';

import {
  useUpdateAtpPermissionsMutation,
  useUpdatePermissionsMutation,
} from '~/api';
import { usePickUserPermissions } from '~/hooks';
import { useAccountStatuses } from '~/hooks/useAccountStatuses';
import { StyledTabActionsContainer } from '~/pages/ApplicationEntry/ApplicationEntry.styled';
import { TFormHandler } from '~/types/form/handler';
import { createFormSubmitHandler } from '~/utils/forms/createFormSubmitHandler';

import { TabsContext } from '../../../../../../contexts/TabsContext';
import { AccountPermissionsAtp } from '../AccountPermissionsAtp';
import { AccountPermissionsList } from '../AccountPermissionsList';

import {
  createHandlersMap,
  getDefaultValues,
} from './PermissionsFormContainer.helpers';
import { StyledPermissionsFormWrapper } from './PermissionsFormContainer.styled';
import {
  TFormData,
  TPermissionsFormContainerProps,
} from './PermissionsFormContainer.types';
import { usePreviousPermissions } from './usePreviousPermissions';
import { getValidationScheme } from './vaildationScheme';

export const PermissionsFormContainer = ({
  accountPermissions: {
    accountPermissions,
    defaultAccountAccess,
    atpPermissions,
  },
  userName,
}: TPermissionsFormContainerProps) => {
  const { setCurrentTabIsDirty } = useContext(TabsContext);
  const { accountStatusesOptions } = useAccountStatuses();

  const defaultValues = useMemo(
    () =>
      getDefaultValues(accountPermissions, defaultAccountAccess || 'blocked'),
    [accountPermissions, defaultAccountAccess],
  );

  const { previousPermissions, setPreviousPermissions } =
    usePreviousPermissions(defaultValues.permissions);

  const initialPermissions = useMemo(
    () => previousPermissions.map((item) => item.accountId),
    [previousPermissions],
  );

  const userPermissions = usePickUserPermissions(['User account status']);

  const validationSchema = useMemo(() => getValidationScheme(), []);

  const formInstance = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    reset,
    getValues,
    formState: { dirtyFields, isDirty, isSubmitting, isValid },
  } = formInstance;

  const [updatePermissions] = useUpdatePermissionsMutation();
  const [updateAtpPermissions] = useUpdateAtpPermissionsMutation();

  const handlersMap = useMemo<TFormHandler<TFormData> | null>(
    () =>
      createHandlersMap({
        userName,
        previousPermissions,
        setPreviousPermissions,
        atpPermissions,
        updatePermissions,
        updateAtpPermissions,
      }),
    [
      atpPermissions,
      previousPermissions,
      setPreviousPermissions,
      userName,
      updatePermissions,
      updateAtpPermissions,
    ],
  );

  const handleFormSubmit = useMemo(
    () =>
      createFormSubmitHandler({
        dirtyFields,
        handlersMap,
        reset,
        getValues,
      }),
    [dirtyFields, getValues, handlersMap, reset],
  );

  useEffect(() => {
    if (isSubmitting && !isValid) {
      Notification.error({
        title: 'There are some validation errors in the form',
      });
    }
  }, [isSubmitting, isValid]);

  useEffect(() => {
    setCurrentTabIsDirty(isDirty);
  }, [isDirty, setCurrentTabIsDirty]);

  const saveButton = useMemo(() => {
    return userPermissions['User account status'].write ? (
      <IconButton
        iconName="SaveIcon"
        iconColor="action"
        label="Save"
        type="submit"
        disabled={isSubmitting || !isDirty}
      />
    ) : null;
  }, [userPermissions, isSubmitting, isDirty]);

  return (
    <FormProvider {...formInstance}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <StyledPermissionsFormWrapper>
          <StyledTabActionsContainer className="TabActionsContainer">
            <AccountPermissionsAtp />

            {saveButton}
          </StyledTabActionsContainer>
        </StyledPermissionsFormWrapper>

        <AccountPermissionsList
          accountStatusesOptions={accountStatusesOptions}
          initialPermissions={initialPermissions}
        />
      </form>
    </FormProvider>
  );
};
