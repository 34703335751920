import { FC } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { useDeleteDocFileMutation } from '~/api';
import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';
import { getTableId } from '~/utils/table';

export const DeleteActionCell: FC<{ fileId: number }> = ({ fileId }) => {
  const tableId = getTableId('REQUESTS_TABLE_NAME');
  const [deleteFile, stateDelete] = useDeleteDocFileMutation();

  const buttonsIsDisabled = stateDelete.isLoading;

  const handleOnDelete = () => deleteFile({ fileId });

  return (
    <ActionWithConfirmation
      onConfirm={handleOnDelete}
      title="Confirmation"
      content="Are you sure you want to delete this file?"
      placement="bottom"
      disabled={buttonsIsDisabled}
      confirmButtonNameKey="Confirm"
      cancelButtonNameKey="Cancel"
    >
      <IconButton
        iconSize={24}
        iconColor="secondary"
        iconName="DeleteIcon"
        data-test-id={`${tableId}__button--delete-record`}
        title="Delete file"
      />
    </ActionWithConfirmation>
  );
};
