import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  calculateCountOfPages,
  IOnFetchArguments,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { useLazyGetReportsQuery } from '~/api';
import { TReports } from '~/api/reports/reports.types';
import { RefreshButton } from '~/components/RefreshButton';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';
import {
  getDefaultPagination,
  getFilterParams,
  getPaginationParams,
} from '~/utils/table';

import {
  DISPLAYED_COLUMNS_KEYS,
  REFETCH_DATA_INTERVAL,
  getColumns,
  PAGE_SIZES,
  TABLE_ID,
} from './Reports.constants';
import { getAdditionalFilters, prepareReportsFilters } from './Reports.filters';
import { findTasksInProcess } from './Reports.helpers';
import { PendingReportsContext } from './contexts/PendingReportsContext';

export const ReportsContainer: FC = () => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `reports-page-${TABLE_ID}`,
  );
  const [silentUpdate, setSilentUpdate] = useState(false);

  const { pendingReports, clearPendingReports, updatePendingReports } =
    useContext(PendingReportsContext);

  const [
    fetchReports,
    {
      isLoading: isGetReportsLoading,
      isSuccess: isGetReportsSuccess,
      isFetching: isGetReportsFetching,
      isUninitialized: isGetReportsUninitialized,
    },
  ] = useLazyGetReportsQuery();

  useEffect(() => {
    if (isGetReportsLoading) {
      setStartHandleTime();
    }
  }, [isGetReportsLoading, setStartHandleTime]);

  const getReports = useCallback(
    async (params: IOnFetchArguments) => {
      const paginationParams = getPaginationParams(params);
      const filterParams = prepareReportsFilters(getFilterParams(params));
      const response = await fetchReports({
        paginationParams,
        filterParams,
      });

      return response.data;
    },
    [fetchReports],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId: TABLE_ID,
      data: {
        onFetch: getReports,
        onSuccess: () => {
          setTimeout(() => clearPendingReports(), 0);
        },
        onFailure: () => {
          setTimeout(() => clearPendingReports(), 0);
        },
      },
      pagination: {
        getDefaultPagination,
      },
      saveViewParamsAfterLeave: true,
    }),
    [getReports],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    skip,
    isLoading: isTableLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    fetchData,
  } = useTableData(tableDataArgs);

  const total = data?.iTotalDisplayRecords ?? 0;

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount: calculateCountOfPages(total, limit),
    }),
    [skip, limit, page, setLimit, setPage, total],
  );

  const tableData = data?.data || [];

  const refetchData = useCallback(
    (id?: number) => {
      if (isTableLoading || isGetReportsFetching) {
        return;
      }

      if (id) {
        updatePendingReports(id);
      }

      setSilentUpdate(true);
      fetchData().finally(() => setSilentUpdate(false));
    },
    [isTableLoading, isGetReportsFetching],
  );

  const columns = useMemo(() => {
    return getColumns({
      refetchData,
      pendingReports,
    });
  }, [refetchData, pendingReports?.size]);

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [setFilter, removeFilter],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
    }),
    [additionalFilters, resetFilters, filters],
  );

  const tasksInProcess = findTasksInProcess(tableData);

  const isTableDataLoading = !silentUpdate && isTableLoading;

  useEffect(() => {
    if (!data || !tasksInProcess.length || isGetReportsUninitialized) {
      return;
    }

    const interval = setInterval(refetchData, REFETCH_DATA_INTERVAL);

    return () => clearInterval(interval);
  }, [data, refetchData, isGetReportsUninitialized, tasksInProcess.length]);

  const additionalActions = [
    {
      component: (
        <RefreshButton
          key="refresh"
          onRefresh={fetchData}
          disabled={isTableLoading}
          iconColor="secondary"
          title="Refresh table data"
        />
      ),
    },
  ];

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: data?.data,
    processTrigger: !isGetReportsLoading && isGetReportsSuccess,
  });

  return (
    <Table<TReports>
      title="CRM Reports"
      className="ReportsTable"
      titleSize={1}
      columns={columns}
      displayedColumnKeys={DISPLAYED_COLUMNS_KEYS}
      isLoading={isTableDataLoading}
      filtersExpanded
      isFlexLayout
      disableSortBy
      manualSortBy
      hasFilters
      filteringProps={filteringProps}
      data={tableData}
      tableId={TABLE_ID}
      hasPagination
      showTableInfo
      saveColumnOrder
      serverPaginationProps={serverPaginationProps}
      isHiddenColumnSelect
      saveViewParamsAfterLeave
      pageSizes={PAGE_SIZES}
      additionalActions={additionalActions}
    />
  );
};
