import { Skeleton as UISkeleton } from '../../../Table/TableSkeleton/Skeleton';

import {
  UploadSkeletonBulletStyled,
  UploadSkeletonButtonStyled,
  UploadSkeletonContainerLeftStyled,
  UploadSkeletonContainerRightStyled,
  UploadSkeletonContainerStyled,
  UploadSkeletonDndIconStyled,
  UploadSkeletonDndTextStyled,
  UploadSkeletonTitleStyled,
} from './Skeleton.styled';
import { TSkeleton } from './types';

export const Skeleton = ({ info, tooltip }: TSkeleton) => {
  return (
    <UploadSkeletonContainerStyled className="UploadSkeletonContainer">
      <UploadSkeletonContainerLeftStyled className="UploadSkeletonContainerLeft">
        <UploadSkeletonTitleStyled />
        <UploadSkeletonBulletStyled />
        <UploadSkeletonDndIconStyled />
        <UploadSkeletonDndTextStyled />
        <UploadSkeletonButtonStyled />
      </UploadSkeletonContainerLeftStyled>
      {(info || tooltip) && (
        <UploadSkeletonContainerRightStyled className="UploadSkeletonContainerRight">
          <UISkeleton width={16} height={16} />
        </UploadSkeletonContainerRightStyled>
      )}
    </UploadSkeletonContainerStyled>
  );
};
