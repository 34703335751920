import { useContext } from 'react';
import { Loader } from 'react-ui-kit-exante';

import { EntryScreenWrapper } from '~/components/EntryScreen';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';

import { Header } from './components/Header';
import { Managers } from './components/Managers';
import { TabsProvider } from './contexts/TabsContext';
import { Tabs } from './tabs/Tabs';

export const ApplicationEntry = () => {
  const { isStructureLoading, structure } = useContext(ApplicationContext);

  return (
    <EntryScreenWrapper>
      <Header />
      <Managers />
      {isStructureLoading ? (
        <Loader isCentered size={32} isInner />
      ) : (
        <TabsProvider structure={structure?.children}>
          <Tabs />
        </TabsProvider>
      )}
    </EntryScreenWrapper>
  );
};
