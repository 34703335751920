import { FC } from 'react';
import { Loader } from 'react-ui-kit-exante';

import { StyledTabLoader } from './TabLoader.styled';

export const TabLoader: FC = () => {
  return (
    <StyledTabLoader className="TabLoader">
      <Loader isCentered size={24} />
    </StyledTabLoader>
  );
};
