export const getNameFormFields = (isCorporate?: boolean) => {
  const baseColumns = [
    {
      name: 'first_name',
      title: 'First name',
      editable: true,
    },
    {
      name: 'last_name',
      title: 'Last name',
      editable: true,
    },
  ];

  const additionalColumn = [
    {
      name: 'company_name',
      title: 'Company name',
      editable: true,
    },
  ];

  if (isCorporate) {
    return [...baseColumns, ...additionalColumn];
  }

  return baseColumns;
};
