import { ISelectOption } from 'react-ui-kit-exante';

export enum TokenTypes {
  Password = 'password',
  Sms = 'sms',
  Email = 'email',
  Totp = 'totp',
}

export const DEFAULT_PASSWORD_VALUES: Record<string, any> = {
  type: 'password',
  name: '',
  value: '',
  revalue: '',
};

export const DEFAULT_SMS_AND_EMAIL_VALUES = {
  type: 'sms',
  name: '',
};

export const DEFAULT_TOTP_VALUES = {
  type: 'sms',
  name: '',
  value: '',
};

export enum PasswordManagementType {
  Password = 'password',
  SMS = 'sms',
  Email = 'email',
  Totp = 'totp',
}

export const PasswordManagementTypeOptions: ISelectOption[] = [
  { value: PasswordManagementType.Password, label: 'Password' },
  { value: PasswordManagementType.SMS, label: 'SMS' },
  { value: PasswordManagementType.Email, label: 'E-mail' },
  { value: PasswordManagementType.Totp, label: 'One Time Password' },
];
