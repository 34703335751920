import { useContext } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { useClientActionsMutation } from '~/api';
import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';

import { TClientActions } from '../ClientForm.types';

import {
  StyledClientFormActions,
  StyledClientFormClientHeader,
  StyledClientFormTitle,
} from './ClientFormHeader.styled';

export const ClientFormHeader = ({
  id,
  clientId,
  closedOnly,
  closedAccount,
  blockedAccount,
}: TClientActions) => {
  const [changeClient, state] = useClientActionsMutation();
  const { isLoadingApplication } = useContext(ApplicationContext);

  const isLoadingAction = state.isLoading || isLoadingApplication;

  return (
    <StyledClientFormClientHeader className="ClientFormClientHeader">
      <StyledClientFormTitle className="ClientFormTitle">
        {clientId}
      </StyledClientFormTitle>
      <StyledClientFormActions className="ClientFormActions">
        <ActionWithConfirmation
          withCloseAfterConfirmation
          onConfirm={() => changeClient({ data: { id, closed_only: true } })}
          title="Close only client"
          content={`Do you want to close ${clientId}? You will set the client accounts to the close only status.`}
          placement="bottom"
          confirmButtonNameKey="Close only"
          cancelButtonNameKey="Cancel"
        >
          <IconButton
            iconName="CircleCloseIcon"
            iconColor="warning"
            label="Close Only Mode"
            iconSize={32}
            disabled={closedOnly || isLoadingAction}
          />
        </ActionWithConfirmation>
        <ActionWithConfirmation
          withCloseAfterConfirmation
          onConfirm={() => changeClient({ data: { id, closed_account: true } })}
          title="Close account client"
          content={`Do you want to close ${clientId}?  All account data will be unavailable in CRM and the client’s area.`}
          placement="bottom"
          confirmButtonNameKey="Close account"
          cancelButtonNameKey="Cancel"
        >
          <IconButton
            iconName="CircleCloseIcon"
            iconColor="ghost"
            label="Close account"
            iconSize={32}
            disabled={closedAccount || isLoadingAction}
          />
        </ActionWithConfirmation>
        <ActionWithConfirmation
          withCloseAfterConfirmation
          onConfirm={() =>
            changeClient({ data: { id, blocked_account: true } })
          }
          title="Block account client"
          content={`Do you want to block ${clientId}? You will set the client accounts to the read only status.`}
          placement="bottom"
          confirmButtonNameKey="Block account"
          cancelButtonNameKey="Cancel"
        >
          <IconButton
            iconName="BlockAccountIcon"
            iconColor="radical"
            label="Block"
            iconSize={32}
            disabled={blockedAccount || isLoadingAction}
          />
        </ActionWithConfirmation>
      </StyledClientFormActions>
    </StyledClientFormClientHeader>
  );
};
