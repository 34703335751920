import { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  calculateCountOfPages,
  IOnFetchArguments,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { useLazyGetApplicationAccountSegregationsQuery } from '~/api';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { getDefaultPagination, getPaginationParams } from '~/utils/table';

import { COLUMNS, TABLE_ID } from './AccountsSegregation.constants';

export const AccountsSegregation = () => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `application-entry-${TABLE_ID}`,
  );
  const { application } = useContext(ApplicationContext);

  const applicationId = application?.application?.id;

  const [
    getAccountsSegregation,
    { isLoading: isGetApplicationAccountLoading, isSuccess },
  ] = useLazyGetApplicationAccountSegregationsQuery();

  useEffect(() => {
    if (isGetApplicationAccountLoading) {
      setStartHandleTime();
    }
  }, [isGetApplicationAccountLoading, setStartHandleTime]);

  const getAccounts = useCallback(
    async (params: IOnFetchArguments) => {
      const paginationParams = getPaginationParams(params, true);
      if (applicationId) {
        const response = await getAccountsSegregation({
          applicationId,
          pageSize: paginationParams.iDisplayLength,
          page: paginationParams.iDisplayPage
            ? paginationParams.iDisplayPage + 1
            : 1,
        });

        return response.data?.account_segregations;
      }

      return null;
    },
    [applicationId, getAccountsSegregation],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId: TABLE_ID,
      data: { onFetch: getAccounts },
      pagination: {
        getDefaultPagination,
      },
    }),
    [getAccounts],
  );

  const { data, limit, setLimit, setPage, page, skip, isLoading } =
    useTableData(tableDataArgs);

  const total = data?.max_count ?? 0;
  const transactionsData = data?.results;

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount: calculateCountOfPages(total, limit),
    }),
    [skip, limit, page, setLimit, setPage, total],
  );

  const normalizeData = useMemo(() => {
    if (transactionsData) {
      const { own, segregated, omnibus } = transactionsData;

      const maxLength = Math.max(own.length, segregated.length, omnibus.length);

      return Array(maxLength)
        .fill(null)
        .map((_, index) => ({
          own: own[index] || null,
          omnibus: omnibus[index] || null,
          segregated: segregated[index] || null,
        }));
    }

    return undefined;
  }, [transactionsData]);

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: transactionsData,
    processTrigger: !isGetApplicationAccountLoading && isSuccess,
  });

  return (
    <Table
      className="Table"
      columns={COLUMNS}
      tableId={TABLE_ID}
      isLoading={isLoading}
      data={normalizeData ?? []}
      isFlexLayout
      manualSortBy
      hasPagination
      serverPaginationProps={serverPaginationProps}
    />
  );
};
