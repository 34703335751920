import * as Yup from 'yup';

import { TTokens } from '~/api/nodeBackApi/generalUsers/generalUsers.types';

export const getValidationPasswordScheme = ({
  tokens,
  isEdit,
}: {
  tokens: TTokens[] | null;
  isEdit: boolean;
}) =>
  Yup.object({
    name: Yup.mixed().test({
      name: 'token-name-validation',
      test(value, { path, createError }) {
        if (!value.trim()) {
          return createError({
            message: 'Name is required',
            path,
          });
        }

        if (
          tokens?.some(({ name }: { name: string }) => name === value) &&
          !isEdit
        ) {
          return createError({
            message: 'Token with this name already exists',
            path,
          });
        }

        return true;
      },
    }),
    value: Yup.string()
      .required('Password is required')
      .min(10, 'Password must be at least 10 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{10,})/,
        'Password must contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character',
      ),
    revalue: Yup.string()
      .required('Password is required')
      .oneOf([Yup.ref('value'), null], 'Passwords must match'),
  });

export const getValidationSmsOrEmailScheme = ({
  tokens,
}: {
  tokens: TTokens[] | null;
}) =>
  Yup.object({
    name: Yup.mixed().test({
      name: 'token-name-validation',
      test(value, { path, createError }) {
        if (!value.trim()) {
          return createError({
            message: 'Name is required',
            path,
          });
        }

        if (tokens?.some(({ name }: { name: string }) => name === value)) {
          return createError({
            message: 'Token with this name already exists',
            path,
          });
        }

        return true;
      },
    }),
  });

export const getValidationTotpScheme = ({
  tokens,
}: {
  tokens: TTokens[] | null;
}) =>
  Yup.object({
    name: Yup.mixed().test({
      test(value, { path, createError }) {
        if (!value.trim()) {
          return createError({
            message: 'Name is required',
            path,
          });
        }

        if (tokens?.some(({ name }: { name: string }) => name === value)) {
          return createError({
            message: 'Token with this name already exists',
            path,
          });
        }

        return true;
      },
    }),
    value: Yup.string()
      .required('Value is required')
      .length(10, 'Value must be 10 characters'),
  });
