import { useContext } from 'react';
import { IconButton } from 'react-ui-kit-exante';

import { useRequestStatusActionMutation } from 'api';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';

import { TRequestStatusAction } from './RequestStatusAction.types';

export const RequestStatusAction = ({
  action,
  disabled,
}: TRequestStatusAction) => {
  const { application } = useContext(ApplicationContext);
  const [sendRequestStatus, { isLoading }] = useRequestStatusActionMutation();

  const applicationId = application?.application.id;

  const handleOnSend = () => {
    if (applicationId) {
      sendRequestStatus({ applicationId, type: action });
    }
  };

  return (
    <IconButton
      iconSize={24}
      iconColor="action"
      onClick={handleOnSend}
      iconName="CircleSendIcon"
      label={
        action === 'check_accuity'
          ? 'Request Accuity Status'
          : 'Request Sum&Sub Status'
      }
      disabled={isLoading || disabled}
      data-test-id={`${action}--reques-status-button`}
    />
  );
};
