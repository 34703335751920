import { useCallback } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { IconButton } from 'react-ui-kit-exante';

import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';
import {
  FormCheckboxContainer,
  FormMultiSelectAsyncContainer,
  FormSelectContainer,
} from '~/components/Form';
import { useAutocomplete, usePickUserPermissions } from '~/hooks';

import {
  DEFAULT_PERMISSION,
  DELETE_CONFIRMATION_TEXT,
} from './AccountPermissionsList.constants';
import { isDropDownDisabled } from './AccountPermissionsList.helpers';
import {
  StyledAccountPermissionsAdd,
  StyledAccountPermissionsList,
  StyledAccountPermissionsListRow,
} from './AccountPermissionsList.styled';
import { TAccountPermissionsListProps } from './AccountPermissionsList.types';

export const AccountPermissionsList = ({
  accountStatusesOptions,
  initialPermissions,
}: TAccountPermissionsListProps) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'permissions',
  });

  // Fields don't update after submitting
  const watchedPermissions = useWatch({
    control,
    name: 'permissions',
  });

  const userPermissions = usePickUserPermissions(['User account status']);

  const hasWriteAccess = userPermissions['User account status'].write;

  const getAccountsAutocompleteFn = useAutocomplete('accounts');

  const handleAddRow = useCallback(() => append(DEFAULT_PERMISSION), [append]);

  const handleRemoveRow = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  return (
    <StyledAccountPermissionsList className="AccountPermissionsList">
      {fields.map((field, index) => {
        return (
          <StyledAccountPermissionsListRow
            className="AccountPermissionsListRow"
            key={field.id}
          >
            <FormMultiSelectAsyncContainer
              label="Account id"
              name={`permissions.${index}.accountId`}
              fetchData={getAccountsAutocompleteFn()}
              isMultiple={false}
              size="medium"
              disableCloseOnSelect={false}
              disabled={
                isDropDownDisabled(
                  initialPermissions,
                  watchedPermissions,
                  index,
                ) || !hasWriteAccess
              }
              className="AccountPermissionsListId"
            />

            <FormSelectContainer
              label="Account status"
              name={`permissions.${index}.status`}
              options={accountStatusesOptions}
              disabled={!hasWriteAccess}
              className="AccountPermissionsListStatus"
            />

            <FormCheckboxContainer
              label="Override"
              name={`permissions.${index}.overrideAccountStatus`}
              disabled={!hasWriteAccess}
              className="AccountPermissionsListOverride"
            />

            <ActionWithConfirmation
              onConfirm={() => handleRemoveRow(index)}
              title="Are you sure?"
              content={<p>{DELETE_CONFIRMATION_TEXT}</p>}
              placement="bottom"
              confirmButtonNameKey="Confirm"
              cancelButtonNameKey="Cancel"
              className="AccountPermissionsListDelete"
              disabled={!hasWriteAccess}
            >
              <IconButton
                title={DELETE_CONFIRMATION_TEXT}
                iconName="DeleteIcon"
                iconColor="radical"
                iconSize={24}
                disabled={!hasWriteAccess}
              />
            </ActionWithConfirmation>
          </StyledAccountPermissionsListRow>
        );
      })}

      <StyledAccountPermissionsAdd className="AccountPermissionsAdd">
        <IconButton
          title="Add permission"
          iconColor="action"
          iconName="AddIcon"
          onClick={handleAddRow}
        />
      </StyledAccountPermissionsAdd>
    </StyledAccountPermissionsList>
  );
};
