import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/utils/apiRequest';

export const applicationLegalEntitiesApi = createApi({
  reducerPath: 'applicationLegalEntities',
  baseQuery: baseQueryHandler,
  tagTypes: ['ApplicationLegalEntitiesApi'],
  endpoints: (builder) => ({
    getApplicationLegalEntities: builder.query<
      { legal_entities: string[] },
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/rest/applications/application-legal-entities/${id}/`,
      }),
      providesTags: ['ApplicationLegalEntitiesApi'],
    }),
  }),
});

export const { useLazyGetApplicationLegalEntitiesQuery } =
  applicationLegalEntitiesApi;
