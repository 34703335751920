import { styled } from 'react-ui-kit-exante';

export const StyledClientFormClientHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const StyledClientFormActions = styled('div')`
  display: flex;
  gap: 16px;
`;

export const StyledClientFormTitle = styled('div')`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
`;
