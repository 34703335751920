import { List } from '@mui/material';

import { styled } from '../../theme';

export const StyledList = styled(List)`
  padding: 0;
  margin: 0;
  list-style: none;
  font-family: ${({ theme }) => theme.font.main};
`;
