import { useContext } from 'react';

import { ActionWithConfirmationWithSelect } from '~/components/ConfirmationComponents/ActionWithConfirmationWithSelect';

import { PasswordManagementContext } from '../../context';
import { Forms } from '../Forms';
import { TokensList } from '../TokensList';

import {
  StyledAddIcon,
  StyledPasswordManagement,
} from './PasswordManagement.styled';

export const PasswordManagement = () => {
  const {
    onAdd,
    isOpenAddForm,
    openAddFormAnchorEl,
    handleAddFormOpen,
    handleAddFormClose,
    isDisabledBtn,
  } = useContext(PasswordManagementContext);

  return (
    <StyledPasswordManagement className="PasswordManagement">
      <ActionWithConfirmationWithSelect
        open={isOpenAddForm}
        anchorEl={openAddFormAnchorEl}
        onClose={handleAddFormClose}
        confirmText="Add"
        handleConfirm={onAdd}
        handleClose={handleAddFormClose}
        content={<Forms />}
        disabledConfirm={isDisabledBtn}
      >
        <StyledAddIcon
          className="AddIcon"
          title="Add"
          label="Add"
          iconColor="action"
          iconName="AddIcon"
          onClick={handleAddFormOpen}
          disabled={isDisabledBtn}
        />
      </ActionWithConfirmationWithSelect>
      <TokensList />
    </StyledPasswordManagement>
  );
};
