import { blockNonNativeProps } from '../../../../helpers';
import { styled } from '../../../../theme';
import { Tooltip } from '../../../Tooltip';

import { TStyledTooltip } from './DeleteConfirmationTooltip.types';

export const Container = styled('div')(() => ({
  display: 'flex',
  position: 'relative',

  '& .MuiTooltip-popper[data-popper-placement*="bottom"]': {
    margin: '100px',
  },
}));

export const Title = styled('div')(({ theme }) => ({
  display: 'flex',
  fontSize: theme.size.text.md,
  justifyContent: 'space-between',
  lineHeight: '20px',
  marginBottom: '8px',
}));

export const Buttons = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  marginTop: '16px',

  '& button': {
    fontSize: theme.size.text.md,
  },
}));

export const StyledTooltip = styled(Tooltip, {
  shouldForwardProp: blockNonNativeProps(['size']),
})<TStyledTooltip>(({ size }) => ({
  '& .MuiTooltip-tooltip': {
    marginTop: '5px !important',
    marginBottom: '5px !important',
    maxWidth: size === 'big' ? '400px' : 'unset',
  },
}));
