import { find, pullAllBy, uniqBy } from 'lodash';
import { ChangeEvent, DragEvent, useCallback, useState } from 'react';

import {
  getErrorMaxFileSizeExceedText,
  getErrorValidFileTypeText,
  getHasAcceptType,
  getMaxFileSizeExceed,
  normalizeFiles,
} from './helpers';
import type {
  TFile,
  TFileUploadHook,
  TSetFileDeleteStatusFn,
  TSetFileErrorFn,
  TSetFileSuccessFn,
} from './types';
import { DeleteStatus, UploadStatus } from './types';

const useFileUpload = ({
  accept,
  errorMaxFileSizeExceedMessage,
  disabled,
  filesInitial,
  maxFileSize,
  onDelete,
  onUpload,
  withDeleteConfirmation,
  translator,
  uploadErrorMessage,
  isMultiple,
}: TFileUploadHook) => {
  const [filesToDisplay, setFilesToDisplay] = useState<TFile[]>(
    filesInitial || [],
  );
  const [externalOpened, setExternalOpened] = useState<string | undefined>(
    undefined,
  );
  const [uploadError, setUploadError] = useState('');

  // Makes specific file succeeded and sets it an ID
  const setFileSuccess: TSetFileSuccessFn = useCallback(
    ({ name, newName, id, removable }) => {
      setFilesToDisplay((files) =>
        files.map((file) =>
          file.name !== name
            ? file
            : {
                ...file,
                uploadStatus: UploadStatus.SUCCESS,
                name: newName || name,
                id,
                removable,
              },
        ),
      );
    },
    [],
  );

  // Makes specific file errored and sets it an error
  const setFileError: TSetFileErrorFn = useCallback(({ name, error }) => {
    setFilesToDisplay((files) =>
      files.map((file) =>
        file.name !== name
          ? file
          : { ...file, uploadStatus: UploadStatus.ERROR, error },
      ),
    );
  }, []);

  // Removes the file from the list by its ID or name (if not uploaded)
  // or just changes the status if not SUCCESS
  const setFileDeleteStatus: TSetFileDeleteStatusFn = useCallback(
    ({ id, name, status }) => {
      setFilesToDisplay((files) => {
        return files.reduce((acc, curr) => {
          if ((id && curr.id === id) || curr.name === name) {
            return status === DeleteStatus.SUCCESS
              ? acc
              : [...acc, { ...curr, deleteStatus: status }];
          }

          return [...acc, curr];
        }, [] as TFile[]);
      });
    },
    [],
  );

  const handleFileUploadOrDrop = useCallback(
    (files: FileList | null) => {
      setUploadError('');

      if (!files || disabled) {
        return;
      }

      const hasAcceptType = getHasAcceptType(files, accept);

      if (!hasAcceptType) {
        const errorValidFileTypeText = getErrorValidFileTypeText(
          translator,
          accept,
          uploadErrorMessage,
        );

        setUploadError(errorValidFileTypeText);

        return;
      }

      if (typeof maxFileSize === 'number') {
        const maxFileSizeExceed = getMaxFileSizeExceed(maxFileSize, files);

        if (maxFileSizeExceed) {
          const errorMaxFileSizeExceedText = getErrorMaxFileSizeExceedText(
            maxFileSize,
            translator,
            errorMaxFileSizeExceedMessage,
          );

          setUploadError(errorMaxFileSizeExceedText);

          return;
        }
      }

      const newFiles = !isMultiple ? [] : [...filesToDisplay];
      // take only files with new names
      const filesNewByFileName = pullAllBy(
        // from files from input
        Array.from(files),
        // errored files can still be re-uploaded so include them if they exist
        newFiles.filter((f) => f.uploadStatus !== UploadStatus.ERROR),
        'name',
      );

      // display unique list of names
      setFilesToDisplay(
        uniqBy(
          [
            ...newFiles,
            // the new file is pending by default
            ...normalizeFiles(filesNewByFileName, UploadStatus.PENDING),
          ],
          'name',
        ).map((file) => ({
          ...file,
          // if the file was errored, it should become pending after re-upload
          ...(find(files, { name: file.name, uploadStatus: UploadStatus.ERROR })
            ? { uploadStatus: UploadStatus.PENDING }
            : null),
        })),
      );

      if (onUpload) {
        onUpload(filesNewByFileName, setFileSuccess, setFileError);
      }
    },
    [filesToDisplay, isMultiple],
  );

  const handleUpload = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { files } = target;

    handleFileUploadOrDrop(files);

    // this makes possible to add the same file after removal
    // eslint-disable-next-line no-param-reassign
    target.value = '';
  };

  const handleDrop = ({ dataTransfer }: DragEvent<HTMLElement>) => {
    const { files } = dataTransfer;

    handleFileUploadOrDrop(files);
  };

  const handleDelete = (id: string | undefined, name: string) => {
    if (id) {
      if (withDeleteConfirmation) {
        setFileDeleteStatus({ id, status: DeleteStatus.PENDING });
        // if handled & succeeded, ask the parent to delete the file
      } else {
        // otherwise delete promptly
        setFileDeleteStatus({ id, name, status: DeleteStatus.SUCCESS });
      }
      onDelete?.(id, setFileDeleteStatus);
    }
  };

  const stopDefaults = (event: DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const dragEvents = {
    onDragEnter: (event: DragEvent) => {
      stopDefaults(event);
    },
    onDragLeave: (event: DragEvent) => {
      stopDefaults(event);
    },
    onDragOver: stopDefaults,
    onDrop: (event: DragEvent<HTMLElement>) => {
      stopDefaults(event);

      handleDrop(event);
    },
  };

  return {
    dragEvents,
    externalOpened,
    filesToUpload: filesToDisplay,
    handleDelete,
    handleUpload,
    setExternalOpened,
    setFileError,
    setFileSuccess,
    uploadError,
  };
};

export default useFileUpload;
