import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import { baseQueryHandler } from '~/utils/apiRequest';

import {
  TBookmarkAPI,
  TCreateBookmarkPayload,
  TUpdateBookmarkPayload,
} from './bookmarks.types';

export const bookmarksApi = createApi({
  reducerPath: 'bookmarksApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['Bookmarks', 'NewBookmarks'],
  endpoints: (builder) => ({
    getBookmarks: builder.query<TBookmarkAPI[], void>({
      query: () => ({
        url: '/rest/bookmarks/bo-like-bookmarks/',
      }),
      providesTags: ['Bookmarks'],
    }),

    createBookmark: builder.mutation<
      TBookmarkAPI,
      { data: TCreateBookmarkPayload }
    >({
      query: ({ data }) => ({
        url: `/rest/bookmarks/bookmark/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Bookmark successfully created',
          });
        } catch (e) {
          Notification.error({
            // todo error message
            title: 'Bookmark creating error ',
          });
        }
      },
      invalidatesTags: ['Bookmarks'],
    }),

    updateBookmark: builder.mutation<
      TBookmarkAPI,
      { id: number; data: TUpdateBookmarkPayload }
    >({
      query: ({ id, data }) => ({
        url: `/rest/bookmarks/bookmark/${id}/`,
        method: 'PATCH',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Bookmark successfully updated',
          });
        } catch (e) {
          Notification.error({
            // todo error message
            title: 'Bookmark updating error ',
          });
        }
      },
      invalidatesTags: ['Bookmarks'],
    }),

    deleteBookmark: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/rest/bookmarks/bookmark/${id}/`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Bookmark successfully deleted',
          });
        } catch (e) {
          Notification.error({
            title: 'Bookmark deleting error ',
          });
        }
      },
      invalidatesTags: ['Bookmarks'],
    }),
  }),
});

export const {
  useGetBookmarksQuery,
  useDeleteBookmarkMutation,
  useLazyGetBookmarksQuery,
  useCreateBookmarkMutation,
  useUpdateBookmarkMutation,
} = bookmarksApi;
