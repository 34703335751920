import { styled } from 'react-ui-kit-exante';

import { SectionBody } from '~/pages/ApplicationEntry/components/EnrtyScreenStructure/SectionBody';

export const StyledCreatePersonForm = styled('div')`
  margin-bottom: 24px;
`;

export const StyledCreatePersonActions = styled('div')`
  display: flex;
  gap: 16px;
`;

export const StyledSectionBody = styled(SectionBody)`
  display: grid;
  gap: 8px;
  justify-content: space-between;
  grid-template-columns: 49% 49%;
`;

export const StyledCreatePersonTitle = styled('div')`
  width: 49%;
  display: flex;
  justify-content: space-between;
`;
