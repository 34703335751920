import { createElement } from 'react';
import { IColumn, ISortBy } from 'react-ui-kit-exante';

import { TAuditLog } from '~/api/auditLogs/auditLogs.types';
import { TPaginationResponse } from '~/api/nodeBackApi/accounts/accounts.types';
import { TooltipWrapper } from '~/components/TooltipWrapper';
import { useAutocomplete } from '~/hooks/useAutocomplete';
import { TAdditionalFiltersHandlers } from '~/types/table';
import { createStringDateUTCWithoutTimezone } from '~/utils/dates/createStringDateWithoutTimezone';
import { threeDaysAgo, today } from '~/utils/dates/variables';
import { getPredefinedDateRanges } from '~/utils/getPredefinedDateRanges';
import { getTableId } from '~/utils/table';

// default displayed columns
export const DISPLAYED_COLUMNS_KEYS = [
  'account',
  'change',
  'description',
  'ip',
  'symbol',
  'time',
  'type',
  'user',
  'who',
];

export function useColumns({
  onFilter,
  onRemove,
}: TAdditionalFiltersHandlers): IColumn<TAuditLog>[] {
  const getAccountsAutocompleteFn = useAutocomplete('accounts');
  const getUsersAutocompleteFn = useAutocomplete('users');
  const getWhoAutocompleteFn = useAutocomplete('users');
  const getSymbolsAutocompleteFn = useAutocomplete('symbols');

  return [
    {
      Header: 'Time',
      accessor: 'time',
      type: 'dateTimeRange',
      onFilter,
      onToday: onFilter,
      onYesterday: onFilter,
      onLastWeek: onFilter,
      onRemove,
      formatting: 'dateTimeUTC',
      predefinedDateRanges: getPredefinedDateRanges(onFilter),
    },
    {
      Header: 'Change',
      accessor: 'change',
      width: 300,
      onFilter,
      onRemove,
      tooltip: {
        type: 'custom',
        options: {
          Title(value: unknown) {
            return createElement(TooltipWrapper, { value });
          },
        },
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
      onFilter,
      onRemove,
      tooltip: 'custom',
    },
    {
      Header: 'Account',
      accessor: 'account',
      type: 'asyncSelect',
      fetchDataOption: getAccountsAutocompleteFn(),
      onFilter,
      onRemove,
    },
    {
      Header: 'User',
      accessor: 'user',
      type: 'asyncSelect',
      fetchDataOption: getUsersAutocompleteFn(),
      onFilter,
      onRemove,
    },
    {
      Header: 'Who',
      accessor: 'who',
      type: 'asyncSelect',
      fetchDataOption: getWhoAutocompleteFn(),
      onFilter,
      onRemove,
    },
    {
      Header: 'IP',
      accessor: 'ip',
      disableFilters: true,
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
      type: 'asyncSelect',
      fetchDataOption: getSymbolsAutocompleteFn(),
      onFilter,
      onRemove,
    },
    {
      Header: 'Description',
      accessor: 'description',
      onFilter,
      onRemove,
      disableSortBy: true,
      tooltip: 'custom',
    },
  ];
}

export const EMPTY_AUDIT_LOGS = {
  limit: 0,
  page: 0,
  logs: [],
  total: 0,
};

export const PARAMS_FOR_REQUEST = new Set([
  'account',
  'change',
  'description',
  'symbol',
  'time',
  'type',
  'user',
  'who',
]);

export const PARAMS_FOR_EQUAL = new Set(['symbol', 'user', 'who']);

export function getDefaultSorting(): ISortBy[] {
  return [{ id: 'time', desc: true }];
}

export const DEFAULT_SORTING = getDefaultSorting();

export function getDefaultFilters() {
  return {
    time: [
      createStringDateUTCWithoutTimezone(threeDaysAgo),
      createStringDateUTCWithoutTimezone(today),
    ],
  };
}

export const DEFAULT_PAGINATION_RESPONSE: TPaginationResponse = { total: 0 };
export const TABLE_ID = getTableId('activity-audit-logs');
