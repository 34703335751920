import { styled } from 'react-ui-kit-exante';

export const StyledFormCheckboxMessage = styled('div')`
  color: ${({ theme }) => theme.color.typo.radical};
  font-size: 11px;
`;

export const StyledList = styled('ul')`
  padding: 0px;
  margin: 24px 0;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(4, 1fr);
`;

export const StyledItem = styled('li')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  gap: 8px;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  line-height: 0;
  border: ${({ theme }) => `1px solid ${theme?.color?.dropdown?.border}`};
`;

export const StyledName = styled('span')`
  margin-right: auto;
  font-weight: 500;
  font-size: 15px;
  color: ${({ theme }) => theme?.color?.button?.secondary?.typo?.default};
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1;
  white-space: nowrap;
`;
