export const RootTabStructureKey = 'UIApplicationTabsV2'; // only for application tabs
export const RootTabBOStructureKey = 'UITabsV2'; // only for BO tabs
export const TabStructureKey = 'UITabsV2'; // only for sub tabs
export const StaticSectionKey = 'UISectionV2'; // only for static sections on entry screen
export const DynamicSectionKey = 'UITagGroupSectionV2'; // only for dynamic sections on entry screen
export const TableSectionKey = 'UITableSectionV2'; // only for dynamic sections on entry screen

export enum EApplicationActionsType {
  Approve = 'approve',
  Unapprove = 'unapprove',
  ToOnboarding = 'to_onboarding',
  ToPending = 'to_pending',
  FromPending = 'from_pending',
  FromPendingToRegistrant = 'from_pending_to_registrant',
  Delete = 'delete',
  Block = 'block',
  Unblock = 'unblock',
  FromPendingToQualified = 'from_pending_to_qualified',
}

export const HideEmptyTagsSections = [
  'other',
  'corporate_categorization',
  'individual_compliance',
  'generic_compliance',
  'product_compliance',
];
