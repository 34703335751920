import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';

import usePrevious from './usePrevious';

type CallbackTriggerHandle<T> = {
  cb: () => void;
  dataTrigger: T;
  processTrigger?: boolean;
};

export const useCallbackTriggerHandle = <T>({
  cb,
  dataTrigger,
  processTrigger = true,
}: CallbackTriggerHandle<T>) => {
  const prevData = usePrevious(dataTrigger);

  useEffect(() => {
    if (processTrigger && dataTrigger && !isEqual(prevData, dataTrigger)) {
      cb();
    }
  }, [dataTrigger, cb, prevData, processTrigger]);
};
