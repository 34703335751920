import { styled } from 'react-ui-kit-exante';

export const StyledColumns = styled('div')<{ columnsWidth?: string }>`
  display: grid;
  grid-template-columns: ${({ columnsWidth }) => columnsWidth || '1fr 1fr'};

  ${({ theme }) => theme.breakpoints.down('lg')} {
    grid-template-columns: 1fr;
  }
`;

export const StyledColumn = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &:not(:first-child) {
    border-left: 1px solid ${({ theme }) => theme.color.line.primary};
  }
`;
