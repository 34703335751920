import { styled } from 'react-ui-kit-exante';

export const StyledOnboardingLogList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
`;

export const StyledClientFormField = styled('div')`
  min-width: 270px;

  .MuiFormControlLabel-root {
    display: flex;
    justify-content: right;
  }
`;
