import { IOption } from '~/types/form/options';

export const accountAccessTypes = [
  'blocked',
  'read_only',
  'close_only',
  'full_access',
] as const;

export type TAccountsResponse = {
  total?: number;
  canceled?: boolean;
  nextSkip: number | null;
  options: IOption[];
};

export type TAccountsParams = {
  skip: number | null;
  search: string;
  limit?: number;
  archived?: boolean;
};

export type TOwnerShipType = 'Own' | 'Omnibus' | 'Segregated';

export type TAccountRegularCommissionsTableRow = Partial<{
  commissionId: number;
  accountId: string;
  active: boolean;
  currency: string;
  name: string;
  rate: string;
  frequency: string;
}>;

export type TAccountRegularCommissionsTable =
  TAccountRegularCommissionsTableRow[];

export type TRebateAccount = Partial<{
  percent: string;
  id: string;
}>;

export type TRebateAccountsData = TRebateAccount[];

export type TOriginatorIds = {
  default: string | null;
  overrides?: Record<string, string>;
};

export type TAccount = {
  id: string;
  accountOwner?: string | null;
  created: string;
  marginTradingAllowed?: boolean;
  internalComment: string | null;
  type: string;
  subOwnerDescription?: string | null;
  status: string;
  ownershipType: TOwnerShipType;
  iban: string | null;
  comment: string | null;
  clientId: string;
  autoLiquidationAllowed: boolean;
  archivedDate: string;
  archived: boolean;
  accountPurpose: string | null;
  calcInterestsPositiveFlag: boolean;
  calcInterestsNegativeFlag: boolean;
  calcOvernightsFlag: boolean;
  useFreemoneyPremium: boolean;
  useExcessMarginFee: boolean;
  availableCurrencies: string[] | null;
  useIntradayMargin?: boolean;
  sumOrderMargin?: boolean;
  strictOrderMarginPolicy?: boolean;
  preorderMarginCheck?: boolean;
  preorderCommissionCheck?: boolean;
  navViolationCheck?: boolean;
  concentrationMargin?: boolean;
  useMarginSlippage?: boolean;
  skipRevertPositionCheck?: boolean;
  skipWashTradeCheck?: boolean;
  usePortfolioMargin?: boolean;
  marginThresholdOverride?: string | null;
  takeMinimumFee?: boolean;
  commissionGroupId?: number | null;
  calcCommissionRebate?: boolean;
  interestsSetId?: number | null;
  calcInterestRebate?: boolean;
  overnightsSetId?: number | null;
  interestUseNegativePnl?: boolean;
  interestUsePositivePnl?: boolean;
  takeCashConversionCommission?: boolean;
  cashConversionLimit?: string;
  convertCashInto?: string | null;
  permissionsSetId?: string | null;
  routingTags?: string[] | null;
  mirroringLevel?: number;
  autoDebit?: boolean;
  autoDebitAccountId?: string | null;
  excludeFromGlobalMetrics?: boolean;
  clientName?: string | null;
  clientCode?: string | null;
  totalOrderMargin: number;
  totalFreeMoney: number;
  totalCash: number;
  totalBlockedMargin: number;
  sharePrice: number;
  requiredMargin: number;
  netAssetValue: number;
  disableForcedConversion: boolean;
  rates?: Record<string, string>;
  regularCommissions?: TAccountRegularCommissionsTable;
  rebateAccounts?: TRebateAccountsData;
  marginUtilization?: number;
  counterpartyAccountId: string | null;
  originatorIds: TOriginatorIds | null;
  branding?: string | null;
  clientLegalEntity: string | null;
  permissionGroupId: number | null;
};

export type TPaginationResponse = {
  total: number;
};

export type TSummary = {
  nav: number | null;
};

export type TAccountsState = {
  accounts: TAccount[];
  pagination: TPaginationResponse;
  summary?: TSummary;
};

export type TAccountAccessType = (typeof accountAccessTypes)[number];
