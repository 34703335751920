import { FC } from 'react';

import { formatPhoneNumberWithFlag } from '~/components/phoneWithFlag/phoneWithFlag';

import {
  StyledContactInfoCell,
  StyledContactInfoCellName,
  StyledContactInfoCellPhone,
} from './ContactInfoCell.styled';
import { TContactInfoCellProps } from './ContactInfoCell.types';

export const ContactInfoCell: FC<TContactInfoCellProps> = ({
  name,
  email,
  phone,
}) => {
  return (
    <StyledContactInfoCell className="ContactInfoCell">
      {name && (
        <StyledContactInfoCellName className="ContactInfoCell">
          {name}
        </StyledContactInfoCellName>
      )}
      {email && <p>{email}</p>}
      {phone && (
        <StyledContactInfoCellPhone className="ContactInfoCellPhone">
          {formatPhoneNumberWithFlag(String(phone))}
        </StyledContactInfoCellPhone>
      )}
    </StyledContactInfoCell>
  );
};
