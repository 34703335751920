import { TServiceResponse } from '~/types/api';

const defaultArguments: TServiceResponse<never> = {
  data: undefined,
  error: null,
  messages: undefined,
};

export function createServiceResponse<T>(
  args: TServiceResponse<T> = defaultArguments,
): TServiceResponse<T> {
  const { data, error, messages } = args;

  return { data, error, messages };
}
