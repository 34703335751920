import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import { TUpload } from '~/api/withdrawals/withdrawals.types';
import { ALL_LEGAL_ENTITY } from '~/constants/common';
import { TDefaultPaginationResponse, TDefaultParams } from '~/types/api';
import { TTableFilters } from '~/types/table';
import { baseQueryHandler } from '~/utils/apiRequest';
import { getFormData } from '~/utils/formData';

import {
  TApplicationRequest,
  TDeleteRequestBody,
  TEditRequestBody,
  TExportCsvParams,
  TExportCsvResponse,
  TRecordTypes,
  TRequest,
  TSendRequestBody,
  TUploadRequestBody,
} from './requests.types';

export const requestsApi = createApi({
  reducerPath: 'requestsApi',
  baseQuery: baseQueryHandler,
  tagTypes: [
    'Requests',
    'RequestsTableFilters',
    'RequestsExportCsv',
    'DeleteRequestRecord',
    'Request',
    'ApplicationRequests',
  ],
  endpoints: (builder) => ({
    getRequests: builder.query<
      TDefaultPaginationResponse<TRequest>,
      TDefaultParams
    >({
      query: (params) => ({
        url: '/rest/monitoring/record-table/',
        method: 'POST',
        data: {
          ...params,
        },
      }),
      providesTags: ['Requests'],
    }),
    getRequestsTableFilters: builder.query<TTableFilters, void>({
      query: () => ({
        url: '/rest/monitoring/record-filters/?table_name=monitoring',
      }),
      providesTags: ['RequestsTableFilters'],
    }),
    requestsExportCsv: builder.mutation<TExportCsvResponse, TExportCsvParams>({
      query: ({ preparedFilters, selectedColumns }) => ({
        url: '/rest/monitoring/record-table/report/',
        method: 'POST',
        data: {
          ...preparedFilters,
          columns: selectedColumns,
        },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          Notification.success({
            title: `Records were exported to CSV. ${data.message}`,
          });
        } catch (e) {
          Notification.error({
            title: `Records were not exported to CSV.`,
          });
        }
      },
    }),
    deleteRequestRecord: builder.mutation({
      query: (id) => ({
        url: `/rest/monitoring/record/${id}/`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: `Record was succesfully deleted.`,
          });
        } catch (e) {
          Notification.error({
            title: `Record was not deleted.`,
          });
        }
      },
      invalidatesTags: ['ApplicationRequests'],
    }),

    getRequest: builder.query<TRequest, { id: string }>({
      query: ({ id }) => ({
        url: `/rest/monitoring/record/${id}/`,
      }),
      providesTags: ['Request'],
    }),

    getApplicationRequests: builder.query<
      TApplicationRequest[],
      { applicationId: number; legalEntity?: string }
    >({
      query: ({ applicationId, legalEntity }) => ({
        url: `/rest/monitoring/record/`,
        params: {
          application: applicationId,
          le: legalEntity || ALL_LEGAL_ENTITY,
        },
      }),
      providesTags: ['ApplicationRequests'],
    }),

    editRequest: builder.mutation<TRequest, TEditRequestBody>({
      query: ({ id, data }) => ({
        url: `/rest/monitoring/record/${id}/`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Request successfully edited',
          });
        } catch (e) {
          Notification.error({
            title: `Request was not successfully edited`,
          });
        }
      },
      invalidatesTags: ['Request'],
    }),

    createRequest: builder.mutation<TRequest, TEditRequestBody>({
      query: ({ data }) => ({
        url: `/rest/monitoring/record/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Request successfully created',
          });
        } catch (e) {
          Notification.error({
            title: `Request was not successfully created`,
          });
        }
      },
    }),

    sendRequest: builder.mutation<void, TSendRequestBody>({
      query: ({ id }) => ({
        url: `/rest/monitoring/record/${id}/resend_email/`,
        method: 'POST',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Request successfully sent',
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),

    getRecordTypes: builder.query<TRecordTypes[], void>({
      query: () => ({
        url: `/rest/monitoring/record-type/`,
      }),
    }),

    getRecordState: builder.query<
      { record_types: { id: string; visible_title: string }[] }[],
      void
    >({
      query: () => ({
        url: `/rest/monitoring/record-state/`,
      }),
    }),

    uploadMonitoringFiles: builder.mutation<TUpload, TUploadRequestBody>({
      query: ({ id, files }) => {
        const formData = getFormData([['record', String(id)]]);

        files?.map((file: File, index: number) =>
          formData.data.append(`file[${index}]`, file),
        );

        return {
          url: `/rest/monitoring/recordfile/`,
          method: 'POST',
          ...formData,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'File successfully uploaded',
          });
        } catch (e) {
          Notification.error({
            title: 'Failed to upload file',
          });
        }
      },
      invalidatesTags: ['Request'],
    }),

    deleteMonitoringFile: builder.mutation<void, TDeleteRequestBody>({
      query: ({ fileId }) => ({
        url: `/rest/monitoring/recordfile/${fileId}/`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'File successfully deleted',
          });
        } catch (e) {
          Notification.error({
            title: 'Failed to delete file',
          });
        }
      },
    }),

    downloadMonitoringFile: builder.query<{ url?: string }, { id: string }>({
      query: ({ id }) => ({
        url: `/rest/monitoring/getfile/${id}/`,
      }),
    }),
  }),
});
export const {
  useLazyGetRequestsQuery,
  useGetRequestsTableFiltersQuery,
  useRequestsExportCsvMutation,
  useDeleteRequestRecordMutation,
  useGetRequestQuery,
  useLazyGetRequestQuery,
  useGetRecordTypesQuery,
  useEditRequestMutation,
  useGetRecordStateQuery,
  useSendRequestMutation,
  useUploadMonitoringFilesMutation,
  useDeleteMonitoringFileMutation,
  useLazyDownloadMonitoringFileQuery,
  useGetApplicationRequestsQuery,
  useLazyGetApplicationRequestsQuery,
  useCreateRequestMutation,
} = requestsApi;
