import { styled, Tabs, Tab, ToggleButtonGroup } from 'react-ui-kit-exante';

export const StyledTabs = styled(Tabs)`
  margin: 24px 0 0 24px;
`;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  margin: 24px 24px 0;
`;
// need to see the solution with ui-kit element typing
// eslint-disable-next-line prettier/prettier
export const StyledTab = styled(Tab)({ // NOSONAR
  display: 'flex', // NOSONAR
  flexDirection: 'row', // NOSONAR
  alignItems: 'center', // NOSONAR
  fontSize: '14px', // NOSONAR
}) as typeof Tab; // NOSONAR
