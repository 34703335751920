import { useCallback, useContext } from 'react';

import { useLazyGetLinkedApplicationChoicesQuery } from '~/api';
import { FormMultiSelectAsyncContainer } from '~/components/Form/FormMultiSelectAsyncContainer';
import { ValueRow } from '~/components/ValueRow';

import { StyledSectionBody } from '../../../../components/EnrtyScreenStructure/SectionBody/SectionBody.styled';
import { ApplicationContext } from '../../../../contexts/ApplicationContext';

const defaultAutocompleteState = {
  total: 0,
  fetchData: null,
  canceled: true,
};

// todo autocomplete change!!
export const LinkedApplication = () => {
  const { application } = useContext(ApplicationContext);

  const [getLinkedApplicationChoices] =
    useLazyGetLinkedApplicationChoicesQuery();

  const applicationId = application?.application?.id;

  const fetchOptionsData = useCallback(
    (skip = 0) => {
      return async (search: string) => {
        if (applicationId && search.length > 2) {
          const response = await getLinkedApplicationChoices({
            applicationId,
            query: search,
          });

          const options = response?.data?.results?.map(
            ({ id, full_name, email }) => {
              return {
                value: String(id),
                label: `${full_name} ${email}`,
              };
            },
          );

          return {
            ...defaultAutocompleteState,
            total: options?.length || 0,
            options: options || [],
            fetchData: fetchOptionsData(skip),
          };
        }

        return {
          ...defaultAutocompleteState,
          options: [],
          fetchData: fetchOptionsData(0),
        };
      };
    },
    [applicationId],
  );

  return (
    <StyledSectionBody className="SectionBody">
      <ValueRow
        label="Select Application"
        valueNode={
          <FormMultiSelectAsyncContainer
            name="linked_applications"
            fetchData={fetchOptionsData()}
            isMultiple
            size="small"
            placeholder="Please enter minimum 3 characters"
            sx={{ width: 270 }}
            disableCloseOnSelect
          />
        }
      />
    </StyledSectionBody>
  );
};
