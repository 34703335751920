import { useGetCurrentUserAccessRightsQuery } from '~/api';
import {
  TListAccessTypes,
  TPossibleAccessRights,
} from '~/api/nodeBackApi/accessRights/accessRights.types';

export function usePickUserPermissions(
  permissions: TListAccessTypes[],
): Record<TListAccessTypes, TPossibleAccessRights> {
  const { data: userPermissions } = useGetCurrentUserAccessRightsQuery();

  return permissions.reduce((acc, permission) => {
    return {
      ...acc,
      [permission]: {
        read: !!userPermissions?.read[permission],
        write: !!userPermissions?.write[permission],
      },
    };
  }, {} as Record<TListAccessTypes, TPossibleAccessRights>);
}
