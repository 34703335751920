import { ISortBy } from 'react-ui-kit-exante';

import { getTableId } from '~/utils/table';

import type { TPaginationResponse } from './ComputedUsers.types';

export const DEFAULT_PAGINATION_RESPONSE: TPaginationResponse = { total: 0 };

export function getDefaultSorting(): ISortBy[] {
  return [{ id: 'creationDate', desc: true }];
}

export const DEFAULT_SORTING = getDefaultSorting();

export const EXCLUDED_PARAMS = ['bookmark'];

export function calculateCountOfPages(total: number, limit: number) {
  return Math.ceil(total / limit);
}

export const DISPLAYED_COLUMN_KEYS = ['username', 'id', 'creationDate'];

export const CREATION_DATE_FIELDS = ['startCreationDate', 'endCreationDate'];

export const LAST_LOGIN_DATE_FIELDS = [
  'startLastLoginTime',
  'endLastLoginTime',
];

export const BIRTH_DATE_FIELDS = ['startBirthDate', 'endBirthDate'];

export const dateFieldsFormat: Record<string, string> = {
  lastLoginTime: 'dateTimeUTC',
  creationDate: 'dateUTC',
};

export const columnFormatFields = [
  'accounts',
  'client_id',
  'clients_legal_entity',
];

export const jsonFormatFields = ['originatorIds', 'extraData', 'notifications'];

export const PAGE_SIZES = [20, 50, 100];
export const TABLE_ID = getTableId('users');
