import { styled, IconButton } from 'react-ui-kit-exante';

export const StyledPasswordManagement = styled('div')`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const StyledAddIcon = styled(IconButton)`
  margin-bottom: 24px;
`;
