import { mixed, object } from 'yup';

import { createArraysValidation } from './PermissionsFormContainer.helpers';

export const getValidationScheme = () =>
  object({
    permissions: mixed().test({
      name: 'user-clients-validation',
      test: createArraysValidation('accountId'),
    }),
  });
