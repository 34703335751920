import { useContext } from 'react';

import { FormInputContainer, FormSelectContainer } from '~/components/Form';

import {
  PasswordManagementType,
  PasswordManagementTypeOptions,
} from '../../PasswordManagement.constants';
import { PasswordManagementContext } from '../../context';

import { StyledFormsWrapper } from './Forms.styled';

export const Forms = () => {
  const { formType, editData } = useContext(PasswordManagementContext);

  return (
    <StyledFormsWrapper className="FormsWrapper">
      <FormSelectContainer
        label="Type"
        name="type"
        options={PasswordManagementTypeOptions}
        fullWidth
        disabled={!!editData}
      />
      {formType === PasswordManagementType.Password && (
        <>
          <FormInputContainer name="name" label="Name" fullWidth />
          <FormInputContainer label="Password" name="value" type="password" />
          <FormInputContainer
            label="Re-type password"
            name="revalue"
            type="password"
          />
        </>
      )}
      {(formType === PasswordManagementType.SMS ||
        formType === PasswordManagementType.Email) && (
        <FormInputContainer name="name" label="Name" fullWidth />
      )}
      {formType === PasswordManagementType.Totp && (
        <>
          <FormInputContainer name="name" label="Name" fullWidth />
          <FormInputContainer label="Value" name="value" />
        </>
      )}
    </StyledFormsWrapper>
  );
};
