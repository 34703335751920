import cn from 'classnames';
import { FC, useState } from 'react';

import { DefaultThemeProvider } from '../../theme';

import { getOpenedItemId } from './Menu.helpers';
import { StyledList } from './Menu.styled';
import { IMenuItem, IMenuProps } from './Menu.types';
import { MenuItem } from './MenuItem';

export const Menu: FC<IMenuProps> = ({
  beforeList,
  items,
  location,
  className = '',
}) => {
  const [openedItemId, setOpenedItemId] = useState<IMenuItem['id'] | null>(
    getOpenedItemId(items, location.pathname),
  );

  const openOrToggle = (id: string | number) => {
    return setOpenedItemId(openedItemId === id ? null : id);
  };

  const open = (id: string | number) => setOpenedItemId(id);

  const renderItems = (): JSX.Element[] => {
    return items.map<JSX.Element>((item) => {
      const { id } = item;

      return (
        <MenuItem
          className={className}
          {...item}
          level={0}
          opened={openedItemId === id}
          location={location}
          onClick={() => openOrToggle(id)}
          onActivate={() => open(id)}
          key={id}
        />
      );
    });
  };

  return (
    <DefaultThemeProvider>
      <nav className={cn('MenuNav', className)} data-test-id="menu-item-nav">
        {beforeList}
        {!!items.length && (
          <StyledList as="ul" className="MenuList" data-test-id="menu-list">
            {renderItems()}
          </StyledList>
        )}
      </nav>
    </DefaultThemeProvider>
  );
};
