import cn from 'classnames';
import { isArray } from 'lodash';
import React, { ChangeEvent } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { DEFAULT_LOCALE } from '../../../../../consts';
import { getDateLocale } from '../../../../../helpers/getDateLocale';
import { DEFAULT_DATE_FORMAT } from '../../constants';
import { TDateCond } from '../types';

import { CalendarHeader, ICalendarHeaderProps } from './CalendarHeader';
import {
  ActionButton,
  CalendarActions,
  CalendarFooter,
  CalendarTimePickerWrapper,
  ClearActionButton,
  StyledPopperWrapper,
} from './DatePicker.styled';
import { DatePickerContainer } from './DatePickerContainer';
import { TimePicker } from './TimePicker';
import { setTime } from './TimePicker/helpers';
import { clampDateSafe } from './helpers';
import { castToDate } from './helpers/castToDate';
import { IDatePickerProps, THandleDateChange } from './types';

const defaultCalendarParams: Partial<ReactDatePickerProps> = {
  disabledKeyboardNavigation: true,
  calendarContainer: DatePickerContainer,
  popperContainer: StyledPopperWrapper,
  popperModifiers: [
    {
      name: 'computeStyles',
      options: {
        // this option disables CSS transforms which
        // breaks proper positioning of inner poppers
        gpuAcceleration: false,
      },
    },
  ],
  // disable native time input rendering,
  // all time input is placed in the footer
  // eslint-disable-next-line react/jsx-no-useless-fragment
  customTimeInput: <></>,
  timeInputLabel: undefined,
};

export const DatePicker = <WithRange extends boolean | undefined = undefined>({
  locale = DEFAULT_LOCALE,
  dateFormat = DEFAULT_DATE_FORMAT,
  onChange = () => undefined,
  selected,
  monthsShown,
  showTodayButton,
  showClearButton,
  todayButtonText = 'Today',
  clearButtonText = 'Clear',
  showTimeInput,
  selectsRange,
  minDate,
  maxDate,
  startDate,
  endDate,
  onToday,
  onClear,
  timePickerProps = {},
  wrapperClassName,
  ...rest
}: IDatePickerProps<WithRange>) => {
  const timePickerDefaults = timePickerProps?.defaults;
  const localeSafe =
    typeof locale === 'string' ? getDateLocale(locale) : locale;

  const selectedSafe = castToDate(selected, dateFormat, timePickerDefaults);
  const startDateSafe = castToDate(startDate, dateFormat, timePickerDefaults);
  const endDateSafe = castToDate(endDate, dateFormat, timePickerDefaults);
  const minDateSafe = castToDate(minDate, dateFormat, timePickerDefaults);
  const maxDateSafe = castToDate(maxDate, dateFormat, timePickerDefaults);

  const handleToday = (e: React.MouseEvent<HTMLButtonElement>) => {
    const today = clampDateSafe(new Date(), minDateSafe, maxDateSafe);
    onChange(
      selectsRange
        ? ([today, null] as TDateCond<WithRange>)
        : (today as TDateCond<WithRange>),
      e,
    );
    onToday?.();
  };

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    onChange(selectsRange ? ([null, null] as any) : null, e);
    onClear?.();
  };

  const handleChange: THandleDateChange<WithRange> = (value, evt) => {
    let valueWithTime = value as TDateCond<WithRange>;

    // on first date change (when selected is undefined),
    // apply default time properties to the change
    // NOTE: default time applies to both range values as well
    if (!selectedSafe) {
      valueWithTime = isArray(value)
        ? (value.map(
            (date) => date && setTime(date, timePickerDefaults || {}),
          ) as TDateCond<WithRange>)
        : value &&
          (setTime(value, timePickerDefaults || {}) as TDateCond<WithRange>);
    }

    const valueClamped = isArray(valueWithTime)
      ? (valueWithTime.map(
          (date) => date && clampDateSafe(date, minDateSafe, maxDateSafe),
        ) as [Date, Date])
      : valueWithTime && clampDateSafe(valueWithTime, minDateSafe, maxDateSafe);

    onChange(valueClamped as TDateCond<WithRange>, evt);
  };

  const handleTimeStartChange = (
    date: Date | null,
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange([date, endDateSafe] as TDateCond<WithRange>, evt);
  };

  const handleTimeEndChange = (
    date: Date | null,
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange([startDateSafe, date] as TDateCond<WithRange>, evt);
  };

  const todayButton = showTodayButton && (
    <ActionButton onClick={handleToday} disableRipple>
      {todayButtonText}
    </ActionButton>
  );

  const clearButton = showClearButton && (
    <ClearActionButton onClick={handleClear} disableRipple>
      {clearButtonText}
    </ClearActionButton>
  );

  const actions = (todayButton || clearButton) && (
    <CalendarActions>
      {todayButton}
      {clearButton}
    </CalendarActions>
  );

  const timeInput = showTimeInput && (
    <CalendarTimePickerWrapper
      fullWidth={monthsShown ? monthsShown > 1 : undefined}
    >
      {selectsRange ? (
        <>
          <TimePicker
            selected={startDateSafe}
            minDate={minDateSafe}
            maxDate={maxDateSafe}
            onChange={handleTimeStartChange}
            // defaults are applied to all pickers in the range
            defaults={timePickerDefaults}
          />
          <TimePicker
            selected={endDateSafe}
            minDate={minDateSafe}
            maxDate={maxDateSafe}
            onChange={handleTimeEndChange}
            // defaults are applied to all pickers in the range
            defaults={timePickerDefaults}
          />
        </>
      ) : (
        <TimePicker<WithRange>
          selected={selectedSafe}
          minDate={minDateSafe}
          maxDate={maxDateSafe}
          onChange={handleChange}
          defaults={timePickerDefaults}
        />
      )}
    </CalendarTimePickerWrapper>
  );

  const renderCustomHeader = (props: ICalendarHeaderProps) => (
    <CalendarHeader
      {...props}
      monthsShown={monthsShown || 1}
      dateFormat={dateFormat}
      locale={localeSafe}
      startDate={startDateSafe}
      endDate={endDateSafe}
      minDate={minDateSafe}
      maxDate={maxDateSafe}
    />
  );

  const customFooter = (actions || timeInput) && (
    <CalendarFooter>
      {timeInput}
      {actions}
    </CalendarFooter>
  );

  return (
    // extra div wraps base ReactDatepicker component
    // and its contents occasionally rendered next to it
    <div className={cn('DatePickerWrapper', wrapperClassName)}>
      <ReactDatePicker
        {...defaultCalendarParams}
        locale={localeSafe}
        selected={selectedSafe}
        onChange={handleChange}
        renderCustomHeader={renderCustomHeader}
        dateFormat={dateFormat}
        monthsShown={monthsShown}
        selectsRange={selectsRange}
        startDate={startDateSafe}
        endDate={endDateSafe}
        minDate={minDateSafe}
        maxDate={maxDateSafe}
        // this makes time picker and input synchronized,
        // see `ReactDatePicker.setSelected`
        showTimeInput={showTimeInput}
        {...rest}
      >
        {customFooter}
      </ReactDatePicker>
    </div>
  );
};
