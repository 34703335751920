import {
  MouseEvent,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Modal, useModal } from 'react-ui-kit-exante';

import { TTabComponentProps } from '~/types/TTabComponentProps';
import { TLocationState } from '~/types/location';

import { TabsContext } from '../../contexts/TabsContext';
import { TabEntry } from '../TabEntry';

import { StyledTabs, StyledTab, StyledToggleButtonGroup } from './Tabs.styled';

export const Tabs = () => {
  const navigate = useNavigate();
  const modal = useModal();

  const { hash, pathname, state } = useLocation();

  const [nextUrl, setNextUrl] = useState('');

  const { requestParams, previousPath } = (state as TLocationState) || {};
  // fix for https://jira.exante.eu/browse/CRM-3792
  const locationState = {
    state: {
      previousPath,
      requestParams,
    },
  };

  const { tabs, currentTab, subTabs, currentTabIsDirty, setCurrentTabIsDirty } =
    useContext(TabsContext);

  const onTabClick = (url: string, event?: MouseEvent) => {
    if (currentTabIsDirty) {
      modal.onOpen();
      event?.stopPropagation();
      event?.preventDefault();
      setNextUrl(url);
    }
  };

  // leave tab confirmation modal
  const confirmNewTab = () => {
    if (nextUrl) {
      setCurrentTabIsDirty(false);
      modal.onClose();
      navigate(nextUrl, locationState);
    }
  };

  const onSubTabChange = (value: string | string[]) => {
    const newTab = subTabs?.find((item) => item.name === value);

    if (newTab?.url) {
      const subTabUrl = `${pathname}#${newTab?.url}`;
      if (currentTabIsDirty) {
        onTabClick(subTabUrl);
      } else {
        navigate(subTabUrl, locationState);
      }
    }
  };

  const tabsList: ReactNode[] = useMemo<ReactNode[]>(() => {
    return tabs.reduce<ReactNode[]>(
      (tabComponents, { title, url, disabled }) => {
        tabComponents.push(
          <StyledTab<TTabComponentProps<typeof Link>>
            className="Tab"
            label={title}
            key={title}
            onClick={(e) => onTabClick(url, e)}
            component={Link}
            to={url}
            disabled={disabled}
          />,
        );

        return tabComponents;
      },
      [],
    );
  }, [tabs]);

  useEffect(() => {
    const enabledTabs = tabs.filter((i) => !i.disabled);
    const firstTab = enabledTabs?.[0];
    const firstSubTab = tabs[0]?.subTabs?.[0];
    if (tabs.length && currentTab?.number === -1) {
      if (firstSubTab) {
        navigate(`${firstTab?.url}#${firstSubTab?.url}`, locationState);
      }
      navigate(firstTab?.url, locationState);
    }
  }, [tabs, currentTab]);

  const tabValue = currentTab?.number || 0;

  const subTabsOptions =
    subTabs?.map((subTab) => {
      return {
        label: subTab.title,
        value: subTab.name,
        disabled: subTab.disabled,
      };
    }) || [];

  const subTabValue = hash.replace('#', '');
  const currentSubTab = subTabsOptions.find(
    (subTab) => subTab.value === subTabValue,
  );
  return (
    <>
      <StyledTabs
        value={tabValue}
        variant="scrollable"
        scrollButtons={false}
        className="ApplicationTabs"
      >
        {tabsList}
      </StyledTabs>

      {subTabsOptions.length > 0 && (
        <StyledToggleButtonGroup
          fullWidth
          size="small"
          options={subTabsOptions}
          exclusive
          onChange={onSubTabChange}
          value={subTabValue}
        />
      )}

      <Modal
        cancelButton={{
          cancelButtonName: 'Cancel',
        }}
        confirmButton={{
          handleConfirm: confirmNewTab,
          confirmButtonName: 'Skip Changes',
        }}
        isOpened={modal.isOpened}
        onClose={modal.onClose}
        title="Do you want to switch to another tab?"
      >
        <div>
          You have changes in the{' '}
          <b>{currentSubTab?.label || currentTab?.tab?.title}</b> tab, the
          changes will not be saved if you leave the tab.
        </div>
      </Modal>

      <TabEntry />
    </>
  );
};
