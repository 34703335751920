import { apiRequest } from '~/utils/apiRequest';

import { TDictionary, TDictionaryTab } from './dictionaries.types';

export const fetchFilterDictionary = async (
  url: string,
  params?: Record<string, any>,
) => {
  try {
    const { data } = await apiRequest<TDictionary[] & TDictionaryTab[]>({
      url,
      params,
    });

    // todo delete when task will done https://jira.exante.eu/browse/CRM-3788
    if ('tab_name' in data[0]) {
      return data[0].choices.map(({ id, name, text }) => ({
        value: String(id),
        label: name || text || String(id),
      }));
    }

    if ('id' in data[0]) {
      return data.map(({ id, name, text }) => ({
        value: String(id),
        label: name || text || String(id),
      }));
    }
    return [];
  } catch (e) {
    return [];
  }
};
