import cn from 'classnames';
import { FC } from 'react';
import { Checkbox, Tooltip } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants/common';

import { CopyButton } from '../CopyButton';

import {
  StyledValueRowValue,
  StyledValueRowContainer,
  StyledValueRowLabel,
  StyledRightOffset,
  StyledIconButton,
} from './ValueRow.styled';
import { TValueRowProps } from './ValueRow.types';

export const ValueRow: FC<TValueRowProps> = ({
  value,
  label,
  valueNode,
  withCopyButton,
  className,
  valueForCopy,
  withRightOffset,
  hint,
}) => {
  const prepareParsedValue = () => {
    if (valueForCopy) {
      return valueForCopy;
    }
    if (value || typeof value === 'boolean') {
      return String(value) || DEFAULT_EMPTY_PLACEHOLDER;
    }
    return DEFAULT_EMPTY_PLACEHOLDER;
  };
  const parsedValue = prepareParsedValue();

  const isCheckbox = ['false', 'true'].includes(parsedValue);

  return (
    <StyledValueRowContainer className={cn(['ValueRowContainer', className])}>
      <StyledValueRowLabel className="ValueRowLabel">
        {label}
        {hint && (
          <Tooltip title={hint}>
            <StyledIconButton
              className="IconButton"
              iconName="InfoIcon"
              iconColor="ghost"
              iconSize={18}
            />
          </Tooltip>
        )}
      </StyledValueRowLabel>
      <StyledValueRowValue className="ValueRowValue">
        {isCheckbox ? (
          <Checkbox checked={parsedValue === 'true'} disabled />
        ) : (
          valueNode || parsedValue
        )}
        {withCopyButton && parsedValue !== DEFAULT_EMPTY_PLACEHOLDER && (
          <CopyButton text={parsedValue} />
        )}
        {withRightOffset && <StyledRightOffset className="RightOffset" />}
      </StyledValueRowValue>
    </StyledValueRowContainer>
  );
};
