import { IMenuItem } from './Menu.types';

export const checkPathActive = (
  pathname: string,
  url: string,
  aliases: string[] = [],
): boolean => {
  return [url, ...aliases].some((urlMatch) =>
    new RegExp(`^${urlMatch.replace(/\/$/, '')}/?$`).test(pathname),
  );
};

export const getOpenedItemId = (
  items: IMenuItem[],
  pathname: string,
): IMenuItem['id'] | null =>
  items.find(({ items: subItems, url: itemUrl, urlAliases: itemAliases }) => {
    if (subItems.length) {
      return subItems.some(
        ({ url: subItemUrl, urlAliases: subItemAliases }) => {
          return checkPathActive(pathname, subItemUrl, subItemAliases);
        },
      );
    }

    return checkPathActive(pathname, itemUrl, itemAliases);
  })?.id || null;
