import { format } from 'date-fns';

import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
} from '~/constants/common';

export function clearDateTimezone(date: Date | string) {
  return new Date(
    new Date(date).valueOf() + new Date(date).getTimezoneOffset() * 60 * 1000,
  );
}

export const convertToTz = (date: Date | string, tzString: string) => {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: tzString,
    }),
  );
};

export function formatDate(date: Date | string) {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    const dtDateOnly = clearDateTimezone(date);
    return format(dtDateOnly, DEFAULT_DATE_FORMAT);
  }
  return format(date, DEFAULT_DATE_FORMAT);
}

export function formatDateTime(
  date?: Date | string | null,
): string | null | undefined {
  if (!date) {
    return null;
  }
  if (typeof date === 'string') {
    const dtDateOnly = clearDateTimezone(date);
    return format(dtDateOnly, DEFAULT_DATE_TIME_FORMAT);
  }
  return format(date, DEFAULT_DATE_TIME_FORMAT);
}
