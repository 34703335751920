import { getTableId } from '~/utils/table';

export const COLUMNS = [
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: true,
  },
  {
    Header: 'Days',
    accessor: 'days',
    disableSortBy: true,
  },
];

export const TABLE_ID = getTableId('onboarding-log');
