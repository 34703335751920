import { useGetAccountStatusTypesQuery } from '~/api';
import { capitalize } from '~/utils/capitalize';

const transformAccountStatusesToOptions = (values: string[] = []) => {
  return values.map((t) => ({
    value: t,
    label: capitalize(t.replace('_', ' ')),
  }));
};

export const useAccountStatuses = () => {
  const { data: accountStatusTypes } = useGetAccountStatusTypesQuery();

  const accountStatusesOptions = transformAccountStatusesToOptions(
    accountStatusTypes?.values,
  );

  return {
    accountStatusTypes: accountStatusTypes?.values || [],
    defaultAccountStatus: accountStatusTypes?.default || '',
    accountStatusesOptions,
  };
};
