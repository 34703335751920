import { FC } from 'react';

import { Accordion } from '~/components/Accordion';

import { getTagsForSection } from '../../../ApplicationEntry.helpers';

import {
  HIDDEN_TITLES,
  mapSectionKeyToAdditionalComponent,
} from './Section.constants';
import { StyledSection, StyledTitle } from './Section.styled';
import { TSectionProps } from './Section.types';
import { SubSection } from './SubSection';

export const Section: FC<TSectionProps> = ({
  section,
  tagsData,
  withoutText,
}) => {
  const sectionKey = section.name;
  const title = section.title || '';
  const isCollapsed = section.is_collapsed;
  const isVisible = section.is_visible;

  if (!isVisible) {
    return null;
  }

  const content = section.children?.map((subSection) => {
    const sectionTags = getTagsForSection(tagsData, subSection);
    return (
      <SubSection
        isInnerSection
        key={subSection.name}
        section={subSection}
        tags={sectionTags}
        withoutText={withoutText}
        parent={section}
      />
    );
  });

  const sectionTitle =
    !HIDDEN_TITLES.includes(title) && title ? (
      <StyledTitle className="StyledTitle" isCollapsibleTitle={isCollapsed}>
        {title}
        {sectionKey && mapSectionKeyToAdditionalComponent[sectionKey]}
      </StyledTitle>
    ) : null;

  if (section.children?.length) {
    return (
      <StyledSection className="Section">
        {isCollapsed ? (
          <Accordion title={sectionTitle} margin="0 0 24px">
            {content}
          </Accordion>
        ) : (
          <>
            {sectionTitle}
            {content}
          </>
        )}
      </StyledSection>
    );
  }

  const tags = getTagsForSection(tagsData, section);

  return (
    <SubSection
      key={section.name}
      section={section}
      tags={tags}
      withoutText={withoutText}
    />
  );
};
