import { useContext } from 'react';
import { NoData } from 'react-ui-kit-exante';

import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';

import { TabLoader } from '../../../../components/TabLoader';

import { PermissionsFormContainer } from './components/PermissionsFormContainer';
import { useAccountPermissionsData } from './useAccountPermissionsData';

export const AccountPermissionsContainer = () => {
  const { userName } = useContext(ApplicationContext);

  const {
    queries: { accountPermissionsQuery },
    isDataLoading,
  } = useAccountPermissionsData(userName);

  if (isDataLoading) {
    return <TabLoader />;
  }

  if (!accountPermissionsQuery.data) {
    return <NoData />;
  }

  return (
    <PermissionsFormContainer
      userName={userName}
      accountPermissions={accountPermissionsQuery.data}
    />
  );
};
