import { useState, FC, MouseEvent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IconButton } from 'react-ui-kit-exante';

import {
  TSection,
  TTableSectionColumn,
} from '~/api/applications/applications.types';
import { ActionWithConfirmationWithSelect } from '~/components/ConfirmationComponents/ActionWithConfirmationWithSelect';
import { mapDynamicTypeToFieldType } from '~/constants/common';
import { getFormField } from '~/utils/getFormField';

import { StyledAddRowFormContainer } from './AddRowForm.styled';

export type AddRowFormProps = {
  section: TSection;
  onAdd: VoidFunction;
  disabled?: boolean;
  fields?: TTableSectionColumn[];
};

export const AddRowForm: FC<AddRowFormProps> = ({
  section,
  onAdd,
  disabled,
  fields,
}) => {
  const [isOpenAddForm, setIsOpenAddForm] = useState(false);
  const [addFormAnchorEl, setAddFormAnchorEl] = useState<null | HTMLElement>(
    null,
  );

  const normalizedFields = fields?.filter((i) => i.id !== 'actions');

  const methods = useForm({
    defaultValues: normalizedFields?.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.id]: '',
      };
    }, {}),
    resolver: undefined,
  });

  const { watch, handleSubmit } = methods;

  watch();

  const openAddForm = (event: MouseEvent<HTMLElement>) => {
    setIsOpenAddForm(true);
    setAddFormAnchorEl(event.currentTarget);
  };

  const onCloseAddForm = () => {
    setIsOpenAddForm(false);
  };

  const onConfirm = () => {
    onAdd();
  };

  const formBody = () => {
    return (
      <StyledAddRowFormContainer className="AddRowFormContainer">
        {normalizedFields?.map((field) => {
          return getFormField({
            name: String(field.id),
            type: mapDynamicTypeToFieldType[field.type],
            options: field?.choices?.split(',').map((i) => ({
              value: i,
              label: i,
            })),
            label: field.title,
            size: 'medium',
          });
        })}
      </StyledAddRowFormContainer>
    );
  };

  return (
    <FormProvider {...methods}>
      <ActionWithConfirmationWithSelect
        key="addRowForm"
        open={isOpenAddForm}
        anchorEl={addFormAnchorEl}
        onClose={onCloseAddForm}
        title={`Add row to table "${section.title}"`}
        content={formBody()}
        confirmText="Add"
        handleConfirm={handleSubmit(onConfirm)}
        handleClose={onCloseAddForm}
        // workaround for calendar popup
        paperStyle={{
          overflowY: 'visible',
          overflowX: 'visible',
        }}
        // disabledConfirm={!reason}
      >
        <IconButton
          key="add"
          iconName="AddIcon"
          label="Add"
          disabled={disabled}
          iconColor="action"
          onClick={openAddForm}
        />
      </ActionWithConfirmationWithSelect>
    </FormProvider>
  );
};
