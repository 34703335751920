import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { DEFAULT_EMPTY_PLACEHOLDER } from '~/constants/common';
import { getTableId } from '~/utils/table';

import { TDataChangeRequestLog } from './DataChangeRequestsEntry.types';
import { ToCell } from './components/ToCell';

export function getColumns(isNew: boolean): IColumn<TDataChangeRequestLog>[] {
  return [
    {
      Header: 'Field',
      id: 'field',
    },
    {
      Header: 'From',
      id: 'from',
    },
    {
      Header: 'To',
      id: 'to',
      Cell: ({ row: { values } }: ICellValue<TDataChangeRequestLog>) => {
        const { to } = values;
        if (to) {
          return <ToCell to={to} isNew={isNew} />;
        }
        return DEFAULT_EMPTY_PLACEHOLDER;
      },
    },
  ].map((item) => ({ ...item, accessor: item.id, disableFilters: true }));
}

export const TABLE_ID = getTableId('data-change-requests-log');
