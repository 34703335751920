import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  calculateCountOfPages,
  IOnFetchArguments,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { useLazyGetNotificationsQuery } from '~/api';
import { TNotifications } from '~/api/notifications/notifications.types';
import { RefreshButton } from '~/components/RefreshButton';
import { useCallbackTriggerHandle, useLogHandleTime } from '~/hooks';
import { OLD_CRM_ROOT_PATH } from '~/router';
import { linkAndClick } from '~/utils/linkAndClick';
import {
  getDefaultPagination,
  getFilterParams,
  getPaginationParams,
} from '~/utils/table';

import {
  DISPLAYED_COLUMNS_KEYS,
  getColumns,
  TABLE_ID,
} from './Notifications.constants';

export const Notifications: FC = () => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    `notifications-page-${TABLE_ID}`,
  );
  const [
    fetchNotifications,
    {
      isLoading: isGetNotificationsLoading,
      isSuccess: isGetNotificationsSuccess,
    },
  ] = useLazyGetNotificationsQuery();

  useEffect(() => {
    if (isGetNotificationsLoading) {
      setStartHandleTime();
    }
  }, [isGetNotificationsLoading, setStartHandleTime]);

  const getNotifications = useCallback(
    async (params: IOnFetchArguments) => {
      const paginationParams = getPaginationParams(params);
      const filterParams = getFilterParams(params);

      const response = await fetchNotifications({
        ...paginationParams,
        ...filterParams,
      });

      return response.data;
    },
    [fetchNotifications],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId: TABLE_ID,
      data: {
        onFetch: getNotifications,
      },
      pagination: {
        getDefaultPagination,
      },
      saveViewParamsAfterLeave: true,
    }),
    [getNotifications],
  );

  const { data, limit, setLimit, setPage, page, skip, isLoading, fetchData } =
    useTableData(tableDataArgs);

  const total = data?.iTotalDisplayRecords ?? 0;

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount: calculateCountOfPages(total, limit),
    }),
    [skip, limit, page, setLimit, setPage, total],
  );

  const columns = getColumns();

  const displayedColumnKeys = DISPLAYED_COLUMNS_KEYS;

  // todo add params as other tables (see Deposits) after change on new entry screen page
  const handleRowClick = ({ pk }: TNotifications) => {
    const notificationUrl = `${OLD_CRM_ROOT_PATH}/notification-${pk}`;

    linkAndClick(notificationUrl);
  };

  const additionalActions = [
    {
      component: (
        <RefreshButton
          onRefresh={fetchData}
          disabled={isLoading}
          iconColor="secondary"
          title="Refresh table data"
        />
      ),
    },
  ];

  useCallbackTriggerHandle({
    cb: logHandleTime,
    dataTrigger: data?.results,
    processTrigger: !isGetNotificationsLoading && isGetNotificationsSuccess,
  });

  return (
    <Table<TNotifications>
      title="Notifications"
      className="NotificationsTable"
      titleSize={1}
      columns={columns}
      displayedColumnKeys={displayedColumnKeys}
      isLoading={isLoading}
      isFlexLayout
      disableSortBy
      manualSortBy
      data={data?.results || []}
      tableId={TABLE_ID}
      hasPagination
      showTableInfo
      isHiddenColumnSelect
      saveColumnOrder
      serverPaginationProps={serverPaginationProps}
      saveViewParamsAfterLeave
      handleRowClick={handleRowClick}
      additionalActions={additionalActions}
    />
  );
};
