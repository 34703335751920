import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { TComment } from '~/api/comments/comments.types';

import { StyledCommentCell } from './Comments.styled';

export const getColumns = (): IColumn<TComment>[] => {
  return [
    {
      id: 'info',
      minWidth: 240,
      Cell: ({ row: { original } }: ICellValue<TComment>) => {
        let answerText = '';
        if (!original.answered && original.kind_description.has_answer) {
          answerText = 'No answer';
        } else if (
          !original.is_system &&
          original.kind_description.has_answer
        ) {
          answerText = 'Answered';
        }

        return (
          <div>
            {original?.created_at_str}{' '}
            <strong>{original?.kind_description?.title}</strong>
            {answerText && <span>&nbsp;{answerText}</span>}
          </div>
        );
      },
    },
    {
      id: 'comment',
      width: '100%',
      Cell: ({ row: { original } }: ICellValue<TComment>) => {
        return (
          <StyledCommentCell className="CommentCell">
            <strong>
              {original.author_str && <div>{original.author_str}&nbsp;</div>}
              {original.author_detail && (
                <span>
                  {original.author_detail?.first_name}{' '}
                  {original.author_detail?.last_name}{' '}
                </span>
              )}
            </strong>
            <span>{original.content}</span>
          </StyledCommentCell>
        );
      },
    },
  ];
};
