import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, FileUpload } from 'react-ui-kit-exante';

import { useLazyGetDocTypesQuery, useUploadDocsMutation } from '~/api';

import { ApplicationContext } from '../../../../../contexts/ApplicationContext';

import {
  StyledDocumentsGeneralTitle,
  StyledDocumentsTableName,
  StyledDocumentsTableTitle,
  StyledDocumentsTitle,
} from './UploadedDocuments.styled';
import { UploadedDocumentsTable } from './UploadedDocumentsTable';

export const UploadedDocuments = ({
  dynamicTitle,
}: {
  dynamicTitle?: string;
}) => {
  const navigate = useNavigate();
  const { application } = useContext(ApplicationContext);

  const applicationId = application?.application?.id;

  const [fetchDocTypes, stateDocTypes] = useLazyGetDocTypesQuery();
  const [uploadDocs, stateUploadDocs] = useUploadDocsMutation();

  const dataDocTypes = stateDocTypes.data || [];

  useEffect(() => {
    if (applicationId) {
      fetchDocTypes({ applicationId });
    }
  }, [applicationId]);

  const handleOnClickDownloadAll = () =>
    navigate(`/application/${applicationId}/archive/`);

  const title = (
    <StyledDocumentsTitle className="DocumentsTitle">
      <StyledDocumentsGeneralTitle className="Title">
        {dynamicTitle}
      </StyledDocumentsGeneralTitle>
      <IconButton
        iconSize={24}
        iconName="DownloadIcon"
        iconColor="secondary"
        title="Download All"
        label="Download All"
        onClick={handleOnClickDownloadAll}
      />
    </StyledDocumentsTitle>
  );

  const handleFileAppend = (payload: File[], type: string) => {
    payload.forEach((file) => uploadDocs({ applicationId, file, type }));
  };

  return (
    <>
      {title}
      {dataDocTypes.map((docType) => {
        return (
          <div key={docType?.key}>
            <StyledDocumentsTableTitle className="DocumentsTableTitle">
              <StyledDocumentsTableName className="DocumentsTableName">
                {docType?.title}
              </StyledDocumentsTableName>
              {stateUploadDocs.isLoading && (
                <FileUpload
                  disabled
                  buttonLabel="Upload New Document"
                  showDragAndDrop={false}
                />
              )}
              {!stateUploadDocs.isLoading && (
                <FileUpload
                  onUpload={(files) => handleFileAppend(files, docType?.key)}
                  showDragAndDrop={false}
                  buttonLabel="Upload New Document"
                />
              )}
            </StyledDocumentsTableTitle>
            <UploadedDocumentsTable
              type={docType?.key}
              dataDocTypes={dataDocTypes}
            />
          </div>
        );
      })}
    </>
  );
};
