import { Collapse } from '@mui/material';
import cn from 'classnames';
import { FC, useEffect, useState } from 'react';

import { HTML_TAGS } from '../../../consts/HtmlTags.constants';
import { checkPathActive } from '../Menu.helpers';
import { IMenuItemComponentProps } from '../Menu.types';

import {
  StyledExpandLess,
  StyledExpandMore,
  StyledListItem,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
  StyledShowAttentionMark,
  StyledSubList,
} from './MenuItem.styled';

export const MenuItem: FC<IMenuItemComponentProps> = ({
  className,
  items,
  url,
  urlAliases,
  title,
  linkComponent,
  icon,
  level,
  target,
  opened,
  location,
  forceExternal,
  onClick,
  onActivate,
  showAttentionMark,
}) => {
  const isTopLevel: boolean = level === 0;
  const hasChild = !!items.length;
  const shouldRender = hasChild || !!title;
  const isLinkExternal: boolean =
    !hasChild && (forceExternal || url?.includes('http'));

  const [isActive, setIsActive] = useState(false);

  const linkComponentAdditionalProps = isLinkExternal
    ? {
        component: HTML_TAGS.A,
        href: url,
      }
    : {
        component: linkComponent,
        to: url,
      };

  const linkComponentProps = {
    active: isActive,
    target,
    ...linkComponentAdditionalProps,
  };

  const WrapperProps = {
    onClick,
    level,
    disableTouchRipple: true,
    ...(hasChild
      ? {
          component: HTML_TAGS.BUTTON,
          onClick,
          active: opened,
        }
      : linkComponentProps),
  };

  useEffect(() => {
    if (isActive) {
      onActivate?.();
    }
  }, [isActive]);

  useEffect(() => {
    setIsActive(checkPathActive(location.pathname, url, urlAliases));
  }, [location.pathname, url, urlAliases]);

  return !shouldRender ? null : (
    <StyledListItem
      className={cn('MenuItemWrapper', `MenuLevel-${level}`, className)}
      data-test-id={`menu-item-wrapper-level-${level}`}
    >
      <StyledListItemButton
        className={cn('MenuItemButton', { MenuItemActive: isActive })}
        data-test-id="menu-item-button"
        {...WrapperProps}
      >
        {isTopLevel && icon && (
          <StyledListItemIcon
            className="MenuItemIcon"
            data-test-id="menu-item-icon"
            active={opened}
          >
            {icon}
          </StyledListItemIcon>
        )}
        {title && (
          <StyledListItemText
            className="MenuItemTitle"
            data-test-id="menu-item-title"
            disableTypography
          >
            {title}
            {showAttentionMark && <StyledShowAttentionMark />}
          </StyledListItemText>
        )}
        {hasChild &&
          (opened ? (
            <StyledExpandLess
              className="MenuItemExpandedIcon"
              data-test-id="menu-item-expanded-icon"
            />
          ) : (
            <StyledExpandMore
              className="MenuItemExpandIcon"
              data-test-id="menu-item-expand-icon"
            />
          ))}
      </StyledListItemButton>
      {hasChild && (
        <Collapse
          className="MenuItemCollapse"
          data-test-id="menu-item-collapse"
          in={opened}
          timeout="auto"
        >
          <StyledSubList
            className="MenuItemSubList"
            data-test-id="menu-item-sub-list"
          >
            {items.map<JSX.Element>((item) => {
              const newLevel: number | string = level + 1;

              return (
                <MenuItem
                  {...item}
                  level={newLevel}
                  location={location}
                  key={item.id}
                  onActivate={() => setIsActive(true)}
                />
              );
            })}
          </StyledSubList>
        </Collapse>
      )}
    </StyledListItem>
  );
};
