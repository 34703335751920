import { ReactNode } from 'react';

import { TTranslator } from 'contexts/TranslationContext.types';

export type TFileUploadProps = {
  accept?: string;
  buttonLabel?: string;
  className?: string;
  customHeader?: ReactNode;
  deleteConfirmationLabel?: string;
  disabled?: boolean;
  dndLabel?: string;
  error?: boolean;
  errorMaxFileSizeExceedMessage?: string;
  filesInitial?: TFile[];
  info?: ReactNode;
  loading?: boolean;
  maxFileSize?: number;
  message?: string;
  onDelete?: (id: string, setFileDeleted: TSetFileDeleteStatusFn) => void;
  onUpload?: (
    files: File[],
    setSuccess: TSetFileSuccessFn,
    setError: TSetFileErrorFn,
  ) => void;
  onDownload?: (id: string) => void;
  withDeleteConfirmation?: boolean;
  showDragAndDrop?: boolean;
  showFileSize?: boolean;
  showUploadIconButtonOnly?: boolean;
  tooltip?: ReactNode;
  translator?: TTranslator;
  uploadErrorMessage?: string;
  viewMode?: boolean;
  isMultiple?: boolean;
};

export type TSetFileSuccessArgs = {
  name: string;
  newName?: string;
  id: string;
  removable?: boolean;
};

export type TSetFileErrorArgs = {
  name: string;
  error: Error;
};

export type TSetFileDeleteStatusArgs = {
  id?: string;
  name?: string;
  status: DeleteStatus;
};

export type TSetFileSuccessFn = (args: TSetFileSuccessArgs) => void;
export type TSetFileErrorFn = (args: TSetFileErrorArgs) => void;
export type TSetFileDeleteStatusFn = (args: TSetFileDeleteStatusArgs) => void;

export type TFileUploadHook = {
  accept?: string;
  disabled?: boolean;
  errorMaxFileSizeExceedMessage?: string;
  filesInitial?: TFile[];
  maxFileSize?: number;
  onDelete?: (id: string, setFileDeleted: TSetFileDeleteStatusFn) => void;
  onUpload?: (
    files: File[],
    setSuccess: TSetFileSuccessFn,
    setError: TSetFileErrorFn,
  ) => void;
  withDeleteConfirmation: boolean;
  translator: TTranslator;
  uploadErrorMessage?: string;
  isMultiple?: boolean;
};

export type TFile = {
  id?: string;
  lastModified?: number;
  name: string;
  removable?: boolean;
  size: number;
  uploadStatus: UploadStatus;
  deleteStatus?: DeleteStatus;
  error?: Error;
  type?: string;
};

export enum DeleteStatus {
  SUCCESS = 'success',
  PENDING = 'pending',
  ERROR = 'error',
}

export enum UploadStatus {
  SUCCESS = 'success',
  PENDING = 'pending',
  ERROR = 'error',
}

export type TRootProps = {
  hasFile?: boolean;
  error?: boolean;
};

export type TRowContainerProps = {
  fullWidth: boolean;
};

export type TUploadButtonProps = {
  showUploadIconButtonOnly?: boolean;
};

export type TStateContainerProps = {
  status: UploadStatus;
  viewMode?: boolean;
};

export type TStateErrorMessageProps = {
  error?: boolean;
};

export type TGetColorBg = {
  viewMode?: boolean;
  status: string;
  secondaryColor: string;
  fileStatusColor: string;
};
