import { useContext } from 'react';

import { TokenTypes } from '~/pages/ApplicationEntry/tabs/UserSettingsTab/components/PasswordManagement/PasswordManagement.constants';

import { PasswordManagementContext } from '../../context';

import {
  StyledTokensListWrapper,
  StyledTokensListSection,
  StyledTokensListTitle,
} from './TokensList.styled';
import { TokenListSection } from './components';

export const TokensList = () => {
  const { tokens, authFlow } = useContext(PasswordManagementContext);

  const passwords =
    tokens?.filter((item) => item?.type === TokenTypes.Password) || [];
  const emails =
    tokens?.filter((item) => item?.type === TokenTypes.Email) || [];
  const sms = tokens?.filter((item) => item?.type === TokenTypes.Sms) || [];
  const totp = tokens?.filter((item) => item?.type === TokenTypes.Totp) || [];
  const authFlowPasswords = authFlow?.firstStep;
  const otherAuthFlow = authFlow?.secondSteps || [];

  return (
    <StyledTokensListWrapper className="TokensListWrapper">
      <StyledTokensListSection className="TokensListSection">
        <StyledTokensListTitle className="TokensListTitle">
          Password
        </StyledTokensListTitle>
        <TokenListSection
          tokens={passwords}
          isPasswordTokens
          authFlowTokens={[Number(authFlowPasswords)]}
          messageStub="No password"
        />
      </StyledTokensListSection>
      <StyledTokensListSection className="TokensListSection">
        <StyledTokensListTitle className="TokensListTitle">
          SMS
        </StyledTokensListTitle>
        <TokenListSection
          tokens={sms}
          authFlowTokens={otherAuthFlow}
          messageStub="No SMS"
        />
      </StyledTokensListSection>
      <StyledTokensListSection className="TokensListSection">
        <StyledTokensListTitle className="TokensListTitle">
          E-mail
        </StyledTokensListTitle>
        <TokenListSection
          tokens={emails}
          authFlowTokens={otherAuthFlow}
          messageStub="No e-mails"
        />
      </StyledTokensListSection>
      <StyledTokensListSection className="TokensListSection">
        <StyledTokensListTitle className="TokensListTitle">
          One Time Password
        </StyledTokensListTitle>
        <TokenListSection
          tokens={totp}
          authFlowTokens={otherAuthFlow}
          messageStub="No one time passwords"
        />
      </StyledTokensListSection>
    </StyledTokensListWrapper>
  );
};
