import { useController, useFormContext } from 'react-hook-form';
import { Select } from 'react-ui-kit-exante';

import { TFormSelectContainerProps } from './FormSelectContainer.types';

export const FormSelectContainer = ({
  fullWidth = true,
  label = '',
  disabled,
  name,
  options,
  iconLeft,
  readOnly,
  withNone,
  message,
  width,
  className,
  warning,
  ...rest
}: TFormSelectContainerProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const optionsFull = withNone
    ? [{ label: <em>None</em>, value: '' }, ...options]
    : options;

  const selectValue =
    !options.length || value === null || value === undefined ? '' : value;

  return (
    <Select
      style={{ width }}
      error={!!error}
      fullWidth={fullWidth}
      label={label}
      message={message || error?.message}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={optionsFull}
      value={selectValue}
      disabled={disabled}
      iconLeft={iconLeft}
      readOnly={readOnly}
      isShowMessageForDisabled
      className={className}
      warning={warning}
      {...rest}
    />
  );
};
