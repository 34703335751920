import { useContext, useMemo, useState } from 'react';

import { useGetPermissionsQuery } from '~/api';
import { ApplicationContext } from '~/pages/ApplicationEntry/contexts/ApplicationContext';
import { IOption } from '~/types/form/options';

const defaultOption = {
  value: 'All',
  label: 'All',
};

export const useGetTransactionsData = () => {
  const { application } = useContext(ApplicationContext);

  const { data } = useGetPermissionsQuery();
  const canView = data?.clientsarea.view_deposits;

  const options = useMemo(() => {
    const accounts = application?.accounts;

    return Object.entries(accounts ?? {}).reduce(
      (acc, [key, accountList]) => {
        accountList.forEach((accountId) => {
          acc.push({
            value: accountId,
            label: `${accountId} (${key})`,
          });
        });

        return acc;
      },
      [defaultOption] as IOption[],
    );
  }, [application?.accounts]);

  const [selectedAccount, setSelectedAccount] =
    useState<IOption>(defaultOption);

  const onChangeHandler = (_: any, value: IOption | null) => {
    setSelectedAccount(value ?? defaultOption);
  };

  const selectedAccountId = selectedAccount?.value;

  return {
    options,
    selectedAccountId,
    selectedAccount,
    onChangeHandler,
    canView,
  };
};
