import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

export const StyledApplicationWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const StyledApplicationFilters = styled('div')`
  display: flex;
  height: 100%;
  gap: 8px;
  margin-left: 18px;
`;

export const StyledApplicationFilter = styled('span', {
  shouldForwardProp: blockNonNativeProps('isActive'),
})<{ isActive: boolean }>`
  font-size: 15px;
  border: 1.5px solid;
  border-radius: 28px;
  padding: 6px 16px;
  cursor: pointer;
  color: ${({ isActive, theme }) =>
    isActive ? theme?.color?.bg?.primary : theme?.color?.typo?.promo};
  background: ${({ isActive, theme }) =>
    isActive ? theme?.color?.typo?.promo : theme?.color?.bg?.primary};
`;

export const StyledApplicationClosedForm = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;
