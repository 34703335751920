import { Notification } from 'react-ui-kit-exante';

export function validationJSON(value: unknown) {
  try {
    if (typeof value === 'object') {
      JSON.parse(JSON.stringify(value));
    } else if (typeof value === 'string') {
      JSON.parse(value);
    }
  } catch (e) {
    return Notification.error({
      title: `Notifications field is not valid JSON`,
    });
  }

  return true;
}
