import {
  useEffect,
  useState,
  useContext,
  SyntheticEvent,
  ChangeEvent,
} from 'react';

import { useGetManagersQuery, useSaveApplicationMutation } from '~/api';
import { ActionWithConfirmation } from '~/components/ConfirmationComponents/ActionWithConfirmation';
import { formatDate } from '~/utils/dates/formatDate';

import { ApplicationContext } from '../../contexts/ApplicationContext';

import {
  StyledAutocomplete,
  StyledFormRow,
  StyledCommentInput,
} from './Managers.styled';

export const Managers = () => {
  const { application, isLoadingApplication } = useContext(ApplicationContext);

  const { data: assignedList, isLoading: isLoadingAssigned } =
    useGetManagersQuery({ param: '' });
  const { data: сoManagersList, isLoading: isLoadingCoManager } =
    useGetManagersQuery({ param: 'co_managers_list/' });
  const { data: supportedList, isLoading: isLoadingSupported } =
    useGetManagersQuery({ param: 'supported_by_list/' });
  const [saveApplication] = useSaveApplicationMutation();

  const [assignedValue, setAssignedValue] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [сoManagerValue, setCoManagerValue] = useState<{
    value: number;
    label: string;
  } | null>(null);
  const [supportedValue, setSupportedValue] = useState<{
    value: number;
    label: string;
  } | null>(null);

  const [activeConfirm, setActiveConfirm] = useState('');

  const [assignedComment, setAssignedComment] = useState('');
  const [сoManagerComment, setCoManagerComment] = useState('');
  const [supportedComment, setSupportedComment] = useState('');

  const assignedOptions =
    assignedList?.map(
      ({ id: assignedId, name }: { id: number; name: string }) => ({
        value: assignedId,
        label: name,
      }),
    ) || [];
  const сoManagersOptions =
    сoManagersList?.map(
      ({ id: сoManagerId, name }: { id: number; name: string }) => ({
        value: сoManagerId,
        label: name,
      }),
    ) || [];
  const supportedOptions =
    supportedList?.map(
      ({ id: supportedId, name }: { id: number; name: string }) => ({
        value: supportedId,
        label: name,
      }),
    ) || [];

  const defaultCoManagers =
    сoManagersOptions?.find(
      (item: { value: number }) => item.value === application?.co_manager,
    ) || null;

  const defaultSupported =
    supportedOptions?.find(
      (item: { value: number }) => item.value === application?.supported_by,
    ) || null;

  const isAssignedConfirmOpen =
    application?.assigned_to && activeConfirm === 'assigned';
  const isCoManagerConfirmOpen =
    application?.co_manager && activeConfirm === 'coManager';
  const isSupportedConfirmOpen =
    application?.supported_by && activeConfirm === 'supported';

  useEffect(() => {
    if (application?.application?.assigned_to) {
      setAssignedValue({
        value: application?.application?.assigned_to,
        label: application?.application?.assigned_to__full_name,
      });
    }
  }, [application]);

  useEffect(() => {
    if (application?.co_manager) {
      setCoManagerValue(defaultCoManagers);
    }
  }, [сoManagersList]);

  useEffect(() => {
    if (application?.supported_by) {
      setSupportedValue(defaultSupported);
    }
  }, [supportedList]);

  if (!application) {
    return null;
  }

  const handleConfirmAssignedTo = () => {
    saveApplication({
      applicationId: application?.application?.id,
      params: {
        assigned_to: {
          id: assignedValue?.value,
          reason: assignedComment,
        },
      },
    });

    setActiveConfirm('');
  };

  const handleOnAssignedClose = () => {
    setAssignedComment('');
    setActiveConfirm('');
  };

  const handleOnAssignedCancel = () =>
    setAssignedValue({
      value: application?.application?.assigned_to,
      label: application?.application?.assigned_to__full_name,
    });

  const handleOnAssignedComment = (e: ChangeEvent<HTMLInputElement>) =>
    setAssignedComment(e.target.value);

  const handleOnChangeAssigned = (
    e: SyntheticEvent,
    value: {
      value: number;
      label: string;
    },
  ) => {
    setAssignedValue(value);

    if ((assignedValue || application?.assigned_to) && e.type !== 'change') {
      setActiveConfirm('assigned');
    }
    if (!application?.assigned_to) {
      saveApplication({
        applicationId: application?.application?.id,
        params: {
          assigned_to: {
            id: value?.value,
          },
        },
      });
    }
  };

  const handleConfirmCoManager = () => {
    saveApplication({
      applicationId: application?.application?.id,
      params: {
        co_manager: {
          id: сoManagerValue?.value,
          reason: сoManagerComment,
        },
      },
    });

    setActiveConfirm('');
  };

  const handleOnCoManagerClose = () => {
    setCoManagerComment('');
    setActiveConfirm('');
  };

  const handleOnCoManagerCancel = () => setCoManagerValue(defaultCoManagers);

  const handleOnCoManagerComment = (e: ChangeEvent<HTMLInputElement>) =>
    setCoManagerComment(e.target.value);

  const handleOnChangeCoManager = (
    e: SyntheticEvent,
    value: {
      value: number;
      label: string;
    },
  ) => {
    setCoManagerValue(value);

    if ((сoManagerValue || application?.co_manager) && e.type !== 'change') {
      setActiveConfirm('coManager');
    }

    if (!application?.co_manager) {
      saveApplication({
        applicationId: application?.application?.id,
        params: {
          co_manager: {
            id: value?.value,
          },
        },
      });
    }
  };

  const handleConfirmSupported = () => {
    saveApplication({
      applicationId: application?.application?.id,
      params: {
        supported_by: {
          id: supportedValue?.value,
          reason: supportedComment,
        },
      },
    });

    setActiveConfirm('');
  };

  const handleOnSupportedClose = () => {
    setActiveConfirm('');
    setSupportedComment('');
  };

  const handleOnSupportedCancel = () => setSupportedValue(defaultSupported);

  const handleOnSupportedComment = (e: ChangeEvent<HTMLInputElement>) =>
    setSupportedComment(e.target.value);

  const handleOnChangeSupported = (
    e: SyntheticEvent,
    value: {
      value: number;
      label: string;
    },
  ) => {
    setSupportedValue(value);

    if ((supportedValue || application?.supported_by) && e.type !== 'change') {
      setActiveConfirm('supported');
    }

    if (!application?.supported_by) {
      saveApplication({
        applicationId: application?.application?.id,
        params: {
          supported_by: {
            id: value.value,
          },
        },
      });
    }
  };

  return (
    <StyledFormRow className="FormRow">
      <ActionWithConfirmation
        externalOpened={!!isAssignedConfirmOpen}
        openFromExternal
        withCloseAfterConfirmation
        placement="bottom"
        cancelButtonNameKey="Cancel"
        confirmButtonNameKey="Reassigned"
        disabledConfirmBtn={!assignedComment}
        onConfirm={handleConfirmAssignedTo}
        title="Assigned reason"
        closeHandler={handleOnAssignedClose}
        cancelHandler={handleOnAssignedCancel}
        content={
          <StyledCommentInput
            className="CommentInput"
            label="Comment"
            onChange={handleOnAssignedComment}
            value={assignedComment}
            multiline
            placeholder="Write the reason"
          />
        }
      >
        <StyledAutocomplete
          options={assignedOptions}
          placeholder="Assigned to"
          disabled={isLoadingAssigned || isLoadingApplication}
          value={assignedValue}
          onChange={handleOnChangeAssigned}
          controlProps={{
            message:
              application?.application?.assigned_date &&
              application?.application?.assigned_to &&
              `Assigned date ${formatDate(
                application?.application?.assigned_date,
              )}`,
          }}
        />
      </ActionWithConfirmation>
      <ActionWithConfirmation
        externalOpened={!!isCoManagerConfirmOpen}
        openFromExternal
        withCloseAfterConfirmation
        placement="bottom"
        cancelButtonNameKey="Cancel"
        confirmButtonNameKey="Reassigned"
        disabledConfirmBtn={!сoManagerComment}
        onConfirm={handleConfirmCoManager}
        title="Co-Manager reason"
        closeHandler={handleOnCoManagerClose}
        cancelHandler={handleOnCoManagerCancel}
        content={
          <StyledCommentInput
            className="CommentInput"
            label="Comment"
            onChange={handleOnCoManagerComment}
            value={сoManagerComment}
            multiline
            placeholder="Write the reason"
          />
        }
      >
        <StyledAutocomplete
          options={сoManagersOptions}
          placeholder="Co-manager"
          disabled={isLoadingCoManager || isLoadingApplication}
          value={сoManagerValue}
          onChange={handleOnChangeCoManager}
          controlProps={{
            message:
              application?.co_manager &&
              application?.co_manager_date &&
              `Assigned date ${formatDate(application?.co_manager_date)}`,
          }}
        />
      </ActionWithConfirmation>
      <ActionWithConfirmation
        externalOpened={!!isSupportedConfirmOpen}
        openFromExternal
        withCloseAfterConfirmation
        placement="bottom"
        cancelButtonNameKey="Cancel"
        confirmButtonNameKey="Reassigned"
        disabledConfirmBtn={!supportedComment}
        onConfirm={handleConfirmSupported}
        title="Supported reason"
        closeHandler={handleOnSupportedClose}
        cancelHandler={handleOnSupportedCancel}
        content={
          <StyledCommentInput
            className="CommentInput"
            label="Comment"
            onChange={handleOnSupportedComment}
            value={supportedComment}
            multiline
            placeholder="Write the reason"
          />
        }
      >
        <StyledAutocomplete
          options={supportedOptions}
          placeholder="Supported by"
          disabled={isLoadingSupported || isLoadingApplication}
          value={supportedValue}
          onChange={handleOnChangeSupported}
          controlProps={{
            message:
              application?.supported_date &&
              application?.supported_by &&
              `Assigned date ${formatDate(application?.supported_date)}`,
          }}
        />
      </ActionWithConfirmation>
    </StyledFormRow>
  );
};
