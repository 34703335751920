import { styled } from 'react-ui-kit-exante';

export const StyledScoringTablesTitle = styled('div')`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin-left: 24px;
  font-family: ${({ theme }) => theme?.font?.header};
`;

export const StyledScoringRiskLevel = styled('div')`
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  margin: 24px;
  font-family: ${({ theme }) => theme?.font?.header};
`;
