import cn from 'classnames';
import { FC, useContext, useMemo } from 'react';
import { Country } from 'react-phone-number-input';

import { ISelectOption } from '../../Select';
import { PhoneContext } from '../PhoneContext';
import { PhoneCountryLabel } from '../PhoneCountryLabel/PhoneCountryLabel';

import { PhoneCountryIcon } from './PhoneCountryIcon';
import { PHONE_INTERNATIONAL_VALUE } from './constants';
import { StyledPhoneCountrySelect } from './styled';
import { ICountrySelectComponentProps } from './types';

export const PhoneCountrySelect: FC<ICountrySelectComponentProps> = ({
  value,
  onChange,
  iconComponent,
  options: countries,
  className,
  ...restSafePropsForSelect
}) => {
  const { translate } = useContext(PhoneContext);

  const selectOptions = useMemo<ISelectOption[]>(() => {
    const labels = Object.fromEntries(
      countries?.map<[Country, string]>((country) => [
        (country.value || PHONE_INTERNATIONAL_VALUE) as Country,
        translate(country.label),
      ]) || [],
    );

    const values = Object.values(countries || {}).reduce(
      (acc, country) =>
        country.value ? [...acc, country.value as Country] : acc,
      [] as Country[],
    );

    return ([PHONE_INTERNATIONAL_VALUE, ...values] as const).map(
      (country): ISelectOption => ({
        value: country,
        icon: <PhoneCountryIcon country={country} />,
        label: <PhoneCountryLabel country={country} label={labels[country]} />,
      }),
    );
  }, [countries]);

  return (
    <StyledPhoneCountrySelect
      className={cn('PhoneCountrySelect', className)}
      options={selectOptions}
      value={value || PHONE_INTERNATIONAL_VALUE}
      onChange={(e) => onChange?.(e.target.value)}
      {...restSafePropsForSelect}
    />
  );
};
