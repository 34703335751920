import { FC, SyntheticEvent, useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { IconButton, Loader, Tooltip } from 'react-ui-kit-exante';

import { useGetPermissionsQuery } from '~/api';
import { TDepositInfo } from '~/api/deposits/deposits.types';
import {
  EntryColumn,
  EntrySection,
  EntrySubSection,
} from '~/components/EntryScreen';
import { ValueRow } from '~/components/ValueRow';
import { StyledFormField } from '~/pages/DepositEntry/components/DepositForm/components/DepositDetails/DepositDetails.styled';
import { getFormField } from '~/utils/getFormField';

import {
  cannotEditDepositText,
  formValuesNotChanged,
  getClientDetailsFields,
  getComplyRadarFields,
} from './DepositForm.constants';
import { StyledActionsRow } from './DepositForm.styled';
import { TDepositFormProps } from './DepositForm.types';
import { DepositDetails } from './components/DepositDetails';
import { DepositFormContext } from './contexts/DepositFormContext';

export const DepositForm: FC<TDepositFormProps> = ({ deposit }) => {
  const { isEdit, setIsEdit, formNotEdited, isFormDisabled, isFormLoading } =
    useContext(DepositFormContext);
  const { watch } = useFormContext();

  const { data: permissions } = useGetPermissionsQuery();
  const canChange = Boolean(permissions?.deposit_info.change_depositinfo);

  const onToggleEdit = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsEdit(!isEdit);
  };

  const isEditDisabled = !canChange || isFormDisabled;

  const tooltipMessage = useMemo(() => {
    if (!canChange) {
      return cannotEditDepositText;
    }
    return '';
  }, [canChange]);

  const complyRadarFields = getComplyRadarFields(deposit);
  const currentFormValues = watch();

  return (
    <EntryColumn>
      <EntrySection
        title="Info"
        action={
          <StyledActionsRow className="ActionsRow">
            {isEdit && !isFormLoading ? (
              <>
                <Tooltip title={formNotEdited ? formValuesNotChanged : ''}>
                  <IconButton
                    title="Save deposit data"
                    label="Save"
                    type="submit"
                    iconName="SaveIcon"
                    iconColor="action"
                    iconSize={24}
                    disabled={formNotEdited}
                  />
                </Tooltip>
                <IconButton
                  title="close form editing"
                  type="button"
                  label="Cancel"
                  iconName="CloseIcon"
                  iconSize={24}
                  onClick={() => setIsEdit(!isEdit)}
                />
              </>
            ) : (
              <>
                {!isFormLoading && (
                  <Tooltip title={tooltipMessage}>
                    <IconButton
                      title="Open form editing"
                      type="button"
                      iconName="EditIcon"
                      iconColor="action"
                      disabled={isEditDisabled}
                      iconSize={24}
                      onClick={onToggleEdit}
                    />
                  </Tooltip>
                )}
                {isFormLoading && <Loader />}
              </>
            )}
          </StyledActionsRow>
        }
      >
        {isFormLoading && <Loader size="l" isCentered isInner />}
        {!isFormLoading && (
          <>
            <EntrySubSection title="Client Details">
              {getClientDetailsFields(deposit, currentFormValues).map(
                ({
                  name,
                  type,
                  title,
                  optionsConfig,
                  withCopyButton,
                  valueForCopy,
                  node,
                }) => {
                  const value = deposit[name as keyof TDepositInfo];

                  // because we need show account_id in Client Details section
                  if (isEdit && name === 'account_id') {
                    return (
                      <ValueRow
                        key={name}
                        label={title}
                        valueNode={
                          <StyledFormField className="FormField">
                            {getFormField({
                              type,
                              name,
                              options: optionsConfig?.options,
                            })}
                          </StyledFormField>
                        }
                      />
                    );
                  }
                  return (
                    <ValueRow
                      key={name}
                      label={title}
                      value={!node ? value : null}
                      valueNode={node}
                      withCopyButton={withCopyButton}
                      valueForCopy={valueForCopy}
                    />
                  );
                },
              )}
            </EntrySubSection>
            {complyRadarFields.length > 0 && (
              <EntrySubSection title="ComplyRadar">
                {complyRadarFields.map((field) => {
                  return (
                    <ValueRow
                      key={field.key}
                      label={field.label}
                      value={deposit?.[field.key as keyof TDepositInfo]}
                      withCopyButton
                    />
                  );
                })}
              </EntrySubSection>
            )}
            <DepositDetails deposit={deposit} isEditMode={isEdit} />
          </>
        )}
      </EntrySection>
    </EntryColumn>
  );
};
