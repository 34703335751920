import { styled } from 'react-ui-kit-exante';

export const StyledTransactionTotalList = styled('div')`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  gap: 8px;
  border-top: 1px solid ${({ theme }) => theme.color.line.primary};
`;

export const StyledLoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px;
`;
