import PhoneInput from 'react-phone-number-input';

import { styled } from '../../../theme';

export const StyledPhoneInput = styled(PhoneInput)`
  display: flex;

  .PhoneCountryInput .MuiInputBase-root {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
` as unknown as typeof PhoneInput; // HACK: lib author exported type in a wrong way
