export const DISPLAYED_COLUMNS_KEYS = [
  'name',
  'date_joined',
  'next_communication_date',
  'last_call',
  'last_meeting',
  'assigned_to',
];

export const FILTERS_DATE_FIELDS = [
  'field_date_joined',
  'field_next_communication_date',
];

export const COLUMNS_DATE_FIELDS = [
  'date_joined',
  'tag_date_of_incorporation_registration',
  'tag_approved date',
  'send_to_review_date',
  'tag_last kyc update date',
  'tag_last withdrawal date',
  'tag_aml initiated date',
  'tag_standing authority expiration date',
  'tag_last funds received date',
  'tag_blocked date',
  'tag_lead date',
  'tag_lei expiry date',
  'tag_pending date',
  'tag_date of birth',
  'next_communication_date',
  'tag_id expiry date',
  'tag_last client review date',
  'tag_accuity last verification date',
  'tag_min_deposit_date',
  'tag_meeting date',
  'tag_monitoring / date of opening',
  'tag_fund launch date',
  'tag_status change date',
  'tag_co-manager assign date',
  'tag_ever had min nav date',
  'tag_funds received date',
  'tag_monitoring / date of source',
  'tag_sumsub liveness check date',
  'tag_assigned date',
];

export const PAGE_SIZES = [10, 20, 50];
export const PAGE_SIZE = 20;
