import { alpha } from '@mui/material/styles';

import { blockNonNativeProps } from '../../../helpers';
import { styled, ThemeColorNotifications } from '../../../theme';
import { Button } from '../../Button/Button';
import { Loader } from '../../Loader';

import { FILE_STATUS_TO_NOTIFICATION_COLOR } from './constants';
import { getColorBg } from './helpers';
import type {
  TRootProps,
  TRowContainerProps,
  TStateErrorMessageProps,
  TUploadButtonProps,
} from './types';
import { TStateContainerProps, UploadStatus } from './types';

export const RootStyled = styled('div', {
  shouldForwardProp: blockNonNativeProps(['hasFile', 'error']),
})<TRootProps>(({ hasFile, theme, error }) => {
  const getBorderColor = () => {
    if (error) {
      return theme?.color?.input?.error;
    }
    if (hasFile) {
      return theme?.color?.input?.border;
    }
    return theme?.color?.input?.bg?.focus;
  };

  return {
    alignItems: 'flex-start',
    padding: hasFile ? '8px' : '0',
    borderStyle: hasFile ? 'dashed' : 'none',
    borderColor: getBorderColor(),
    borderWidth: '1px',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    [theme.breakpoints.up('sm')]: {
      borderStyle: 'dashed',
      padding: '8px',
    },
  };
});

export const Head = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}));

export const Body = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '8px',
}));

export const RowContainer = styled('div', {
  shouldForwardProp: blockNonNativeProps(['fullWidth']),
})<TRowContainerProps>(({ fullWidth }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: '18px',
  justifyContent: 'space-between',
  width: fullWidth ? '100%' : 'auto',
}));

export const RowCustomHeader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme?.color?.typo?.primary,
  display: 'flex',
  fontSize: '20px',
  gap: '24px',
}));

export const RowContainerLeft = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme?.color?.button?.secondary?.typo?.default,
  display: 'flex',
  fontSize: theme?.size?.text?.md,
  gap: '24px',
}));

export const RowContainerRight = styled('div')(() => ({
  display: 'flex',
}));

export const UploadButton = styled(Button, {
  shouldForwardProp: blockNonNativeProps(['showUploadIconButtonOnly']),
})<TUploadButtonProps>(({ showUploadIconButtonOnly, theme }) => ({
  borderRadius: '4px',
  fontSize: theme?.size?.text?.md,
  height: '32px',
  padding: '4px 8px',
  minWidth: 'auto',
  '& .MuiButton-startIcon': {
    marginRight: showUploadIconButtonOnly ? '0' : '4px',
    marginLeft: '0',
  },
}));

export const DragAndDropWrapper = styled('div')(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    gap: '24px',
  },
}));

export const DragAndDropDownloadIcon = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '10px',
}));

export const StateContainer = styled('div', {
  shouldForwardProp: blockNonNativeProps(['status', 'viewMode']),
})<TStateContainerProps>(({ theme, status, viewMode }) => {
  const fileStatusColor: keyof ThemeColorNotifications =
    FILE_STATUS_TO_NOTIFICATION_COLOR?.[status];

  const colorBg = getColorBg({
    viewMode,
    status,
    secondaryColor: theme?.color?.input?.bg?.focus,
    fileStatusColor: theme?.color?.notifications?.[fileStatusColor],
  });

  const color =
    status === UploadStatus.SUCCESS ? theme?.color?.typo?.primary : colorBg;

  return {
    alignItems: 'center',
    backgroundColor: colorBg
      ? alpha(colorBg, viewMode ? 1 : 0.1)
      : 'transparent',
    color: status ? color : theme?.color?.typo?.primary,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 10px',
    width: '100%',
  };
});

export const StateContainerLeft = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  gap: '10px',
  width: '100%',
}));

export const StateContainerRight = styled('div')(() => ({
  display: 'flex',
}));

export const StateFileName = styled('div')(({ theme }) => ({
  fontSize: theme?.size?.text?.md,
  fontFamily: theme?.font?.main,
  overflowWrap: 'anywhere',
  marginTop: '-2px',
}));

export const StateFileSize = styled('div')(({ theme }) => ({
  color: theme?.color?.typo?.ghost,
  fontSize: theme?.size?.text?.sm,
  fontFamily: theme?.font?.main,
  marginTop: '-2px',
}));

export const StateIcon = styled('div')(() => ({
  display: 'flex',
}));

export const LoaderStyled = styled(Loader)(({ theme }) => ({
  '.spinner-icon': {
    color: theme?.color?.notifications?.warning,
  },
}));

export const Text = styled('div')(({ theme }) => ({
  fontFamily: theme?.font?.main,
}));

export const ErrorMessageStyled = styled('div', {
  shouldForwardProp: blockNonNativeProps(['error']),
})<TStateErrorMessageProps>(({ theme, error }) => {
  return {
    fontFamily: theme?.font?.main,
    padding: '0 12px',
    fontSize: '11px',
    lineHeight: '16px',
    fontWeight: '400',
    color: error ? theme?.color?.typo?.radical : theme?.color?.typo?.secondary,
  };
});

export const Actions = styled('div')`
  display: flex;
  gap: 8px;
`;
