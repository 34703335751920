import { createApi } from '@reduxjs/toolkit/query/react';

import { ALL_LEGAL_ENTITY } from '~/constants/common';
import { baseQueryHandler } from '~/utils/apiRequest';

import {
  TApplicationTransactions,
  TApplicationTransactionsParams,
  TApplicationTransactionsTotal,
} from './applicationTransactions.types';

export const applicationTransactionsApi = createApi({
  reducerPath: 'applicationTransactionsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['ApplicationTransactions'],
  endpoints: (builder) => ({
    getApplicationTransactionsTotals: builder.query<
      TApplicationTransactionsTotal,
      { applicationId: number; legalEntity?: string }
    >({
      query: ({ applicationId, legalEntity = ALL_LEGAL_ENTITY }) => ({
        url: `/rest/transactions/application/${applicationId}/totals/`,
        params: {
          le: legalEntity,
        },
      }),
    }),
    getApplicationTransactions: builder.query<
      TApplicationTransactions,
      TApplicationTransactionsParams
    >({
      query: ({
        applicationId,
        page,
        pageSize,
        accountId,
        legalEntity = ALL_LEGAL_ENTITY,
      }) => ({
        url: `/rest/transactions/application/${applicationId}/`,
        params: {
          page,
          le: legalEntity,
          page_size: pageSize,
          account_id: accountId,
        },
      }),
    }),
  }),
});

export const {
  useGetApplicationTransactionsTotalsQuery,
  useLazyGetApplicationTransactionsQuery,
} = applicationTransactionsApi;
