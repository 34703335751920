import cn from 'classnames';
import { forwardRef } from 'react';

import { ForwardRefFC } from '../../../../types';
import { Input } from '../../Input/Input';

import { IPhoneCountryInputProps } from './types';

export const PhoneInput: ForwardRefFC<IPhoneCountryInputProps> = forwardRef(
  function PhoneCountryInput(
    { placeholder = 'Phone', onChange, className, ...rest },
    ref,
  ) {
    return (
      <Input
        className={cn('PhoneInput', className)}
        ref={ref}
        placeholder={placeholder}
        onChange={(e) => onChange?.(e.target.value as any)}
        {...rest}
      />
    );
  },
);
