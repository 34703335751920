import { TTranslator } from 'contexts/TranslationContext.types';

import { ThemeColorNotifications } from '../../../theme';

import { UploadStatus } from './types';

export const KILOBYTE = 1000; // 1 Kilobyte => 1000 Bytes
export const MEGABYTE = 1000 * KILOBYTE; // 1 Megabyte => 1000 Kilobytes

export const TEST_ID_FILEUPLOAD_INPUT = 'INPUT-TEST-ID';
export const TEST_ID_FILEUPLOAD_STATE_CONTAINER = 'STATE_CONTAINER_TEST_ID';
export const TEST_ID_FILEUPLOAD_ICON_ERROR = 'ICON-ERROR';
export const TEST_ID_FILEUPLOAD_ICON_SUCCESS = 'ICON-SUCCESS';
export const TEST_ID_FILEUPLOAD_ICON_DELETE = 'ICON-DELETE';
export const TEST_ID_FILEUPLOAD_ICON_DOWNLOAD = 'ICON-DOWNLOAD';

export const translatorFallback: TTranslator = (key, vars) =>
  ({
    ui__fileupload__error_size_exceed: `Maximum file size exceeded. Please upload a file up to ${vars?.MAX_FILE_SIZE_MB} MB.`,
    ui__fileupload__error_file_type: `Please upload the file in the valid format: ${vars?.VALID_FILE_TYPES}.`,
  }[key] || key);

export const FILE_STATUS_TO_NOTIFICATION_COLOR: Record<
  UploadStatus,
  keyof ThemeColorNotifications
> = {
  [UploadStatus.SUCCESS]: 'success',
  [UploadStatus.PENDING]: 'warning',
  [UploadStatus.ERROR]: 'error',
};

export const NO_VALID_ERROR_MESSAGE = 'No valid error message has been set';
