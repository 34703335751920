import { createApi } from '@reduxjs/toolkit/query/react';

import { ALL_LEGAL_ENTITY } from '~/constants/common';
import { baseQueryHandler } from '~/utils/apiRequest';

import { TRiskScoringTables } from './riskScoring.types';

export const riskScoringApi = createApi({
  reducerPath: 'riskScoringApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['RiskScoringTables'],
  endpoints: (builder) => ({
    getRiskScoringTables: builder.query<
      TRiskScoringTables,
      { applicationId: number; legalEntity?: string }
    >({
      query: ({ applicationId, legalEntity = ALL_LEGAL_ENTITY }) => ({
        url: `/rest/riskscoring/tables/${applicationId}/`,
        params: {
          legal_entity: legalEntity,
        },
      }),
      providesTags: ['RiskScoringTables'],
    }),
  }),
});

export const { useLazyGetRiskScoringTablesQuery } = riskScoringApi;
