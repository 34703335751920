import { styled } from 'react-ui-kit-exante';

export const StyledClientFormField = styled('div')`
  min-width: 270px;

  .MuiFormControlLabel-root {
    display: flex;
    justify-content: right;
  }
`;

export const StyledClientFormList = styled('div')`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const StyledClientFormWrapper = styled('div')`
  margin-bottom: 24px;
`;
