import { createApi } from '@reduxjs/toolkit/query/react';
import { Method } from 'axios';
import { Notification } from 'react-ui-kit-exante';

import { baseQueryHandler } from '~/utils/apiRequest';

import { TCreatePersonParams, TPersons } from './person.types';

export const personsApi = createApi({
  reducerPath: 'personsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['Persons'],
  endpoints: (builder) => ({
    getPersons: builder.query<TPersons[], { applicationId: number | string }>({
      query: ({ applicationId }) => ({
        url: `/rest/docs/person/?application=${applicationId}`,
      }),
      providesTags: ['Persons'],
    }),

    createPerson: builder.mutation<void, { data: TCreatePersonParams }>({
      query: ({ data }) => ({
        url: '/rest/docs/person/',
        method: 'POST',
        data,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Person successfully created',
          });
        } catch (e) {
          console.error(e);
        }
      },
    }),

    deletePerson: builder.mutation<void, { personId: number }>({
      query: ({ personId }) => ({
        url: `/rest/docs/person/${personId}/`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: 'Person successfully deleted',
          });
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['Persons'],
    }),

    setMainUbo: builder.mutation<void, { data: TPersons[]; method: Method }>({
      query: ({ data, method }) => ({
        url: `/rest/docs/person/${data[0].id}/set_main_ubo/`,
        method,
        data,
      }),
      async onQueryStarted({ method }, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: `Person successfully ${
              method === 'POST' ? 'set' : 'unset'
            } main ubo`,
          });
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['Persons'],
    }),
  }),
});

export const {
  useLazyGetPersonsQuery,
  useCreatePersonMutation,
  useDeletePersonMutation,
  useSetMainUboMutation,
} = personsApi;
