import { useCallback, useState } from 'react';

import {
  StyledAccordionWrapper,
  StyleAccordionButton,
  StyleAccordionArrow,
  StyleAccordionHiddenChildren,
} from './Accordion.styled';
import { TAccordion } from './Accordion.types';

export const Accordion = ({
  title,
  margin,
  children,
  disabled,
  hideArrowForDisabled,
}: TAccordion) => {
  const [isShow, setIsShow] = useState(false);

  const handleOnClick = useCallback(
    () => !disabled && setIsShow(!isShow),
    [isShow, disabled],
  );

  const hideArrow = hideArrowForDisabled && disabled;

  return (
    <StyledAccordionWrapper className="AccordionWrapper">
      <StyleAccordionButton
        className="AccordionButton"
        type="button"
        onClick={handleOnClick}
        isDisabled={disabled}
        margin={margin}
      >
        {title}
        {!hideArrow && (
          <StyleAccordionArrow
            className="AccordionArrow"
            isRight={!isShow}
            isDisabled={disabled}
          />
        )}
      </StyleAccordionButton>
      <StyleAccordionHiddenChildren
        className="AccordionHiddenChildren"
        isHidden={!isShow}
      >
        {isShow && children}
      </StyleAccordionHiddenChildren>
    </StyledAccordionWrapper>
  );
};
