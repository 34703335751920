import { blockNonNativeProps, styled } from 'react-ui-kit-exante';

export const StyledSectionBody = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledFormField = styled('div', {
  shouldForwardProp: blockNonNativeProps(['withOffset']),
})<{ withOffset?: boolean }>`
  min-width: ${({ withOffset }) => (withOffset ? '300px' : '270px')};
  max-width: ${({ withOffset }) => (withOffset ? '300px' : '270px')};

  display: flex;
  gap: 12px;
  justify-content: right;
  align-items: center;

  // hack for checkboxes
  .MuiFormControlLabel-root {
    display: flex;
    justify-content: right;
  }

  // hack for datepicker container
  .DatePickerWrapper {
    width: 100%;
  }
`;

export const StyledDeleteButtonOffset = styled('div')`
  width: 18px;
`;
